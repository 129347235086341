export default function (){
    return '  <div class="calculator__tab" data-tab data-calc-mode="Savings">\n' +
        '        <div class="calculator__header">\n' +
        '           {{#if h1}}  <h1>Refinance Calculator</h1>{{else}}<h3>Refinance Calculator</h3>{{/if}}\n' +       
        '            <p>See how you could lower your monthly mortgage payment and/or get cash out.</p>\n' +
        '            <div class="calculator__navigate">\n' +
        '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link active" data-tab-link href="#basic" role="tab" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#advanced" role="tab" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#amortization" role="tab" aria-controls="AMORTIZATION" aria-selected="true">AMORTIZATION</a>\n' +
        '                    </li>\n' +
        '                </ul>\n' +
        '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
        '                <div class="dropdown-menu">\n' +
        '                    <a class="dropdown-item active" href="#basic" mobile-link-item>BASIC</a>\n' +
        '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
        '                    <a class="dropdown-item" href="#amortization" mobile-link-item>AMORTIZATION</a>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="calculator__content">\n' +
        '            <div class="tab-content">\n' +
        '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicSavings">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
        
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Current loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Current Mortgage Balance</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="currentMortgageBalance" autocomplete="false" type="text" name="currentMortgageBalance"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.currentMortgage}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Current Mortgage Balance" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Current Principal & Interest Payment (Excluding Escrows)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="currentPrincipal" autocomplete="false" type="text" name="currentPrincipal"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.currentPI}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Current Principal & Interest Payment (Excluding Escrows)" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Taxes (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTaxes" autocomplete="false" type="text"\n' +
        '                                                   name="propertyTaxes" data-currency data-input value="{{values.Refinance.currentLoan.propertyTaxes}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Property Taxes" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--30 panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--30 panel--mt">\n' +
        '                                        <p>Mortgage Ins. (Monthly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="mortgage" autocomplete="false" type="text" name="mortgage"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.mortgageIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Mortgage Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="">\n' +
        '                                <p class="panel__sub-title">new loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Refinance.newLoan.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input title="term" autocomplete="false" type="text" data-input name="term" value="{{values.Refinance.newLoan.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Refinance.newLoan.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Mortgage Ins. (Monthly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="mortgage2" autocomplete="false" type="text" name="mortgage2"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.newLoan.mortgageIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Mortgage Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--40 panel--mt">\n' +
        '                                        <p>Estimated Settlement Costs </p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="estimateSettlementCosts" autocomplete="false" type="text" name="estimateSettlementCosts"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.newLoan.estimatedCosts}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Estimated Settlement Costs" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Accounts to be paid off</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Cash Available</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="cashAvailable" data-result="cashAvailable" disabled="true" autocomplete="false" type="text" name="cashAvailable"\n' +
        '                                                   data-currency data-input value="51100" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Cash Available to Payoff Accts" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Total Balance of Accounts to Pay Off</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="totalBalance" autocomplete="false" type="text"\n' +
        '                                                   name="totalBalance" data-currency data-input\n' +
        '                                                   value="{{values.Refinance.accountsToBePaid.totalBalance}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Total Balance: Accts. To be Paid Off" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Total Monthly Payments of Accounts to Pay Off</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="totalMonthlyPayments" autocomplete="false" type="text"\n' +
        '                                                   name="totalMonthlyPayments" data-currency data-input value="{{values.Refinance.accountsToBePaid.totalMonthlyPayments}}"\n' +
        '                                                   data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Total Monthly Payments: Accts. to be Paid Off" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--40 panel--mt">\n' +
        '                                        <p>Cash Remaining</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="additionalCashRemaining"  data-result="additionalCashRemaining" disabled="true" autocomplete="false"\n' +
        '                                                   type="text" name="additionalCashRemaining"\n' +
        '                                                   data-currency data-input value="47100"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Additional Cash Remaining" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="flex-row">\n' +
        '                                    <a href="# " class="panel__button " data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom" data-result="basicTitle">\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="advanced" role="tabpanel"  data-calculator data-methods="advancedSavings">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
    
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Current loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Current Mortgage Balance</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="currentMortgageBalance" autocomplete="false" type="text" name="currentMortgageBalance"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.currentMortgage}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Current Mortgage Balance" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Current Principal & Interest Payment (Excluding Escrows)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="currentPrincipal" autocomplete="false" type="text" name="currentPrincipal"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.currentPI}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Current Principal & Interest Payment (Excluding Escrows)" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Taxes (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTaxes" autocomplete="false" type="text"\n' +
        '                                                   name="propertyTaxes" data-currency data-input value="{{values.Refinance.currentLoan.propertyTaxes}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Property Taxes" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--30 panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--30 panel--mt">\n' +
        '                                        <p>Mortgage Ins. (Monthly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="mortgage" autocomplete="false" type="text" name="mortgage"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.mortgageIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Mortgage Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="">\n' +
        '                                <p class="panel__sub-title">new loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Refinance.newLoan.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input title="term" autocomplete="false" type="text" data-input name="term" value="{{values.Refinance.newLoan.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Refinance.newLoan.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Mortgage Ins. (Monthly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="mortgage2" autocomplete="false" type="text" name="mortgage2"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.newLoan.mortgageIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Mortgage Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--40 panel--mt">\n' +
        '                                        <p>Estimated Settlement Costs </p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="estimateSettlementCosts" autocomplete="false" type="text" name="estimateSettlementCosts"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.newLoan.estimatedCosts}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Estimated Settlement Costs" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Accounts to be paid off</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Cash Available</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="cashAvailable" data-result="cashAvailable" disabled="true" autocomplete="false" type="text" name="cashAvailable"\n' +
        '                                                   data-currency data-input value="51100" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Cash Available to Payoff Accts" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Total Balance of Accounts to Pay Off</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="totalBalance" autocomplete="false" type="text"\n' +
        '                                                   name="totalBalance" data-currency data-input\n' +
        '                                                   value="{{values.Refinance.accountsToBePaid.totalBalance}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Total Balance: Accts. To be Paid Off" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Total Monthly Payments of Accounts to Pay Off</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="totalMonthlyPayments" autocomplete="false" type="text"\n' +
        '                                                   name="totalMonthlyPayments" data-currency data-input value="{{values.Refinance.accountsToBePaid.totalMonthlyPayments}}"\n' +
        '                                                   data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Total Monthly Payments: Accts. to be Paid Off" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--40 panel--mt">\n' +
        '                                        <p>Cash Remaining</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="additionalCashRemaining"  data-result="additionalCashRemaining" disabled="true" autocomplete="false"\n' +
        '                                                   type="text" name="additionalCashRemaining"\n' +
        '                                                   data-currency data-input value="47100"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Additional Cash Remaining" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="flex-row">\n' +
        '                                    <a href="# " class="panel__button " data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '                    </div>\n' +
        '                    <div data-show-calc class=" d-none">\n' +
        '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                        <div class="results results--indent result-one">\n' +
        '                            <div class="results__title left order" data-result="advancedTitle">\n' +
        '                                <div class="inline bottom">\n' +
        '                                    <p>With this plan you will save</p>\n' +
        '                                    <h2 calculator-highlightedTextColor><i></i> <span data-result="difference">$0.00</span></h2>\n' +
        '                                </div>\n' +
        '                                <div class="inline bottom">\n' +
        '                                    <p> per month and receive</p>\n' +
        '                                    <h2 calculator-highlightedTextColor><i></i> <span data-result="ACR">$0.00</span>* </h2 >\n' +
        '                                    <p> cash at closing</p>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class=" results__graphic no-border">\n' +
        '                                <div data-chart>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="amortization" role="tabpanel"  data-calculator data-methods="amortizationSavings">\n' +
        '                    <div class="panel d-none d-sm-block">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
    
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Current loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Current Mortgage Balance</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="currentMortgageBalance" autocomplete="false" type="text" name="currentMortgageBalance"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.currentMortgage}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Current Mortgage Balance" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Current Principal & Interest Payment (Excluding Escrows)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="currentPrincipal" autocomplete="false" type="text" name="currentPrincipal"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.currentPI}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Current Principal & Interest Payment (Excluding Escrows)" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Taxes (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTaxes" autocomplete="false" type="text"\n' +
        '                                                   name="propertyTaxes" data-currency data-input value="{{values.Refinance.currentLoan.propertyTaxes}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Property Taxes" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--30 panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--30 panel--mt">\n' +
        '                                        <p>Mortgage Ins. (Monthly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="mortgage" autocomplete="false" type="text" name="mortgage"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.currentLoan.mortgageIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Mortgage Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="">\n' +
        '                                <p class="panel__sub-title">new loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Refinance.newLoan.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input title="term" autocomplete="false" type="text" data-input name="term" value="{{values.Refinance.newLoan.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Refinance.newLoan.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Mortgage Ins. (Monthly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="mortgage2" autocomplete="false" type="text" name="mortgage2"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.newLoan.mortgageIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Mortgage Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--40 panel--mt">\n' +
        '                                        <p>Estimated Settlement Costs </p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="estimateSettlementCosts" autocomplete="false" type="text" name="estimateSettlementCosts"\n' +
        '                                                   data-currency data-input value="{{values.Refinance.newLoan.estmimatedCosts}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Estimated Settlement Costs" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Accounts to be paid off</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Cash Available</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="cashAvailable" data-result="cashAvailable" disabled="true" autocomplete="false" type="text" name="cashAvailable"\n' +
        '                                                   data-currency data-input value="51100" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Cash Available to Payoff Accts" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Total Balance of Accounts to Pay Off</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="totalBalance" autocomplete="false" type="text"\n' +
        '                                                   name="totalBalance" data-currency data-input\n' +
        '                                                   value="{{values.Refinance.accountsToBePaid.totalBalance}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Total Balance: Accts. To be Paid Off" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Total Monthly Payments of Accounts to Pay Off</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="totalMonthlyPayments" autocomplete="false" type="text"\n' +
        '                                                   name="totalMonthlyPayments" data-currency data-input value="{{values.Refinance.accountsToBePaid.totalMonthlyPayments}}"\n' +
        '                                                   data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Total Monthly Payments: Accts. to be Paid Off" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--40 panel--mt">\n' +
        '                                        <p>Cash Remaining</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="additionalCashRemaining"  data-result="additionalCashRemaining" disabled="true" autocomplete="false"\n' +
        '                                                   type="text" name="additionalCashRemaining"\n' +
        '                                                   data-currency data-input value="47100"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Additional Cash Remaining" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="flex-row">\n' +
        '                                    <a href="# " class="panel__button " data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="panel--bottom" data-result="basicTitle">\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '                    </div>\n' +
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                    <div class="results results--table">\n' +
        '                        <div class="results__table" data-table>\n' +
        '\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '\n' +
        '    </div>'
}