export default function (){
    return ' <div class="calculator__tab" data-tab data-calc-mode="Rent">\n' +
        '        <div class="calculator__header">\n' +
        '           {{#if h1}}  <h1>Rent vs. Buy Calculator</h1>{{else}}<h3>Rent vs. Buy Calculator</h3>{{/if}}\n' +       
        '            <p>See if its time to stop renting and buy a home.</p>\n' +
        '            <div class="calculator__navigate">\n' +
        '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link active" data-tab-link href="#basic" role="tab" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#advanced" role="tab" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
        '                    </li>\n' +
        '\n' +
        '\n' +
        '\n' +
        '                </ul>\n' +
        '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
        '                <div class="dropdown-menu">\n' +
        '                    <a class="dropdown-item active" href="#basic" mobile-link-item>BASIC</a>\n' +
        '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
        '                </div>\n' +
        '\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="calculator__content">\n' +
        '            <div class="tab-content">\n' +
        '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicRent">\n' +
        '\n' +
        '                    <div class="panel ">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Comparable rent</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  panel--40">\n' +
        '                                        <p>Monthly Rent</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="monthlyRent" autocomplete="false" type="text" name="montlyRent" data-currency data-input value="{{values.RentBuy.comparableRent.monthlyRent}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--40">\n' +
        '                                        <p>Renters Insurance (Monthly)</p>\n' +
        '                                        <div class="panel__input  ">\n' +
        '                                            <input title="rentersInsurance" autocomplete="false" type="text" data-input name="rentersInsurance" value="{{values.RentBuy.comparableRent.rentersInsurance}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="360" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">Home purchase</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Home Purchase Price</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="purchasePrice" autocomplete="false" type="text" HPP name="purchasePrice" data-currency data-input value="{{values.RentBuy.homePurchase.homePrice}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Down Payment</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="downPayment" autocomplete="false" type="text" DP name="downPayment" data-currency data-input value="{{values.RentBuy.homePurchase.downPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax" data-currency data-input value="{{values.RentBuy.homePurchase.propertyTax}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto ">\n' +
        '                                        <p>Home Owners Insurance (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="ownersInsurance" autocomplete="false" type="text" name="ownersInsurance" data-currency data-input value="{{values.RentBuy.homePurchase.homeownersIns}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">new loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.RentBuy.newLoanInfo.loanTerm}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.RentBuy.newLoanInfo.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--40">\n' +
        '                                        <p>Estimate Settlement Costs</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="estimateSettlement" autocomplete="false" type="text" name="estimateSettlement" data-currency data-input value="{{values.RentBuy.newLoanInfo.estimateCosts}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">assumptions</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Home Appreciation Rate</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="appreciationRate" data-percent autocomplete="false" type="text" name="appreciationRate" data-input value="{{values.RentBuy.assumptions.homeAppreciation}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Expected Yrs. in Home</p>\n' +
        '                                        <div class="panel__input  ">\n' +
        '                                            <input title="expectedYrs" autocomplete="false" type="text" data-input name="expectedYrs" value="{{values.RentBuy.assumptions.expectedYrs}}"\n' +
        '                                                   data-msg="Please enter a Expected Yrs. in Home"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Selling Costs (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="sellingCosts" data-percent autocomplete="false" type="text" name="sellingCosts" data-input value="{{values.RentBuy.assumptions.sellingCosts}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>State + Federal Tax Rate</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="stateTax" data-percent autocomplete="false" type="text" name="stateTax" data-input value="{{values.RentBuy.assumptions.stateFederalTax}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="flex-row">\n' +
        '                                    <a href="# " class="panel__button " data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom">\n' +
        '                                 <p>After<span class="c-blue" calculator-highlightedTextColor data-result="expectedYrs">5</span> years, your total savings will be</p>\n' +
        '                                  <h2  class="head-small" calculator-highlightedTextColor><i>$</i> <span data-result="savings">0.00</span><small class="top">*</small></h2>\n' +
        '                                   <div class="inline break">\n' +
        '                                        <p class="small">\n' +
        '                                            (You save<span data-result="savingspend" class="c-blue" calculator-highlightedTextColor>$0.00</span> in rent and tax savings, plus \n' +
        '                                             you gain<span data-result="sellHome" class="c-blue" calculator-highlightedTextColor>$0.00</span> from the sale of your home)\n' +
        '                                        </p>\n' +
        '                                    </div>\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="advanced" role="tabpanel"  data-calculator data-methods="advancedRent">\n' +
        '                    <div class="panel wrap">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Comparable rent</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  panel--40">\n' +
        '                                        <p>Monthly Rent</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="monthlyRent" autocomplete="false" type="text" name="montlyRent" data-currency data-input value="{{values.RentBuy.comparableRent.monthlyRent}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--40">\n' +
        '                                        <p>Renters Insurance (Monthly)</p>\n' +
        '                                        <div class="panel__input  ">\n' +
        '                                            <input title="rentersInsurance" autocomplete="false" type="text" data-input name="rentersInsurance" value="{{values.RentBuy.comparableRent.rentersInsurance}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="360" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">Home purchase</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Home Purchase Price</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="purchasePrice" autocomplete="false" type="text" HPP name="purchasePrice" data-currency data-input value="{{values.RentBuy.homePurchase.homePrice}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Down Payment</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="downPayment" autocomplete="false" type="text" DP name="downPayment" data-currency data-input value="{{values.RentBuy.homePurchase.downPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax" data-currency data-input value="{{values.RentBuy.homePurchase.propertyTax}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto ">\n' +
        '                                        <p>Home Owners Insurance (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="ownersInsurance" autocomplete="false" type="text" name="ownersInsurance" data-currency data-input value="{{values.RentBuy.homePurchase.homeownersIns}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">new loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.RentBuy.newLoanInfo.loanTerm}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.RentBuy.newLoanInfo.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--40">\n' +
        '                                        <p>Estimate Settlement Costs</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="estimateSettlement" autocomplete="false" type="text" name="estimateSettlement" data-currency data-input value="{{values.RentBuy.newLoanInfo.estimateCosts}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">assumptions</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Home Appreciation Rate</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="appreciationRate" data-percent autocomplete="false" type="text" name="appreciationRate" data-input value="{{values.RentBuy.assumptions.homeAppreciation}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Expected Yrs. in Home</p>\n' +
        '                                        <div class="panel__input  ">\n' +
        '                                            <input title="expectedYrs" autocomplete="false" type="text" data-input name="expectedYrs" value="{{values.RentBuy.assumptions.expectedYrs}}"\n' +
        '                                                   data-msg="Please enter a Expected Yrs. in Home"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Selling Costs (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="sellingCosts" data-percent autocomplete="false" type="text" name="sellingCosts" data-input value="{{values.RentBuy.assumptions.sellingCosts}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>State + Federal Tax Rate</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="stateTax" data-percent autocomplete="false" type="text" name="stateTax" data-input value="{{values.RentBuy.assumptions.stateFederalTax}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="flex-row">\n' +
        '                                    <a href="# " class="panel__button " data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom">\n' +
        '                                 <p>After<span class="c-blue" calculator-highlightedTextColor data-result="expectedYrs">5</span> years, your total savings will be</p>\n' +
        '                                  <h2  class="head-small" calculator-highlightedTextColor><i>$</i> <span data-result="savings">0.00</span><small class="top">*</small></h2>\n' +
        '                                   <div class="inline break">\n' +
        '                                        <p class="small">\n' +
        '                                            (You save<span data-result="savingspend" class="c-blue" calculator-highlightedTextColor>$0.00</span> in rent and tax savings, plus \n' +
        '                                             you gain<span data-result="sellHome" class="c-blue" calculator-highlightedTextColor>$0.00</span> from the sale of your home)\n' +
        '                                        </p>\n' +
        '                                    </div>\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '                    </div>\n' +
        '                    <div class="divider divider--double"></div>\n' +
        '                    <div class="results results--indent">\n' +
        '                        <div class=" results__graphic">\n' +
        '\n' +
        '                            <div data-chart>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                        <div class="results__chart" data-graphic>\n' +
        '\n' +
        '                        </div>\n' +
        '\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '\n' +
        '    </div>'
}