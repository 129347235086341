import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';

import Calculator from '../calculator-common'

export default class extends Calculator{
    constructor(el, param) {
        super(el, param)
        
    }
    basicMountlyQualifying(data, panel ){
        return new Promise((resolve, reject) => {
        const totincome = data.wages + data.investment + data.rental + data.otherIncome ;
        
        const totmonthpay = data.autoLoans +data.studentLoans +data.creditCards +data.alimonySupport +data.rentalLoans +data.otherPayments;
        
        const term =  data.loanTerm;
        const proptax =  data.propertyTax;
        const homeownersins = data.homeowners;
        const taxamt = proptax / 12;
        const insamt = homeownersins / 12;
        const taxsurance = taxamt + insamt;
        
        const cons37 = (totincome - totmonthpay) * 0.37;
        const agg43 = (totincome - totmonthpay) * 0.43;
        
        const consPI = cons37 - taxsurance;
        
        const aggPI = agg43 - taxsurance;
        
        let consLA = this.logicMethods.pv(data.interestRate, 12, (term * 12), (0 - consPI), 0)
        if (consLA < 0) { consLA = 0; }
        let aggLA = this.logicMethods.pv(data.interestRate, 12, (term * 12), (0 - aggPI), 0)
        if (aggLA < 0) { aggLA = 0; }
        
        
        panel.find(this.selectors.result).filter('[data-result="consLA"]').text(Currency(consLA,{ symbol: "$", separator: ",",formatWithSymbol: true , precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="aggLA"]').text(Currency(aggLA,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
            resolve(true)
        })
    }
    advancedMountlyQualifying(data, panel ){
            return new Promise((resolve, reject) => {
        const totincome = data.wages + data.investment + data.rental + data.otherIncome ;
        
        const totmonthpay = data.autoLoans +data.studentLoans +data.creditCards +data.alimonySupport +data.rentalLoans +data.otherPayments;
        
        const term =  data.loanTerm;
        const interestrate = data.interestRate/ 100;
        const proptax =  data.propertyTax;
        const homeownersins = data.homeowners;
        const taxamt = proptax / 12;
        const insamt = homeownersins / 12;
        const taxsurance = taxamt + insamt;
        
        const cons37 = (totincome - totmonthpay) * 0.37;
        
        const agg43 = (totincome - totmonthpay) * 0.43;
        
        const consPI = cons37 - taxsurance;
        
        const aggPI = agg43 - taxsurance;
        
        let consLA = this.logicMethods.pv(data.interestRate, 12, (term * 12), (0 - consPI), 0)
        
        if (consLA < 0) { consLA = 0; }
        let aggLA = this.logicMethods.pv(data.interestRate, 12, (term * 12), (0 - aggPI), 0)
        
        if (aggLA < 0) { aggLA = 0; }
        
        let consI = (consLA * interestrate) / 12;
        let consP = consPI - consI;
        consI = consI.toFixed(2);
        consP = consP.toFixed(2);
        let aggI = (aggLA * interestrate) / 12;
        let aggP = aggPI - aggI;
        aggI = aggI.toFixed(2);
        aggP = aggP.toFixed(2);
      
        const conMonthlyTotal = Number(consP.replace('$', '').replace(/,/g, '')) + Number(consI.replace('$', '').replace(/,/g, '')) + taxsurance;
        const aggMonthlyTotal = Number(aggP.replace('$', '').replace(/,/g, '')) + Number(aggI.replace('$', '').replace(/,/g, '')) + taxsurance;
        panel.find(this.selectors.chart).highcharts({
            chart: {
                plotBackgroundColor: '#ffffff',
                
                type: 'column',
                backgroundColor: 'transparent',
                marginTop: 70,
                marginBotom: 20
            },
            title: {
                // text: '<div style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color: #014f6e;">You may qualify for a loan amount ranging from ' + '<span style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color:#014f6e; font-weight: bold;">$' + numberWithCommas(consLA) + '</span> <span style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color:#014f6e;"> to </span> <span style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color:#014f6e; font-weight: bold;">$' + numberWithCommas(aggLA) + "</span></div>"
                useHTML: true,
                text: '<div class="titlediv secondtitle text-center">You may qualify for a loan amount ranging <br/> from <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight+'">' + Currency(consLA,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format() + ' - ' +  Currency(aggLA,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format()+ '</span></div></div>',
                align: 'center',
                backgroundColor: '#EFEFEF',
                margin: 150,
                verticalAlign:'top',
                widthAdjust: 0
            },
            xAxis: {
                categories: ['Conservative', 'Aggressive'],
                
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            
            tooltip: {
                headerFormat: '<br/>',
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                symbolWidth: 20,
                symbolHeight: 20,
                padding: 15,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {point.name}</b>  {point.val}  </span><br/>',
                shared: false,
              
           
            },
            legend: {
                
                enabled:false,
                y: 80,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6,
                useHTML: true,
                floating: true,
                
                labelFormatter: function () {
                    
                    return '<div><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                }
                
            },
            
            responsive: {
                rules: [{
                    condition: {
                        minwidth: 100,
                        maxWidth: 400
                    },
                    chartOptions: {
                        chart: {
                            height: 300,
                            marginTop: 80,
                            marginBotom: 20
                        },
                        
                       
                    }
                }]
            },
            series: [{
                
                
                data: [{
                    name: "Conservative",
                    color: this.params.tabs.highcharts.column.Principal,
                    y: Number(consLA),
                    val:Currency(consLA,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0}).format()
                },{
                    name: "Aggressive",
                    color:  this.params.tabs.highcharts.column.Interest,
                    y: Number(aggLA),
                    val:Currency(aggLA,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format()
                }],
                // id: Currency(consLA,{separator: ","}).format(),
                
            }]
        });
        
        panel.find(this.selectors.graphic).highcharts({
            chart: {
                plotBackgroundColor: '#ffffff',
                type: 'column',
                backgroundColor: 'transparent',
                marginTop: 70,
                marginBotom: 20
            },
            title: {
                // text: '<div style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color: #014f6e;">You may qualify for a loan amount ranging from ' + '<span style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color:#014f6e; font-weight: bold;">$' + numberWithCommas(consLA) + '</span> <span style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color:#014f6e;"> to </span> <span style="font-family: Myriad Pro, Gill Sans, Gill Sans MT, Calibri, sans-serif;color:#014f6e; font-weight: bold;">$' + numberWithCommas(aggLA) + "</span></div>"
                useHTML: true,
                text: '<div class="titlediv secondtitle text-center">Your monthly payment will <br /> range from <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight+'">' +  Currency(conMonthlyTotal,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format() + ' - '  +  Currency(aggMonthlyTotal,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format() + '</span></div></div>',
                align: 'center',
                backgroundColor: '#EFEFEF',
                marginTop: 50
            },
            xAxis: {
                categories: ['Conservative', 'Aggressive']
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}  <span class="small">({point.percentage:.0f}%)</span></span>',
                shared: true,
                pointFormatter: function () {
                    return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> : '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format()+' <span class="small">('+Math.round(this.percentage)+'%)</span></span>'
                }
            },
            legend: {
                enabled: false,
                y: 80,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6,
                useHTML: true,
                floating: true,
                //labelFormat: '<span class="legenddtitle">{series.name}</span>: <b>${point.y}</b> ({point.percentage:.0f}%)<br/>',
                labelFormatter: function () {
                    
                    return '<div><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                }
                
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderWidth: 0
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        minwidth: 100,
                        maxWidth: 400
                    },
                    chartOptions: {
                        chart: {
                            height: 300,
                            marginTop: 100,
                            marginBotom: 20
                        },
                        legend: {
                            y: 70,
                            align: 'center',
                            layout: 'horizontal',
                            verticalAlign: 'top',
                            useHTML: true,
                            floating: true,
                            labelFormatter: function () {
                                return '<div"><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                            }
                        },
                     
                    }
                }]
            },
            series: [{
                name: 'Principal',
                data: [Number(consP), Number(aggP)],
                color: this.params.tabs.highcharts.pie.Principal
            }, {
                name: 'Interest',
                data: [Number(consI), Number(aggI)],
                color: this.params.tabs.highcharts.pie.Interest
            },
                {
                    name: 'Taxes',
                    data: [taxamt, taxamt],
                    color: this.params.tabs.highcharts.pie.Taxes
                },
                {
                    name: 'Insurance',
                    data: [insamt, insamt],
                    color: this.params.tabs.highcharts.pie.Insurance
                }]
        });
        
        panel.find(this.selectors.result).filter('[data-result="consLA"]').text(Currency(consLA,{ symbol: "$", separator: ",",formatWithSymbol: true , precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="aggLA"]').text(Currency(aggLA,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
                resolve(true)
            })
    }
}