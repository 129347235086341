import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';

import Calculator from '../calculator-common'

export default class extends Calculator{
    constructor(el, param) {
        super(el, param)
        
    }
    basicPurchasing(data, panel ){
        return new Promise((resolve, reject) => {
        const downpayment = data.downPayment,
            totmonth =  data.totalMonthlyPayment,
            term = data.loanTerm,
            interestrate = data.interestRate;
        let pv2 = this.logicMethods.pv(interestrate, 12, (term * 12), totmonth, 0);
        let hmuca;
        
        if (pv2 < 0) {
            pv2 = Math.abs(pv2)
            hmuca = Math.abs(pv2) + Number(downpayment);
        } else {
            
            hmuca = (0 - pv2) + Number(downpayment);
            if (hmuca < 0) {
                hmuca = 0;
            }
        }
        panel.find(this.selectors.result).filter('[data-result="totalLoan"]').text(Currency(hmuca,{separator: ",", precision: 0}).format())
        
        panel.find(this.selectors.result).filter('[data-result="DownPayment"]').text(Currency(downpayment,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="LoanAmount"]').text(Currency(pv2,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
        resolve(true)
    })
    }
    advancedPurchasing(data, panel ){
    return new Promise((resolve, reject) => {
        const downpayment = data.downPayment,
            totmonth = data.totalMonthlyPayment,
            term = data.loanTerm,
            interestrate = data.interestRate,
            insamt = (data.mortgageins / 12) + (data.homeowners / 12),
            proptax = data.propertyTax / 12,
            hoaOther = (data.hoa / 12) + (data.other / 12);
        let truepayment = totmonth - (data.propertyTax / 12) - (data.homeowners / 12) - (data.mortgageins / 12) - (data.hoa / 12) - (data.other / 12);
        
        let pv2 = this.logicMethods.pv(interestrate, 12, (term * 12), truepayment, 0);
        let hmuca;
        
        if (pv2 < 0) {
            pv2 = Math.abs(pv2)
            hmuca = Math.abs(pv2) + Number(downpayment);
        } else {
            
            hmuca = (0 - pv2) + Number(downpayment);
            if (hmuca < 0) {
                hmuca = 0;
            }
        }
        const interestamt = Math.abs(pv2) * ((interestrate / 100) / 12);
        
        var pieColors = (() => {
            var colors = [this.params.tabs.highcharts.column.Interest ,this.params.tabs.highcharts.pie.Interest]
            return colors;
        })();
        var pieColorsOverTime = (() => {
            var colors2 = [
                this.params.tabs.highcharts.pie.Principal,
                this.params.tabs.highcharts.pie.Interest,
                this.params.tabs.highcharts.pie.Taxes,
                this.params.tabs.highcharts.pie.Insurance,
                this.params.tabs.highcharts.pie.Fees
            ]
            return colors2;
        })();
        panel.find(this.selectors.result).filter('[data-result="totalLoan"]').text(Currency(hmuca,{separator: ",", precision: 0}).format())
        
        panel.find(this.selectors.graphic).highcharts({
            chart: {
                type: 'pie',
                height: 260,
                backgroundColor: 'transparent',
                marginBottom: 50,
                events: {
                
                }
            },
            
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                symbolHeight: 14,
                symbolWidth: 14,
                symbolRadius: 6,
                lineHeight: 24,
                itemMarginTop: 10,
                itemMarginBottom: 10,
                useHTML: true,
                labelFormatter: function () {
                    return '<div class="chart__legend large"><span class="chart__title">' + this.name + '</span><span class="chart__value"> $' + this.id + '</span></div>';
                }
            },
            title: {
                useHTML: true,
                text: '<div class="titlediv"><div class="secondtitle">Your <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight +'">' +Currency(totmonth,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format()  + '</span> monthly payment breakdown</div></div>',
                align: 'center',
                backgroundColor: '#EFEFEF',
                marginTop: 0
            },
            
            
            plotOptions: {
                pie: {
                    innerSize: 88,
                    size: 130,
                    allowPointSelect: true,
                    colors: pieColorsOverTime,
                    cursor: 'pointer',
                    dataLabels: {
                        overflow: 'allow',
                        crop: false,
                        enabled: false,
                        verticalAlign: 'top',
                        format: '{point.percentage:.1f} %',
                        style: {
                            color: '#888888',
                            fontSize: '12px'
                            
                        }
                    },
                    showInLegend: true,
                    borderWidth: 0,
                    series: {
                        allowPointSelect: true
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b>{point.name}</b> - ${point.id} </span>',
    
    
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 450
                    },
                    chartOptions: {
                        height: 600,
                        plotOptions: {
                        
                        
                        },
                        
                        legend: {
                            symbolHeight: 14,
                            symbolWidth: 14,
                            symbolRadius: 6,
                            lineHeight: 20,
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal',
                            useHTML: true,
                            labelFormatter: function () {
                                return '<div class="chart__legend "><span class="chart__title">' + this.name + '</span><span class="chart__value"> $' + this.id + '</span></div>';
                            }
                        },
                        title: {
                             },
                        chart: {
                            type: 'pie',
                            height: 370,
                            marginTop: 0,
                            events: {
                                redraw: function () {
                              
                                }
                            }
                        },
                        
                    }
                }]
            },
            series: [{
                name: "Percent",
                colorByPoint: true,
                data: [{
                    name: "Principal",
                    y: (truepayment.toFixed(2) - interestamt.toFixed(2)) / 1, //totmonth,
                    id:Currency((truepayment - interestamt),{separator: ",", precision: 0}).format()
                    },
                    {
                        name: "Interest",
                        y: interestamt / 1, //totmonth,
                        id: Currency(interestamt,{separator: ",", precision: 0}).format()
                    },
                    {
                        name: "Taxes",
                        y: proptax / 1, //totmonth,
                        id: Currency(proptax, { separator: ",", precision: 0 }).format()
                    },
                    {
                        name: "Insurance",
                        y: insamt / 1,
                        id: Currency(insamt, { separator: ",", precision: 0 }).format()
                    },
                    {
                        name: "HOA and Other",
                        y: hoaOther / 1,
                        id: Currency(hoaOther, { separator:",", precision: 0 }).format()
                    }]
            }]
            
        });
        panel.find(this.selectors.chart).highcharts({
            chart: {
                type: 'pie',
                height: 130,
                backgroundColor: 'transparent',
                
            },
            
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                symbolHeight: 14,
                symbolWidth: 14,
                symbolRadius: 6,
                lineHeight: 24,
                itemMarginTop: 10,
                itemMarginBottom: 10,
                useHTML: true,
                labelFormatter: function () {
                    return '<div class="chart__legend large"><span class="chart__title">' + this.name + '</span><span class="chart__value"> $' + this.id + '</span></div>';
                }
            },
            title: '',
            
            plotOptions: {
                pie: {
                    innerSize: 63,
                    size: 95,
                    allowPointSelect: true,
                    colors: pieColors,
                    cursor: 'pointer',
                    dataLabels: {
                        overflow: 'allow',
                        crop: false,
                        enabled: false,
                        verticalAlign: 'top',
                        format: '{point.percentage:.1f} %',
                        style: {
                            color: '#888888',
                            fontSize: '12px'
                            
                        }
                    },
                    showInLegend: true,
                    borderWidth: 0,
                    series: {
                        allowPointSelect: true
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {point.name}</b> - ${point.id} </span>',
    
            },
            responsive: {
                rules:[{
                    condition: {
                        maxWidth: 450
                    },
                    chartOptions: {
                        
                        plotOptions: {
                            pie:{
                                visible: false
                            }
                        },
                        title: {
                            margin:0
                        },
                        legend:{
                            align: 'left',
                            verticalAlign: 'bottom',
                            layout: 'vertical',
                        },
                        
                        chart: {
                            type: 'pie',
                            height: 110,
                            marginTop: 0,
                            
                        },
                        
                    }
                }]
            },
            series: [{
                name: "Percent",
                colorByPoint: true,
                data: [{
                    name: "Down Payment",
                    y: downpayment / 1,//hmuca.toFixed(2),
                    id:  Currency(downpayment,{separator: ",", precision: 0}).format()
                }, {
                    name: "Loan Amount",
                    y: Math.abs(pv2) / 1, //hmuca.toFixed(2),
                    id:  Currency(pv2,{separator: ",", precision: 0}).format()
                }]
            }]
            
            
        })
    resolve(true)
})
    }
    amortizationPurchasing(data, panel) {
    return new Promise((resolve, reject) => {
        const downpayment = data.downPayment,
            totmonth =  data.totalMonthlyPayment,
            term = data.loanTerm ,
            interestrate = data.interestRate;
        let pv2 = this.logicMethods.pv(interestrate, 12, (term * 12), totmonth, 0);
        let hmuca;
        
        if (pv2 < 0) {
            pv2 = Math.abs(pv2)
            hmuca = Math.abs(pv2) + Number(downpayment);
        } else {
            
            hmuca = (0 - pv2) + Number(downpayment);
            if (hmuca < 0) {
                hmuca = 0;
            }
        }
        
        
        panel.find(this.selectors.result).filter('[data-result="totalLoan"]').text(Currency(hmuca,{separator: ",", precision: 0}).format())
        
        panel.find(this.selectors.result).filter('[data-result="DownPayment"]').text(Currency(downpayment,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="LoanAmount"]').text(Currency(pv2,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
        const amortdata = this.logicMethods.AmortizationCompute(pv2,interestrate,term* 12);
        let filterData={}
        filterData.Items = amortdata.amortization.reduce((function(hash){
            return function(array,obj){
                if(!hash[obj.year])
                    hash[obj.year]=[obj]
                else
                    hash[obj.year].push(obj);
                return hash;
            };
            
        })({}),[])
    
        filterData.principalPaid = true
        filterData.totalInterest = true
        this.renderTable(filterData,    panel.find(this.selectors.table)).then(() => {
           // panel.find('.collapse').collapse({'toogle':false})
           
        })
    resolve(true)
})
    }
    
}