import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';

import Calculator from '../calculator-common'

export default class extends Calculator{
    constructor(el, param) {
        super(el, param)
        
    }
    basicRent(data, panel ){
        return new Promise((resolve, reject) => {
        
            const MR = data.montlyRent,
            RI = data.rentersInsurance,
            HPP = data.purchasePrice,
            DP =  data.downPayment,
            PT =  data.propertyTax,
            HOI =  data.ownersInsurance,
            NLterm = data.loanTerm,
            NLint = data.interestRate,
            ESC =  data.estimateSettlement,
            HAR = data.appreciationRate,
            EYH = data.expectedYrs,
            SC = data.sellingCosts,
            SF =  data.stateTax
    
        const loanamount = HPP - DP,
            printerest = this.logicMethods.pmt((NLint / 12) / 100, NLterm * 12, -loanamount, 0, 0),
            taxes = (PT / 12).toFixed(2),
            insurance = (HOI / 12).toFixed(2),
            cuminterest =  Math.abs(this.logicMethods.CUMIPMT(((NLint / 12) / 100), (NLterm * 12), loanamount, 1, (EYH * 12), 0)).toFixed(2),
            cumtaxsurance = (Number(insurance) + Number(taxes)) * (Number(EYH) * 12).toFixed(2),
            taxbreak = (Number(cuminterest) + Number(cumtaxsurance)) * Number(SF / 100).toFixed(2),
            homevalSD =  this.logicMethods.FV2((HAR / 12) / 100, EYH * 12, 0, -HPP).toFixed(2),
            realtorCost = (homevalSD * (SC / 100)).toFixed(2),
            sellHome = (Number(homevalSD) - Number(HPP) + (Number(printerest) * 12 * Number(EYH)) - Number(cuminterest) - Number(realtorCost)).toFixed(2),
            renting = ((MR + RI) * EYH * 12).toFixed(2),
            buying = (((Number(printerest) + Number(taxes) + Number(insurance)) * 12 * EYH) + Number(ESC) - Number(taxbreak)).toFixed(2),
            savings = (Number(renting) - Number(buying) + Number(sellHome)).toFixed(2),
            savingspend = (Number(renting) - Number(buying)).toFixed(2)
        
        
        panel.find(this.selectors.result).filter('[data-result="expectedYrs"]').text(EYH)
        panel.find(this.selectors.result).filter('[data-result="savings"]').text(Currency(savings,{ symbol: "", separator: ",",formatWithSymbol: true ,precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="savingspend"]').text(Currency(savingspend,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="sellHome"]').text(Currency(sellHome,{ symbol: "$", separator: "," ,formatWithSymbol: true,precision: 0}).format())
        resolve(true)
    })
    }
    
    advancedRent(data, panel ){
    return new Promise((resolve, reject) => {
        
        const MR = data.montlyRent,
            RI = data.rentersInsurance,
            HPP = data.purchasePrice,
            DP =  data.downPayment,
            PT =  data.propertyTax,
            HOI =  data.ownersInsurance,
            NLterm = data.loanTerm,
            NLint = data.interestRate,
            ESC =  data.estimateSettlement,
            HAR = data.appreciationRate,
            EYH = data.expectedYrs,
            SC = data.sellingCosts,
            SF =  data.stateTax
    
        const loanamount = HPP - DP,
            printerest = this.logicMethods.pmt((NLint / 12) / 100, NLterm * 12, -loanamount, 0, 0),
            taxes = (PT / 12).toFixed(2),
            insurance = (HOI / 12).toFixed(2),
            cuminterest =  Math.abs(this.logicMethods.CUMIPMT(((NLint / 12) / 100), (NLterm * 12), loanamount, 1, (EYH * 12), 0)).toFixed(2),
            cumtaxsurance = (Number(insurance) + Number(taxes)) * (Number(EYH) * 12).toFixed(2),
            taxbreak = (Number(cuminterest) + Number(cumtaxsurance)) * Number(SF / 100).toFixed(2),
            payoffbalSD =  this.logicMethods.FV2((NLint / 12) / 100, EYH * 12, printerest, -loanamount).toFixed(2),
            homevalSD =  this.logicMethods.FV2((HAR / 12) / 100, EYH * 12, 0, -HPP).toFixed(2),
            realtorCost = (homevalSD * (SC / 100)).toFixed(2),
            sellHome = (Number(homevalSD) - Number(HPP) + (Number(printerest) * 12 * Number(EYH)) - Number(cuminterest) - Number(realtorCost)).toFixed(2),
            renting = ((MR + RI) * EYH * 12).toFixed(2),
            buying = (((Number(printerest) + Number(taxes) + Number(insurance)) * 12 * EYH) + Number(ESC) - Number(taxbreak)).toFixed(2),
            savings = (Number(renting) - Number(buying) + Number(sellHome)).toFixed(2),
            savingspend = (Number(renting) - Number(buying)).toFixed(2),
            savinghome = (Number(sellHome) - Number(ESC)).toFixed(2),
            homepayment = (Number(printerest) + Number(taxes) + Number(insurance)).toFixed(2);
    
    
        panel.find(this.selectors.result).filter('[data-result="expectedYrs"]').text(EYH)
        panel.find(this.selectors.result).filter('[data-result="savings"]').text(Currency(savings,{ symbol: "", separator: ",",formatWithSymbol: true,precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="savingspend"]').text(Currency(savingspend,{ symbol: "$", separator: ",",formatWithSymbol: true,precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="sellHome"]').text(Currency(sellHome,{ symbol: "$", separator: "," ,formatWithSymbol: true,precision: 0}).format())
    
        
        
        panel.find(this.selectors.chart).highcharts({
            chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderWidth: null,
                plotShadow: false,
                type: 'column',
                backgroundColor: 'transparent',
                marginTop: 100,
                marginBotom: 20
            },
            title: {
                useHTML: true,
                text: '<div class="titlediv secondtitle text-center">After <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight+'">' + EYH + '</span> years, your total savings are <span class="firsttitle" style="color:'+this.params.tabs.highcharts.column.Principal+'">' + Currency(savings,{ symbol: "$", separator: ",",formatWithSymbol: true,precision: 0 }).format()+ '</span><br />' +
                    '( You save <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight+'">' + Currency(savingspend,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0}).format() + '</span> in rent and tax savings, plus you gain <span class="firsttitle" style="color:'+this.params.tabs.highcharts.column.Principal+'">' + Currency(sellHome,{ symbol: "$", separator: ",",formatWithSymbol: true,precision: 0 }).format() + '</span> from the sale of your home )</div>',
                align: 'center',
                backgroundColor: 'transparent',
                margin: 180,
        
            },
            xAxis: {
                categories: ['Renting vs Buying']
            },
            yAxis: {
                title: ['']
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> - ${point.y}  </span>',
                shared: false,
                pointFormatter: function () {
                    return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> - '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true,precision: 0 }).format()+'</span>'
                }
            },
            legend: {
             enabled: false
        
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    showInLegend: false,
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}: <b>{point.percentage:.1f} %</b>',
                        style: {
                            color: 'black'
                        }
                    },
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        minwidth: 100,
                        maxWidth: 400
                    },
                    chartOptions: {
                        chart: {
                            height: 350,
                            marginTop: 160,
                            marginBottom: 20
                        },
                      
                      
                    }
                }]
                /**/
            },
            /**/
            series:[{
                name: "Renting ",
                data: [{
                    name: "Renting - " + Currency(renting,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0}).format() ,
                    y: Number(renting)
                }],
                color: this.params.tabs.highcharts.column.Principal
            },
                {
                    name: "Buying " ,
                    data: [{
                        name: "Buying - " +  Currency(buying,{ symbol: "$", separator: ",",formatWithSymbol: true,precision: 0 }).format(),
                        y: Number(buying)
                    }],
                    color: this.params.tabs.highcharts.column.Interest
                }
            ]
        });
        
        panel.find(this.selectors.graphic).highcharts({
            chart: {
                type: 'column',
                plotBackgroundColor: '#ffffff',
                backgroundColor: 'transparent',
                marginTop: 100,
                marginBotom: 20
            },
            title: {
                useHTML: true,
                text: '<div class="titlediv secondtitle">Your monthly home payment is<span class= "firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight+'" > ' + Currency(homepayment,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0}).format()  + '</span><br /> compared to a monthly rent of<span class= "firsttitle" style="color:'+this.params.tabs.highcharts.column.Principal+'" > ' + Currency(MR,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0}).format()+ '</span> </div>',
                align: 'center',
                backgroundColor: 'transparent',
                margin: 50
        
            },
            xAxis: {
                categories: ['Renting', 'Buying']
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}  <span class="small">({point.percentage:.0f}%)</span></span>',
                shared: true,
                pointFormatter: function () {
                    return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> : '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true,precision: 0 }).format()+' <span class="small">('+Math.round(this.percentage)+'%)</span></span>'
                }
            },
            legend: {
                enabled:false,
            },
            plotOptions: {
                pie: {
                 
                    showInLegend: false,
                },
                column: {
                    stacking: 'normal',
                    borderWidth: 0
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        minwidth: 100,
                        maxWidth: 400
                    },
                    chartOptions: {
                        chart: {
                            height: 350,
                            marginTop: 120,
                            marginBotom: 10
                        },
                        legend: {
                            y: 95,
                            align: 'center',
                            layout: 'horizontal',
                            verticalAlign: 'top',
                            useHTML: true,
                            floating: true,
                            labelFormatter: function () {
                                return '<div><span class="legenddtitle" > ' + this.name + '</span ></div > <hr />';
                            }
                        },
                      
                    }
                }]
            },
            series: [
                {
                name: 'Rent Payment',
                data: [MR, 0],
                color:this.params.tabs.highcharts.column.Principal
            },
                {
                    name: 'Principal & Interest',
                    data: [0, Number(printerest)],
                    color: this.params.tabs.highcharts.pie.Interest
                },
                {
                    name: 'Taxes',
                    data: [0, Number(taxes)],
                    color: this.params.tabs.highcharts.pie.Taxes
                },
                {
                    name: 'Insurance',
                    data: [0, Number(insurance)],
                    color: this.params.tabs.highcharts.pie.Insurance
                }]
        });
    resolve(true)
})
    }
}