export default function (){
    return '  <div class="calculator__tab" data-tab data-calc-mode="Mortgage">\n' +
    '        <div class="calculator__header">\n' +
    '           {{#if h1}}  <h1>Mortgage payment Calculator</h1>{{else}}<h3>Mortgage payment Calculator</h3>{{/if}}\n' +
    '            <p>Calculate how much your monthly mortgage payment could be. </p>\n' +
    '            <div class="calculator__navigate">\n' +
    '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
    '                    <li class="nav-item">\n' +
    '                        <a class="nav-link active panel__button"  data-tab-link  href="#basic" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
    '                    </li>\n' +
    '                    <li class="nav-item">\n' +
    '                        <a class="nav-link panel__button" data-tab-link href="#advanced" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
    '                    </li>\n' +
    
    '                       {{#if amortTab}}<li class="nav-item">\n' +
    '                        <a class="nav-link panel__button" data-tab-link href="#amortization" aria-controls="AMORTIZATION" aria-selected="true">AMORTIZATION</a>\n' +
    '                    </li>{{/if}}\n' +
    '                </ul>\n' +
    '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
    '                <div class="dropdown-menu">\n' +
    '                    <a class="dropdown-item active" href="#basic" mobile-link-item>BASIC</a>\n' +
    '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
    '                   {{#if amortTab}}<a class="dropdown-item" href="#amortization" mobile-link-item>AMORTIZATION</a>{{/if}}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="calculator__content">\n' +
    '            <div class="tab-content">\n' +
    '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicMountly">\n' +
    '                    <div class="panel">\n' +
    '                        <form class="panel__body panel--left" autocomplete="off">\n' +
    '                            <div class="panel--wrapper">\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Loan Amount</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="loanAmount" type="text" name="loanAmount" data-currency data-input value="{{values.Mortgage.basic.loanAmount}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Loan Term (Yrs)</p>\n' +
    '                                    <div class="panel__input ">\n' +
    '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.Mortgage.basic.loanTerm}}" \n' +
    '                                               data-msg="Please enter a Loan Term"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="30" />\n' +
    '\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Interest Rate (%)</p>\n' +
    '                                    <div class="panel__input icon--right ">\n' +
    '                                        <i>%</i>\n' +
    '                                        <input title="interestRate" type="text" name="interestRate" data-percent data-input\n' +
    '                                               value="{{values.Mortgage.basic.interestRate}}"\n' +
    '                                               data-msg="Please enter a number between, 1 and 15"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="15" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel--button flex-row">\n' +
    '\n' +
    '                                    <a href="# " class="panel__button" data-calculate calculator-button>calculate</a>\n' +
    '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                        </form>\n' +
    '                        <div class="panel--right">\n' +
    '                            <p>Monthly Payments </p>\n' +
    '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="monthlyPayments">0.00</span>{{#if footnote}}<small calculator-small-asterisk>*</small>{{/if}}</h2>\n' +
    '                            <div class="divider"></div>\n' +
    '                            <div class="inline">\n' +
    '                                <p>Principal <span data-result="principal">$0.00</span></p>\n' +
    '                                <p>Interest <span data-result="printerest">$0.00</span></p>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="tab-pane " id="advanced" role="tabpanel"  data-calculator data-methods="advancedMountly">\n' +
    '                    <div class="panel">\n' +
    '                        <form class="panel__body" autocomplete="off">\n' +
    '                            <div class="panel--wrapper">\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Loan Amount</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="loanAmount" autocomplete="false" type="text" name="loanAmount" data-currency data-input value="{{values.Mortgage.basic.loanAmount}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Loan Term (Yrs)</p>\n' +
    '                                    <div class="panel__input ">\n' +
    '\n' +
    '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.Mortgage.basic.loanTerm}}"\n' +
    '                                               data-msg="Please enter a Loan Term"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="30" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Interest Rate (%)</p>\n' +
    '                                    <div class="panel__input icon--right ">\n' +
    '                                        <i>%</i>\n' +
    '                                        <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Mortgage.basic.interestRate}}"\n' +
    '                                               data-msg="Please enter a number between, 1 and 15"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="15" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Property Tax (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="propertyTax" autocomplete="false" type="text" name="propertyTax" data-currency data-input value="{{values.Mortgage.advanced.propertyTax}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a roperty Tax" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Homeowners Ins. (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="homeowners" autocomplete="false" type="text" name="homeowners" data-currency data-input value="{{values.Mortgage.advanced.homeownersIns}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a property Tax" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Mortgage Ins. (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="mortgageins" autocomplete="false" type="text" name="mortgageins" data-currency data-input value="{{values.Mortgage.advanced.mortgageIns}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a mortgage Tax" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                             <div class="panel__item panel--large">\n' +
    '                                    <p>HOA dues (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="hoa" autocomplete="false" type="text" name="hoa" data-currency data-input value="{{values.Mortgage.advanced.hoaDues}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter HOA dues" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Other (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="other" autocomplete="false" type="text" name="other" data-currency data-input value="{{values.Mortgage.advanced.other}}" data-rule-required data-rule-maxlength="7" data-msg="Please enter a number" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                            <div class="flex-row">\n' +
    '\n' +
    '                                <a href="# " class="panel__button" data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    '                            </div>\n' +
    '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
    '                        </form>\n' +
    '\n' +
    '                    </div>\n' +
    '                    <div class="results">\n' +
    '                        <div class="results__chart order">\n' +
    '                            <p>Monthly Payments with Tax and Insurance</p>\n' +
    '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="monthlyPayments">0.00</span><small calculator-small-asterisk>*</small></h2>\n' +
    '                            <div class="divider"></div>\n' +
    '                            <div data-chart>\n' +
    '\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="results__graphic " >\n' +
                        '                            <div data-graphic class="left-indent">\n' +

                            '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                {{#if amortTab}} <div class="tab-pane " id="amortization" role="tabpanel"  data-calculator data-methods="amortization">\n' +
    '                    <div class="panel d-none d-sm-flex">\n' +
    '                        <form class="panel__body panel--left" autocomplete="off">\n' +
    '                            <div class="panel--wrapper">\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Loan Amount</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="loanAmount" type="text" name="loanAmount" data-currency data-input value="250000" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Loan Term (Yrs)</p>\n' +
    '                                    <div class="panel__input ">\n' +
    '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="30"\n' +
    '                                               data-msg="Please enter a Loan Term"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="30" />\n' +
    '\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Interest Rate (%)</p>\n' +
    '                                    <div class="panel__input icon--right ">\n' +
    '                                        <i>%</i>\n' +
    '                                        <input title="interestRate" data-percent type="text" name="interestRate" data-input\n' +
    '                                               value="4.5"\n' +
    '                                               data-msg="Please enter a number between, 1 and 15"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="15" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '\n' +
    '                            </div>\n' +
    '                            <div class="flex-row">\n' +
    '\n' +
    '                                <a href="# " class="panel__button" data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    '                            </div>\n' +
    '                        </form>\n' +
    '                        <div class="panel--right">\n' +
    '                            <p>Monthly Payments </p>\n' +
    '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="monthlyPayments">0.00</span><small calculator-small-asterisk>*</small></h2>\n' +
    '                            <div class="divider"></div>\n' +
    '                            <div class="inline">\n' +
    '                                <p>Principal <span data-result="principal">$0.00</span></p>\n' +
    '                                <p>Interest <span data-result="printerest">$0.00</span></p>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
    '                    <div class="results results--table">\n' +
    '                        <div class="results__table" data-table>\n' +
    '\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>{{/if}}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>'
}