export default function (){
    return '  <div class="calculator__tab" data-tab data-calc-mode="biweeklyMortgage">\n' +
    '        <div class="calculator__header">\n' +
        '           {{#if h1}}  <h1>Bi-Weekly Payment Calculator</h1>{{else}}<h3>Bi-Weekly Payment Calculator</h3>{{/if}}\n' +       
    '            <p>Accelerate your loan payoff and save money on interest by making two smaller payments each month.</p>\n' +
    '            <div class="calculator__navigate">\n' +
    '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
    '                    <li class="nav-item">\n' +
    '                        <a class="nav-link active" data-tab-link  href="#basic" role="tab" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
    '                    </li>\n' +
    '                    <li class="nav-item">\n' +
    '                        <a class="nav-link " data-tab-link  href="#advanced" role="tab" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
    '                    </li>\n' +
    
    '                       {{#if amortTab}}<li class="nav-item">\n' +
    '                        <a class="nav-link " data-tab-link href="#amortization" role="tab" aria-controls="AMORTIZATION" aria-selected="true">AMORTIZATION</a>\n' +
    '                    </li>{{/if}}\n' +
    '\n' +
    '                </ul>\n' +
    '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
    '                <div class="dropdown-menu">\n' +
    '                    <a class="dropdown-item active" href="#basic" mobile-link-item>BASIC</a>\n' +
    '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
    '                   {{#if amortTab}}<a class="dropdown-item" href="#amortization" mobile-link-item>AMORTIZATION</a>{{/if}}\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="calculator__content">\n' +
    '            <div class="tab-content">\n' +
    '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicMountly">\n' +
    '                    <div class="panel">\n' +
    '                        <form class="panel__body panel--left" autocomplete="off">\n' +
    '                            <div class="panel--wrapper flex-end">\n' +
    '                                <div class="panel__item panel--auto">\n' +
    '                                    <p>Loan Amount</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="loanAmount" type="text" name="loanAmount" data-currency data-input value="{{values.BiWeekly.basic.loanAmount}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Loan Term (Yrs)</p>\n' +
    '                                    <div class="panel__input ">\n' +
    '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.BiWeekly.basic.loanTerm}}"\n' +
    '                                               data-msg="Please enter a Loan Term"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="30" />\n' +
    '\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                               <div class="break"></div>\n'+
    '                                <div class="panel__item panel--auto panel--mt">\n' +
    '                                    <p>Interest Rate (%)</p>\n' +
    '                                    <div class="panel__input icon--right ">\n' +
    '                                        <i>%</i>\n' +
    '                                        <input title="interestRate" type="text" name="interestRate" data-percent data-input\n' +
    '                                               value="{{values.BiWeekly.basic.interestRate}}"\n' +
    '                                               data-msg="Please enter a number between, 1 and 15"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="15" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <a href="# " class="panel__button  panel--mt  w-auto" data-calculate calculator-button>calculate</a>\n' +
    '                               <div class="break"></div>\n'+
    '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    '                            </div>\n' +
    '                        </form>\n' +
    '                        <div class="panel--right">\n' +
    '                            <p>Biweekly  Payments </p>\n' +
    '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="biWeeklyPayments">0.00</span>{{#if footnote}}<small calculator-small-asterisk>*</small>{{/if}}</h2>\n' +
    '                            <div class="divider"></div>\n' +
        '                           <p class="small-size">(You pay <span data-result="biweeklyAmt" class="c-blue">$31,032</span> annually, compared to <span data-result="monthlyAmt"  class="c-blue">$31,032</span> when paying monthly. That’s an additional <span data-result="differenceIAmts"  class="c-blue">$31,032</span> towards your principal\n' +
        'every year.)</p>' +
    '                            <div class="">\n' +
    '                                <p>Interest Saved <span data-result="interestSaved">$0.00</span></p>\n' +
    '                                <p>Accelerated Payoff <span data-result="acceleratedPayoff">$0.00</span></p>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="tab-pane " id="advanced" role="tabpanel"  data-calculator data-methods="advancedMountly">\n' +
    '                    <div class="panel">\n' +
    '                        <form class="panel__body" autocomplete="off">\n' +
    '                            <div class="panel--wrapper">\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Loan Amount</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input autocomplete="false" title="loanAmount" type="text" name="loanAmount" data-currency data-input value="{{values.BiWeekly.basic.loanAmount}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Loan Term (Yrs)</p>\n' +
    '                                    <div class="panel__input ">\n' +
    '\n' +
    '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.BiWeekly.basic.loanTerm}}"\n' +
    '                                               data-msg="Please enter a Loan Term"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="30" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item">\n' +
    '                                    <p>Interest Rate (%)</p>\n' +
    '                                    <div class="panel__input icon--right ">\n' +
    '                                        <i>%</i>\n' +
    '                                        <input data-percent autocomplete="false" title="interestRate" type="text" name="interestRate" data-input value="{{values.BiWeekly.basic.interestRate}}"\n' +
    '                                               data-msg="Please enter a number between, 1 and 15"\n' +
    '                                               data-rule-required\n' +
    '                                               data-rule-max="15" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Property Tax (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input autocomplete="false" title="propertyTax" type="text" name="propertyTax" data-currency data-input value="{{values.BiWeekly.advanced.propertyTax}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a roperty Tax" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Homeowners Ins. (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="homeowners" autocomplete="false" type="text" name="homeowners" data-currency data-input value="{{values.BiWeekly.advanced.homeownersIns}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a roperty Tax" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                            <div class="flex-row">\n' +
    '\n' +
    '                                <a href="# " class="panel__button" data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    
        '                            </div>\n' +
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom block">\n' +
        '                                <p>Biweekly Payments with Tax and Insurance</p>\n' +
        '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="biWeeklyPayments">1,194</span><small calculator-small-asterisk>*</small></h2>\n' +   '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
    '                        </form>\n' +
    '\n' +
    '                    </div>\n' +
    '                    <div class="results">\n' +
    '                        <div class="results__chart order">\n' +
    '                            <div data-chart>\n' +
    '\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="results__graphic" data-graphic>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                {{#if amortTab}} <div class="tab-pane " id="amortization" role="tabpanel"  data-calculator data-methods="amortization">\n' +
        '                    <div class="panel  d-none d-sm-flex">\n' +
        '                        <form class="panel__body panel--left" autocomplete="off">\n' +
        '                               <div class="panel--wrapper flex-end">\n' +
        '                                <div class="panel__item panel--auto">\n' +
        '                                    <p>Loan Amount</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="loanAmount" type="text" name="loanAmount" data-currency data-input value="{{values.BiWeekly.basic.loanAmount}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item">\n' +
        '                                    <p>Loan Term (Yrs)</p>\n' +
        '                                    <div class="panel__input ">\n' +
        '                                        <input title="loanterm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.BiWeekly.basic.loanTerm}}"\n' +
        '                                               data-msg="Please enter a Loan Term"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="30" />\n' +
        '\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                               <div class="break"></div>\n'+
        '                                <div class="panel__item panel--auto panel--mt">\n' +
        '                                    <p>Interest Rate (%)</p>\n' +
        '                                    <div class="panel__input icon--right ">\n' +
        '                                        <i>%</i>\n' +
        '                                        <input title="interestRate" type="text" name="interestRate" data-percent data-input\n' +
        '                                               value="{{values.BiWeekly.basic.interestRate}}"\n' +
        '                                               data-msg="Please enter a number between, 1 and 15"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="15" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <a href="# " class="panel__button  panel--mt  w-auto" data-calculate calculator-button>calculate</a>\n' +
        '                               <div class="break"></div>\n'+
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    
        '                            </div>\n' +
        '                        </form>\n' +
        '                        <div class="panel--right">\n' +
        '                            <p>Biweekly  Payments </p>\n' +
        '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="biWeeklyPayments">0.00</span>{{#if footnote}}<small calculator-small-asterisk>*</small>{{/if}}</h2>\n' +
        '                            <div class="divider"></div>\n' +
        '                           <p class="small-size">(You pay <span data-result="biweeklyAmt" class="c-blue">$31,032</span> annually, compared to <span data-result="monthlyAmt"  class="c-blue">$31,032</span> when paying monthly. That’s an additional <span data-result="differenceIAmts"  class="c-blue">$31,032</span> towards your principal\n' +
        'every year.)</p>' +
        '                            <div class="">\n' +
        '                                <p>Interest Saved <span data-result="interestSaved">$0.00</span></p>\n' +
        '                                <p>Accelerated Payoff <span data-result="acceleratedPayoff">$0.00</span></p>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                    <div class="results results--table">\n' +
        '                        <div class="results__table" data-table>\n' +
    '\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>{{/if}}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>'
}