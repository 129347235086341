import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';

import Calculator from '../calculator-common'
import Handlebars from "handlebars";

export default class extends Calculator{
    constructor(el, param) {
        super(el, param)
        
    }
    basicSavings(data, panel ){
        return new Promise((resolve, reject) => {
        
            const CLcmbalance = data.currentMortgageBalance,
            CLcpip = data.currentPrincipal,
            CLproptax = data.propertyTaxes,
            CLHI =  data.homeowners,
            CLMI =  data.mortgage,
            loanamount =  data.loanAmount,
            NLterm = data.term,
            NLinterestrate = data.interestRate,
            NLMI = data.mortgage2,
            ESC = data.estimateSettlementCosts,
            CAPA = data.cashAvailable,
            totbal = data.totalBalance,
            totmo = data.totalMonthlyPayments,
            ACR = data.additionalCashRemaining;
    

        let NLcpip = this.logicMethods.pmt((NLinterestrate / 12) / 100, NLterm * 12, -loanamount, 0, 0),
            difference = ((CLcpip + (CLproptax / 12) + (CLHI / 12) + totmo + (CLMI / 12)) - (NLcpip + (CLproptax / 12) + (CLHI / 12) + (NLMI / 12))).toFixed(2),
            tCAPA = (Number(loanamount)) - (Number(ESC)) - (Number(CLcmbalance)),
            tACR = (Number(tCAPA)) - (Number(totbal));
            if(tACR < 0)
                tACR = 0
    
            let title = Handlebars.compile('{{#if tACR}}' +
                '<p>With this plan you will save</p>\n' +
                '<h2 style="color:'+ this.params.tabs.highlightedTextColor+';"><i></i> <span > '+Currency(difference,{ separator: ",",formatWithSymbol: true, precision: 0}).format()+' </span> </h2>\n' +
                '<p> per month and receive</p>\n' +
                '<h2 style="color:'+ this.params.tabs.highlightedTextColor+';"><i></i> <span > '+Currency(ACR,{  separator: ",",formatWithSymbol: true, precision: 0 }).format()+' </span> </h2>\n' +
                '<p> cash at closing</p>\n' +
                '{{else}}' +
                '<p>You must increase loan amount to pay off balances listed</p>\n' +
                '{{/if}}');
            let html = title({'tACR':tACR})
         
            panel.find(this.selectors.result).filter('[data-result="cashAvailable"]').val( Currency(tCAPA,{separator: ","}).format())
            panel.find(this.selectors.result).filter('[data-result="additionalCashRemaining"]').val( Currency(tACR,{separator: ","}).format())
    
            panel.find(this.selectors.result).filter('[data-result="basicTitle"]').html(html)
    
      
        
        resolve(true)
        })
    }
    
    advancedSavings(data, panel ){
            return new Promise((resolve, reject) => {
        const CLcmbalance = data.currentMortgageBalance,
            CLcpip = data.currentPrincipal,
            CLproptax = data.propertyTaxes,
            CLHI =  data.homeowners,
            CLMI =  data.mortgage,
            loanamount =  data.loanAmount,
            NLterm = data.term,
            NLinterestrate = data.interestRate,
            NLMI = data.mortgage2,
            ESC = data.estimateSettlementCosts,
            CAPA = data.cashAvailable,
            totbal = data.totalBalance,
            totmo = data.totalMonthlyPayments,
            ACR = data.additionalCashRemaining;
    
    
        let NLcpip = this.logicMethods.pmt((NLinterestrate / 12) / 100, NLterm * 12, -loanamount, 0, 0),
            difference = ((CLcpip + (CLproptax / 12) + (CLHI / 12) + totmo + (CLMI / 12)) - (NLcpip + (CLproptax / 12) + (CLHI / 12) + (NLMI / 12))).toFixed(2),
            tCAPA = (Number(loanamount)) - (Number(ESC)) - (Number(CLcmbalance)),
             tACR = (Number(tCAPA)) - (Number(totbal));
                if(tACR < 0)
                    tACR = 0
    
        let title = Handlebars.compile('{{#if tACR}}' +
            '<div class="inline bottom">\n' +
            '    <p>With this plan you will save</p>\n' +
            '    <h2 style="color:'+ this.params.tabs.highlightedTextColor+';"><i></i> <span >'+Currency(difference,{ separator: ",",formatWithSymbol: true, precision: 0}).format()+'</span></h2>\n' +
            '</div>\n' +
            '<div class="inline bottom">\n' +
            '    <p> per month and receive</p>\n' +
            '    <h2 style="color:'+ this.params.tabs.highlightedTextColor+';"><i></i> <span >'+Currency(ACR,{  separator: ",",formatWithSymbol: true, precision: 0 }).format()+'</span><small calculator-small-asterisk>*</small> </h2>\n' +
            '    <p> cash at closing</p>\n' +
            '</div>\n' +
  
            '{{else}}' +
            '<p>You must increase loan amount to pay off balances listed</p>\n' +
            '{{/if}}');
        let html = title({'tACR':tACR})
        panel.find(this.selectors.result).filter('[data-result="cashAvailable"]').val( Currency(tCAPA,{separator: "," ,precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="additionalCashRemaining"]').val( Currency(tACR,{separator: "," ,precision: 0}).format())

        panel.find(this.selectors.result).filter('[data-result="advancedTitle"]').html(html)
    
        if(panel.find(this.selectors.result).closest('[data-show-calc]'))
            panel.find(this.selectors.result).closest('[data-show-calc]').removeClass('d-none')

        panel.find(this.selectors.chart).highcharts({
            chart: {
                plotBackgroundColor: '#ffffff',
              
                type: 'column',
                backgroundColor: 'transparent',
               
                height: 250,
            },
            title: {
                useHTML: true,
                text: '',
                align: 'center',
                backgroundColor: '#EFEFEF',
                margin: 30
            },
            xAxis: {
                categories: ['Current Payments', 'New Payments']
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}  <span class="small">({point.percentage:.0f}%)</span></span>',
                shared: true,
                pointFormatter: function () {
                    return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> : '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true  ,precision: 0}).format()+' <span class="small">('+Math.round(this.percentage)+'%)</span></span>'
                }
            },
            legend: {
                enabled:false,
            },
            plotOptions: {
                pie: {
        
                    showInLegend: false,
                },
                column: {
                    stacking: 'normal',
                    borderWidth: 0
                }
            },
           
            series: [
                {
                    name: 'Principal & Interest',
                    data: [CLcpip, NLcpip],
                    color: this.params.tabs.highcharts.pie.Principal
                },
                {
                    name: 'Taxes',
                    data: [CLproptax / 12, CLproptax / 12],
                    color: this.params.tabs.highcharts.pie.Taxes
                },
                {
                    name: 'Homeowner\'s Insurance',
                    data: [CLHI / 12, CLHI / 12],
                    color:this.params.tabs.highcharts.pie.Insurance
                },
                {
                    name: 'Mortgage Insurance',
                    data: [CLMI / 12, NLMI / 12],
                    color: this.params.tabs.highcharts.pie.MortgageInsurance
                },
                {
                    name: 'Other Loans',
                    data: [totmo, 0],
                    color: this.params.tabs.highcharts.pie.Interest
                }
            ]
        });
    
    
    
                resolve(true)
            })
    }
    
    amortizationSavings(data, panel) {
                return new Promise((resolve, reject) => {

        const CLcmbalance = data.currentMortgageBalance,
            CLcpip = data.currentPrincipal,
            CLproptax = data.propertyTaxes,
            CLHI =  data.homeowners,
            CLMI =  data.mortgage,
            loanamount =  data.loanAmount,
            NLterm = data.term,
            NLinterestrate = data.interestRate,
            NLMI = data.mortgage2,
            ESC = data.estimateSettlementCosts,
            CAPA = data.cashAvailable,
            totbal = data.totalBalance,
            totmo = data.totalMonthlyPayments,
            ACR = data.additionalCashRemaining;
    
    
        let NLcpip = this.logicMethods.pmt((NLinterestrate / 12) / 100, NLterm * 12, -loanamount, 0, 0),
            difference = ((CLcpip + (CLproptax / 12) + (CLHI / 12) + totmo + (CLMI / 12)) - (NLcpip + (CLproptax / 12) + (CLHI / 12) + (NLMI / 12))).toFixed(2),
            tCAPA = (Number(loanamount)) - (Number(ESC)) - (Number(CLcmbalance)),
            tACR = (Number(tCAPA)) - (Number(totbal));
        
            if(tACR < 0)
                tACR = 0
    
            let title = Handlebars.compile('{{#if tACR}}' +
                '<p>With this plan you will save</p>\n' +
                '<h2 style="color:'+ this.params.tabs.highlightedTextColor+';"><i></i> <span > '+Currency(difference,{ separator: ",",formatWithSymbol: true, precision: 0}).format()+' </span> </h2>\n' +
                '<p> per month and receive</p>\n' +
                '<h2 style="color:'+ this.params.tabs.highlightedTextColor+';"><i></i> <span > '+Currency(ACR,{  separator: ",",formatWithSymbol: true, precision: 0 }).format()+' </span> </h2>\n' +
                '<p> cash at closing</p>\n' +
                '{{else}}' +
                '<p>You must increase loan amount to pay off balances listed</p>\n' +
                '{{/if}}');
            let html = title({'tACR':tACR})
            panel.find(this.selectors.result).filter('[data-result="cashAvailable"]').val( Currency(tCAPA,{separator: "," ,precision: 0}).format())
            panel.find(this.selectors.result).filter('[data-result="additionalCashRemaining"]').val( Currency(tACR,{separator: "," ,precision: 0}).format())
    
            panel.find(this.selectors.result).filter('[data-result="basicTitle"]').html(html)
    
        
        const amortdata = this.logicMethods.AmortizationCompute(loanamount,NLinterestrate,NLterm* 12);
        let filterData={}
        filterData.Items = amortdata.amortization.reduce((function(hash){
            return function(array,obj){
                if(!hash[obj.year])
                    hash[obj.year]=[obj]
                else
                    hash[obj.year].push(obj);
                return hash;
            };
            
        })({}),[])
    
                    filterData.principalPaid = true
                    filterData.totalInterest = true
        this.renderTable(filterData,    panel.find(this.selectors.table)).then(() => {
           // panel.find('.collapse').collapse({'toogle':false})
           
        })
                    resolve(true)
                })
    }
}