import $ from 'jquery';
import 'bootstrap/js/dist/tab';

export default class {
    constructor(el, param) {
        this.tab = el
        this.params = param
      
        this.selectors = {
            link: '[mobile-link]',
            linkItem: '[mobile-link-item]',
            btn: '[data-tab-link]',
            button: '[calculator-button]',
            highlighttext: '[calculator-highlightedtextcolor]',
            asterik:'[calculator-small-asterisk]'
        }
        this.tab.find(this.selectors.btn).filter('.active').css('color',this.params.tabs.headerColor)
        this.tab.find(this.selectors.linkItem).filter('.active').css('color', this.params.tabs.headerColor)
        this.tab.find(this.selectors.button).css('background-color', this.params.tabs.buttonColor)
        this.tab.find(this.selectors.highlighttext).css('color', this.params.tabs.highlightedTextColor)
        this.tab.find(this.selectors.asterik).css('color', this.params.tabs.highlightedTextColor)
        //this.tab.find(this.selectors.linkItem).removeClass('active')
        this.bind()
    }
    bind() {

        this.tab.on({
            'click': (e) => {
                e.preventDefault()
                const el = $(e.currentTarget)
                this.tab.find(this.selectors.linkItem).removeClass('active').css("color", "");
                el.addClass('active').css('color',this.params.tabs.headerColor)
                const id =el.attr('href')
                this.tab.find('.nav-link').filter('[href="' + id + '"]').trigger('click')
                this.tab.find(this.selectors.link).text(el.text())

            }
        },this.selectors.linkItem)
        this.tab.on({
            'click': function (e) {
                e.preventDefault()
                $(this).tab('show')
            }
        },this.selectors.btn)

        $(this.tab).on('shown.bs.tab', this.selectors.btn, (e) => {
           $(e.target).css('color',this.params.tabs.headerColor)
           $(e.relatedTarget).css("color", "");
           
        })
      
    }
}
