

export default function (){
    return ' <div class="table--wrapper " id="amortizationTable">\n' +
        '{{#each Items}}\n' +
        ' <div {{#ifCond @index ">" 4}}class=" d-none" table-item-hide{{/ifCond}}  >\n'+
        '        <div class="table__item" table-item>\n' +
        '            <div class="table__title" id="heading{{@index}}">\n' +
        '                <a href="#amortization{{@key}}" calculator-highlightedtextcolor class="table__button {{#if @first}}collapse{{else}}collapsed{{/if}}" data-toggle="collapse" data-parent="#amortizationTable" data-target="#amortization{{@key}}" aria-expanded="true" aria-controls="collapseOne">\n' +
        '                    {{@key}}\n' +
        '                </a>\n' +
        '            </div>\n' +
        '            <div id="amortization{{@key}}" class=" {{#ifCond @index "<=" 4}}show {{else}}collapse {{/ifCond}}" aria-labelledby="heading{{@index}}" data-parent="#amortizationTable">\n' +
        '                <div class="table__body">\n' +
        '                    <table>\n' +
        '                        <thead>\n' +
        '                            <tr>\n' +
        '                                <th style="color:{{tabs.highlightedTextColor}}">Month</th>\n' +
        '                                <th style="color:{{tabs.highlightedTextColor}}">Month No.</th>\n' +
        '                                <th style="color:{{tabs.highlightedTextColor}}; display:none;">Year No.</th>\n' +
        '                                <th>Payment</th>\n' +
        '                                {{#if  ../principalPaid}}\n' +
        '                                <th>Principal</th>\n' +
        '                                {{/if}}\n' +
        '                                <th>Interest</th>\n' +
        '                                {{#if  ../totalInterest}}\n' +
        '                                <th>Total Interest</th>\n' +
        '                                {{/if}}\n' +
        '                                {{#if  ../buydown}}\n' +
        '                                <th>Buydown</th>\n' +
        '                                {{/if}}\n' +
        '                                <th>Balance</th>\n' +
        '                                {{#if  ../acceleratedPayment}}\n' +
        '                                <th>Accelerated Payment</th>\n' +
        '                                {{/if}}\n' +
        '                                {{#if  ../interestAccelerated}}\n' +
        '                                <th>Accelerated Interest</th>\n' +
        '                                {{/if}}\n' +
        '                                {{#if  ../acceleratedBalance}}\n' +
        '                                <th>Accelerated Balance</th>\n' +
        '                                {{/if}}\n' +
        '                            </tr>\n' +
        '                        </thead>\n' +
        '                        <tbody>\n' +
        '                            {{#each this}}\n' +
        '                            <tr>\n' +
        '                                <td style="color:{{tabs.highlightedTextColor}}">{{{this.date}}}</td>\n' +
        '                                <td style="color:{{tabs.highlightedTextColor}}">{{{this.newMonthNumber}}}</td>\n' +
        '                                <td style="color:{{tabs.highlightedTextColor}}; display:none;">{{{this.newYearNumber}}}</td>\n' +
        '                                <td>{{currency this.payment}}</td>\n' +
        '                                {{#if  ../../principalPaid}}\n' +
        '                                <td>{{currency this.principalPaid}}</td>\n' +
        '                                {{/if}}\n' +
        '                                <td>{{currency this.interest}}</td>\n' +
        '                                {{#if  ../../totalInterest}}\n' +
        '                                <td>{{currency this.totalInterest}}  </td>\n' +
        '                                {{/if}}\n' +
        '                                {{#if  ../../buydown}}\n' +
        '                                <td>{{currency this.buydownamount}}</td>\n' +
        '                                {{/if}}\n' +
        '                                <td>{{currency this.balance}}</td>\n' +
    
        '                                {{#if  ../../acceleratedPayment}}\n' +
        '                                <td>{{currency this.acceleratedPayment}}   </td>\n' +
        '                                {{/if}}\n' +
        '                                {{#if  ../../interestAccelerated}}\n' +
        '                                <td>{{currency this.interestAccelerated}}   </td>\n' +
        '                                {{/if}}\n' +
        '                                {{#if  ../../acceleratedBalance}}\n' +
        '                                <td>{{currency this.acceleratedBalance}}   </td>\n' +
        '                                {{/if}}\n' +
        '                            </tr>\n' +
        '                            {{/each }}\n' +
        '                        </tbody>\n' +
        '                    </table>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
    
        '    </div>' +
        '   {{/each }}\n' +
        '    </div>'+
        ' <a data-show-table class="table__load-more">see more years <span><i class="fas fa-chevron-down "></i></span></a> ' +
        ' <div class="break" style="height:45px;"></div>\n' +
        ' <button id="csvBtn" class="button-cmg button-small button-tertiary" onclick="downloadCSV(amortizationTable)">Download CSV</button>\n'
}