export default function (){
    return '  <div class="calculator__tab" data-tab data-calc-mode="ShortLong">\n' +
        '        <div class="calculator__header">\n' +
        '           {{#if h1}}  <h1>15 vs 30 Year Mortgage Calculator </h1>{{else}}<h3>15 vs 30 Year Mortgage Calculator </h3>{{/if}}\n' +       
        '            <p>Estimate the difference between 15 and 30-year terms and monthly payments.</p>\n' +
        '            <div class="calculator__navigate">\n' +
        '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link active" data-tab-link href="#basic" role="tab" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#advanced" role="tab" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
        '                    </li>\n' +
        '                </ul>\n' +
        '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
        '                <div class="dropdown-menu">\n' +
        '                    <a class="dropdown-item" href="#basic active" mobile-link-item>BASIC</a>\n' +
        '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="calculator__content">\n' +
        '            <div class="tab-content">\n' +
        '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicShortLong">\n' +
        '                    <div class="panel wrap">\n' +
        '                        <form class="panel__body" autocomplete="off">\n' +
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Home info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text" name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.FifteenThirty.homeInfo.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertTax" autocomplete="false" type="text" HPP name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.FifteenThirty.homeInfo.propertyTax}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Property Tax (Yearly)" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Homeowners Ins. (Yrly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" HPP name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.FifteenThirty.homeInfo.homeownersIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Homeowners Ins. (Yrly)" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto ">\n' +
        '                                        <p>Expected Years in Home</p>\n' +
        '                                        <div class="panel__input  ">\n' +
        '                                            <input title="expyrs" autocomplete="false" type="text" data-input name="expyrs" value="{{values.FifteenThirty.homeInfo.expectedYrs}}"\n' +
        '                                                   data-msg="Please enter an Expected Years in Hom"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="double-panel">\n' +
        '                            <div class="double-panel__item">\n' +
        '                                <p class="panel__sub-title">short term loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTermShort" autocomplete="false" type="text" data-input name="loanTermShort" value="{{values.FifteenThirty.shortTermInfo.loanTerm}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRateShort" data-percent autocomplete="false" type="text" name="interestRateShort" data-input value="{{values.FifteenThirty.shortTermInfo.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider "></div>\n' +
        '                            <div class="double-panel__auto" >\n' +
        '                                <p class="panel__sub-title">long term loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.FifteenThirty.longTermInfo.loanTerm}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.FifteenThirty.longTermInfo.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button w-auto" data-calculate calculator-button>calculate</a>\n' +
    
        '                                </div>\n' +
      '                                </div>\n' +
        '                            </div>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom">\n' +
        '                                <div class="mr--30">\n' +
        '                                   <p>Short Term Monthly Payment</p>\n' +
        '                                     <h2 calculator-highlightedTextColor><i>$</i> <span data-result="totPayST">0.00</span><small calculator-small-asterisk>*</small></h2>\n' +
        '                                 </div>\n' +
        '                                <div>\n' +
        '                                  <p>Long Term Monthly Payment</p>\n' +
        '                                   <h2 calculator-highlightedTextColor><i>$</i> <span data-result="totPayLT">0.00</span><small calculator-small-asterisk>*</small></h2>\n' +
        '                                 </div>\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="tab-pane" id="advanced" role="tabpanel"  data-calculator data-methods="advancedShortLong">\n' +
        '                    <div class="panel wrap">\n' +
        '                        <form class="panel__body" autocomplete="off">\n' +
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Home info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text" name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.FifteenThirty.homeInfo.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" HPP name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.FifteenThirty.homeInfo.propertyTax}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Property Tax (Yearly)" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Homeowners Ins. (Yrly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" HPP name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.FifteenThirty.homeInfo.homeownersIns}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Homeowners Ins. (Yrly)" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto ">\n' +
        '                                        <p>Expected Years in Home</p>\n' +
        '                                        <div class="panel__input  ">\n' +
        '                                            <input title="expyrs" autocomplete="false" type="text" data-input name="expyrs" value="{{values.FifteenThirty.homeInfo.expectedYrs}}"\n' +
        '                                                   data-msg="Please enter an Expected Years in Hom"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="double-panel">\n' +
        '                            <div class="double-panel__item">\n' +
        '                                <p class="panel__sub-title">short term loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTermShort" autocomplete="false" type="text" data-input name="loanTermShort" value="{{values.FifteenThirty.shortTermInfo.loanTerm}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRateShort" data-percent autocomplete="false" type="text" name="interestRateShort" data-input value="{{values.FifteenThirty.shortTermInfo.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider "></div>\n' +
        '                            <div class="double-panel__auto" >\n' +
        '                                <p class="panel__sub-title">long term loan info</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.FifteenThirty.longTermInfo.loanTerm}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.FifteenThirty.longTermInfo.loanTerm}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button w-auto" data-calculate calculator-button>calculate</a>\n' +
    
        '                                </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom">\n' +
        '                                <div class="mr--30">\n' +
        '                                   <p>Short Term Monthly Payment</p>\n' +
        '                                     <h2 calculator-highlightedTextColor><i>$</i> <span data-result="totPayST">0.00</span><small calculator-small-asterisk>*</small></h2>\n' +
        '                                 </div>\n' +
        '                                <div>\n' +
        '                                  <p>Long Term Monthly Payment</p>\n' +
        '                                   <h2 calculator-highlightedTextColor><i>$</i> <span data-result="totPayLT">0.00</span><small calculator-small-asterisk>*</small></h2>\n' +
        '                                 </div>\n' +
        '                            </div>\n' +
    
        '                    <div class="divider divider--double"></div>\n' +
        '                        </form>\n' +
    
        '                            </div>\n' +
        '                    <div class="results results--indent result--first no-indent">\n' +
    
        '                        <div class=" results__graphic">\n' +
        '\n' +
        '                            <div data-chart>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                        <div class="results__chart" data-graphic>\n' +
        '\n' +
        '                        </div>\n' +
        '\n' +
        '                    </div>\n' +
        '                    <div data-show-calc class=" d-none">\n' +
        '                        <div class="divider divider--double"></div>\n' +
        '                        <div class="results results--indent  ">\n' +
        '\n' +
        '                            <div class="results__title  order">\n' +
        '                                <p>\n' +
        '                                    Comparison of additional payments\n' +
        '                                    made and the change in principal\n' +
        '                                    payoff balance after <span data-result="expyrs" class="c-blue" calculator-highlightedTextColor>0</span> years\n' +
        '                                </p>\n' +
        '                            </div>\n' +
        '                            <div class=" results__graphic no-indent">\n' +
        '\n' +
        '                                <div data-chart-2>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '\n' +
        '\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '\n' +
        '    </div>'
}