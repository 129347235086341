import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';

import Calculator from '../calculator-common'

export default class extends Calculator{
    constructor(el, param) {
        super(el, param)
        
    }
    basicShortLong(data, panel ){
        return new Promise((resolve, reject) => {
        const loanamount = data.loanAmount,
            proptax = data.propertyTax,
            homeownersins = data.homeowners,
            expyrs =  data.expyrs,
            stt =  data.loanTermShort,
            stinterestrate =  data.interestRateShort,
            ltt = data.loanTerm,
            ltinterestrate = data.interestRate;
        
        
        const stPI =  this.logicMethods.pmt((Number(stinterestrate) / 12) / 100, (Number(stt) * 12), (0 - Number(loanamount)), 0, 0).toFixed(2),
            ltPI =   this.logicMethods.pmt((Number(ltinterestrate) / 12) / 100, (Number(ltt) * 12), (0 - Number(loanamount)), 0, 0).toFixed(2),
            payIncPerPd = stPI - ltPI,
            payChangeOverYrs = Number((payIncPerPd * Number(expyrs) * 12).toFixed(2)),
            remPrinST =  this.logicMethods.FV((Number(stinterestrate) / 12) / 100, (Number(expyrs) * 12), stPI, (0 - Number(loanamount)), 0, 0),
            remPrinLT =  this.logicMethods.FV((Number(ltinterestrate) / 12) / 100, (Number(expyrs) * 12), ltPI, (0 - Number(loanamount)), 0, 0),
            addPaidEquity = Number(remPrinLT - remPrinST),
            taxes = Number(proptax) / 12,
            insurance = Number(homeownersins) / 12,
            intST = ((Number(stinterestrate) / 100) * Number(loanamount)) / 12,
            prinST = Number((stPI - intST).toFixed(2)),
            totPayST = (Number(prinST) + Number(intST) + Number(taxes) + Number(insurance)).toFixed(2),
            intLT = ((Number(ltinterestrate) / 100) * Number(loanamount)) / 12,
            prinLT = Number((ltPI - intLT).toFixed(2)),
            totPayLT = (Number(prinLT) + Number(intLT) + Number(taxes) + Number(insurance)).toFixed(2);
        
        panel.find(this.selectors.result).filter('[data-result="totPayST"]').text(Currency(totPayST,{ separator: ",",formatWithSymbol: false ,precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="totPayLT"]').text(Currency(totPayLT,{  separator: ",",formatWithSymbol: false ,precision: 0 }).format())
        resolve(true)
    })
    }
    
    advancedShortLong(data, panel ){
    return new Promise((resolve, reject) => {
        const loanamount = data.loanAmount,
            proptax = data.propertyTax,
            homeownersins = data.homeowners,
            expyrs =  data.expyrs,
            stt =  data.loanTermShort,
            stinterestrate =  data.interestRateShort,
            ltt = data.loanTerm,
            ltinterestrate = data.interestRate;
    
    
        const stPI =  this.logicMethods.pmt((Number(stinterestrate) / 12) / 100, (Number(stt) * 12), (0 - Number(loanamount)), 0, 0).toFixed(2),
            ltPI =   this.logicMethods.pmt((Number(ltinterestrate) / 12) / 100, (Number(ltt) * 12), (0 - Number(loanamount)), 0, 0).toFixed(2),
            payIncPerPd = stPI - ltPI,
            payChangeOverYrs = Number((payIncPerPd * Number(expyrs) * 12).toFixed(2)),
            remPrinST =  this.logicMethods.FV((Number(stinterestrate) / 12) / 100, (Number(expyrs) * 12), stPI, (0 - Number(loanamount)), 0, 0),
            remPrinLT =  this.logicMethods.FV((Number(ltinterestrate) / 12) / 100, (Number(expyrs) * 12), ltPI, (0 - Number(loanamount)), 0, 0),
            addPaidEquity = Number(remPrinLT - remPrinST),
            taxes = Number(proptax) / 12,
            insurance = Number(homeownersins) / 12,
            intST = ((Number(stinterestrate) / 100) * Number(loanamount)) / 12,
            prinST = Number((stPI - intST).toFixed(2)),
            totPayST = (Number(prinST) + Number(intST) + Number(taxes) + Number(insurance)).toFixed(2),
            intLT = ((Number(ltinterestrate) / 100) * Number(loanamount)) / 12,
            prinLT = Number((ltPI - intLT).toFixed(2)),
            totPayLT = (Number(prinLT) + Number(intLT) + Number(taxes) + Number(insurance)).toFixed(2);
    
    
    
    
    
        panel.find(this.selectors.result).filter('[data-result="totPayST"]').text(Currency(totPayST,{ separator: ",",formatWithSymbol: false ,precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="totPayLT"]').text(Currency(totPayLT,{  separator: ",",formatWithSymbol: false  ,precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="expyrs"]').text(expyrs)
        if(panel.find(this.selectors.result).closest('[data-show-calc]'))
            panel.find(this.selectors.result).closest('[data-show-calc]').removeClass('d-none')

        panel.find(this.selectors.chart).highcharts({
            chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderWidth: null,
                plotShadow: false,
                type: 'column',
                backgroundColor: 'transparent',
                marginTop: 100,
                marginBotom: 20
            },
            title: {
                useHTML: true,
                text: '<div class="titlediv text-center"><div class="secondtitle  text-center">Your shorter term monthly payment is <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight+'">' +  Currency(totPayST,{ symbol: "$", separator: ",",formatWithSymbol: true  ,precision: 0}).format() + '</span> compared to a longer term monthly payment of  <span class="firsttitle" style="color:'+this.params.tabs.highcharts.column.Principal+'">' + Currency(totPayLT,{ symbol: "$", separator: ",",formatWithSymbol: true  ,precision: 0}).format()   + '</span>. Your monthly payment breakdown after <span class="firsttitle" style="color:'+this.params.tabs.highcharts.column.Principal+'">' + Number(expyrs) + '</span> years will be </div></div>',
                align: 'center',
                backgroundColor: '#EFEFEF',
                marginTop: 60
        
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            xAxis: {
                categories: ['Shorter Term - ' +  Currency(totPayST,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0 }).format(), 'Longer Term - ' + Currency(totPayLT,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0 }).format()]
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}  <span class="small">({point.percentage:.0f}%)</span></span>',
                shared: true,
                pointFormatter: function () {
                    return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> : '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0 }).format()+' <span class="small">('+Math.round(this.percentage)+'%)</span></span>'
                }
            },
            legend: {
                enabled:false,
                y: 100,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6,
                useHTML: true,
                floating: true,
                labelFormatter: function () {
            
                    return '<div"><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderWidth: 0
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        minwidth: 100,
                        maxWidth: 400
                    },
                    chartOptions: {
                        chart: {
                            height: 350,
                            marginTop: 170,
                            marginBotom: 20
                        },
                        legend: {
                            y: 70,
                            align: 'center',
                            layout: 'horizontal',
                            verticalAlign: 'top',
                            useHTML: true,
                            floating: true,
                            labelFormatter: function () {
                                return '<div><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                            }
                        },
                       
                    }
                }]
            },
            series: [{
                name: 'Principal',
                data: [prinST, prinLT],
                color: this.params.tabs.highcharts.pie.Principal
            },
                {
                    name: 'Interest',
                    data: [intST, intLT],
                    color: this.params.tabs.highcharts.pie.Interest
                },
                {
                    name: 'Taxes',
                    data: [taxes, taxes],
                    color: this.params.tabs.highcharts.pie.Taxes
                },
                {
                    name: 'Insurance',
                    data: [insurance, insurance],
                    color:this.params.tabs.highcharts.pie.Insurance
                }]
        });
    
    
        panel.find(this.selectors.graphic).highcharts({
            chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderWidth: null,
                plotShadow: false,
                type: 'column',
                backgroundColor: 'transparent',
                marginTop: 100,
                marginBotom: 20
            },
            title: {
                useHTML: true,
                text: '<div class="titlediv"><div class="secondtitle text-center">After <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight+'">' + Number(expyrs) + '</span>  years your principal balance will be <span class="firsttitle" style="color:'+this.params.tabs.highcharts.column.Principal+'">' +Currency(addPaidEquity,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0 }).format()  + '</span> lower</div></div>',
                align: 'center',
                backgroundColor: '#EFEFEF',
                margin: 50
            },
            xAxis: {
                categories: ['Short Term Balance', 'Long Term Balance'],
        
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                shared: true,
                pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {point.name}</b> - {point.val} </span>',
           
            },
            legend: {
                enabled:false,
                y: 80,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6,
                useHTML: true,
                floating: true,
                labelFormatter: function () {
                    return '<div><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                }
            },
            plotOptions: {
        
            },
            responsive: {
                rules: [{
                    condition: {
                        minwidth: 100,
                        maxWidth: 400
                    },
                    chartOptions: {
                        chart: {
                            height: 300,
                            marginTop: 80,
                            marginBotom: 20
                        },
                        legend: {
                            y: 70,
                            align: 'center',
                            layout: 'horizontal',
                            verticalAlign: 'top',
                            useHTML: true,
                            floating: true,
                            labelFormatter: function () {
                                return '<div"><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                            }
                        },
                     
                    }
                }]
            },
            series: [{
                data: [{
                    name: "Short Term\n" +
                    "Balance",
                    color: this.params.tabs.highcharts.column.Principal,
                    y: Number(remPrinST),
                    val:Currency(remPrinST,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0 }).format()
                },{
                    name: "Long Term\n" +
                    "Balance",
                    color: this.params.tabs.highcharts.column.Interest,
                    y: Number(remPrinLT),
                    val:Currency(remPrinLT,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0 }).format()
                }],
                
              
            }]
        });
        panel.find(this.selectors.chart2).highcharts({
            chart: {
                type: 'bar',
                backgroundColor: 'transparent',
                plotBackgroundColor: '#ffffff',
                height: 270,
            },
            title: {
                enabled:false,
                useHTML: true,
                text: '',
                align: 'center',
                backgroundColor: '#EFEFEF',
                margin: 0
            },
    
            xAxis: {
                categories: ['Additional Payments Made', 'Change in Payoff Balance']
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '',
                useHTML: true,
                borderColor: 'transparent',
                borderRadius: 5,
                backgroundColor: '#ffffff',
                padding: 10,
                shadow:false,
                shared: false,
                 pointFormatter:function () {
               
                    return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b>'+this.category+'</b> - '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true ,precision: 0 }).format()+' </span>';
                }
            },
            legend: {
                enabled:false,
             
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        minwidth: 100,
                        maxWidth: 400
                    },
                    chartOptions: {
                        chart: {
                            height: 250,
                            marginTop: 0,
                            marginBottom: 0
                        },
                        legend: {
                            enabled:false,
                            y: 85,
                            align: 'center',
                            layout: 'horizontal',
                            verticalAlign: 'top',
                            useHTML: true,
                            floating: true,
                            labelFormatter: function () {
                                return '<div"><span class="legenddtitle">' + this.name + '</span></div><hr/>';
                            }
                        },
                        title: {
                             },
                    }
                }]
            },
            series: [{
                accessibility: {
                    description:'asdasdsa'
                },
                name: 'Additional Payments Made ' ,
                data: [payChangeOverYrs, 0],
                pointWidth: 70,
                color: this.params.tabs.highcharts.column.Principal,
             
            },
                {
                    name: 'Change in Payoff Balance ' ,
                    data: [0, addPaidEquity],
                    pointWidth: 70,
                    color: this.params.tabs.highcharts.column.Interest,
                    }
            ]
        });
    resolve(true)
})
    }
}