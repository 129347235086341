export default function () {
    return '    <div class="gloabl-navigate--mobile">\n' +
        '            <a class="gloabl-navigate__link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">SEE ALL CALCULATORS</a>\n' +
        '            <div class="dropdown-menu">\n' +
        '                <a class="gloabl-navigate__item  {{#ifEquals calcMode "biweeklyMortgage"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.biweeklyMortgage}}" {{/if}} data-id="biweeklyMortgage">BI-WEEKLY</a>\n' +
        '                <a class="gloabl-navigate__item {{#ifEquals calcMode "Mortgage"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.Mortgage}}" {{/if}} data-id="mortgage">MORTGAGE PAYMENT</a>\n' +
        '                <a class="gloabl-navigate__item {{#ifEquals calcMode "Purchasing"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.Purchasing}}" {{/if}} data-id="purchasing">PURCHASING POWER</a>\n' +
        '                <a class="gloabl-navigate__item {{#ifEquals calcMode "Qualifying"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.Qualifying}}" {{/if}}  data-id="qualifying">QUALIFYING LOAN</a>\n' +
        '                <a class="gloabl-navigate__item {{#ifEquals calcMode "Rent"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.Rent}}" {{/if}}  data-id="rentbuy">RENT VS BUY</a>\n' +
        '                <a class="gloabl-navigate__item {{#ifEquals calcMode "Savings"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.Savings}}" {{/if}} data-id="savings">REFINANCE</a>\n' +
        '                <a class="gloabl-navigate__item {{#ifEquals calcMode "ShortLong"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.ShortLong}}" {{/if}} data-id="shortlong">15 VS 30</a>\n' +
        '                <a class="gloabl-navigate__item {{#ifEquals calcMode "Buydown"}}active{{/ifEquals}}" render-button-mobile {{#if linkNavigate}} data-href="{{links.Buydown}}" {{/if}} data-id="buydown">3-2-1 BUYDOWN</a>\n' +
        '            </div>\n' +
        '        </div>'

}