export default function (){
    return ' <div class="calculator__tab" data-tab data-calc-mode="Qualifying">\n' +
        '        <div class="calculator__header">\n' +
'           {{#if h1}}  <h1>Qualifying Loan Amount Calculator</h1>{{else}}<h3>Qualifying Loan Amount Calculator</h3>{{/if}}\n' +        
    '            <p>Calculate how much you can borrow.</p>\n' +
        '            <div class="calculator__navigate">\n' +
        '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link active" data-tab-link href="#basic" role="tab" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#advanced" role="tab" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
        '                    </li>\n' +
        '                </ul>\n' +
        '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
        '                <div class="dropdown-menu">\n' +
        '                    <a class="dropdown-item active" href="#basic" mobile-link-item>BASIC</a>\n' +
        '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="calculator__content">\n' +
        '            <div class="tab-content">\n' +
        '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicMountlyQualifying">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
        
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Monthly income</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Wages before taxes & deductions</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="wages" autocomplete="false" type="text" name="wages" data-currency data-input value="{{values.Qualifying.monthlyIncome.wages}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Investment $ before taxes</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="investment" autocomplete="false" type="text" name="investment" data-currency data-input value="{{values.Qualifying.monthlyIncome.investments}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Rental Property Income</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="rental" autocomplete="false" type="text" name="rental" data-currency data-input value="{{values.Qualifying.monthlyIncome.rentalPropertyInc}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Other Income</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="otherIncome" autocomplete="false" type="text" name="otherIncome" data-currency data-input value="{{values.Qualifying.monthlyIncome.otherIncome}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="">\n' +
        '                                <p class="panel__sub-title">Monthly PAYMENTS</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Auto Loans</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="autoLoans" autocomplete="false" type="text" name="autoLoans" data-currency data-input value="{{values.Qualifying.monthlyPayments.autoLoans}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Student Loans</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="studentLoans" autocomplete="false" type="text" name="studentLoans" data-currency data-input value="{{values.Qualifying.monthlyPayments.studentLoans}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Credit Cards</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input  title="creditCards" autocomplete="false" type="text" name="creditCards" data-currency data-input value="{{values.Qualifying.monthlyPayments.creditCards}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Alimony & Child Support</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                             <input title="alimonySupport" autocomplete="false" type="text" name="alimonySupport" data-currency data-input value="{{values.Qualifying.monthlyPayments.alimonyChild}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--35 panel--mt">\n' +
        '                                        <p>Rental Property Loans</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="rentalLoans" autocomplete="false" type="text" name="rentalLoans" data-currency data-input value="{{values.Qualifying.monthlyPayments.rentalPropertyLoans}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--35 panel--mt">\n' +
        '                                        <p>Other Payments</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="otherPayments" autocomplete="false" type="text" name="otherPayments" data-currency data-input value="{{values.Qualifying.monthlyPayments.otherPayments}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
         '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">loan information</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item 5">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Qualifying.loanInfo.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.Qualifying.loanInfo.loanTerms}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax" data-currency data-input value="{{values.Qualifying.loanInfo.propertyTax}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners" data-currency data-input value="{{values.Qualifying.loanInfo.homeownersInsurance}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="flex-row">\n' +
        '                                    <a href="# " class="panel__button " data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom">\n' +
        '                                <p>Your loan can range between</p>\n' +
        '                                <h2  class="head-small" calculator-highlightedTextColor><i></i> <span data-result="consLA">$0.00</span><small>&nbsp;and&nbsp;</small><span data-result="aggLA">$0.00</span></h2>\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="advanced" role="tabpanel"  data-calculator data-methods="advancedMountlyQualifying">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
    
        '                            <div class="" >\n' +
        '                                <p class="panel__sub-title">Monthly income</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Wages before taxes & deductions</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="wages" autocomplete="false" type="text" name="wages" data-currency data-input value="{{values.Qualifying.monthlyIncome.wages}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Investment $ before taxes</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="investment" autocomplete="false" type="text" name="investment" data-currency data-input value="{{values.Qualifying.monthlyIncome.investments}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Rental Property Income</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="rental" autocomplete="false" type="text" name="rental" data-currency data-input value="{{values.Qualifying.monthlyIncome.rentalPropertyInc}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Other Income</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="otherIncome" autocomplete="false" type="text" name="otherIncome" data-currency data-input value="{{values.Qualifying.monthlyIncome.otherIncome}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="">\n' +
        '                                <p class="panel__sub-title">Monthly PAYMENTS</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Auto Loans</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="autoLoans" autocomplete="false" type="text" name="autoLoans" data-currency data-input value="{{values.Qualifying.monthlyPayments.autoLoans}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Student Loans</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="studentLoans" autocomplete="false" type="text" name="studentLoans" data-currency data-input value="{{values.Qualifying.monthlyPayments.studentLoans}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Credit Cards</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="creditCards" autocomplete="false" type="text" name="creditCards" data-currency data-input value="{{values.Qualifying.monthlyPayments.creditCards}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--25">\n' +
        '                                        <p>Alimony & Child Support</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="alimonySupport" autocomplete="false" type="text" name="alimonySupport" data-currency data-input value="{{values.Qualifying.monthlyPayments.alimonyChild}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--35 panel--mt">\n' +
        '                                        <p>Rental Property Loans</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="rentalLoans" autocomplete="false" type="text" name="rentalLoans" data-currency data-input value="{{values.Qualifying.monthlyPayments.rentalPropertyLoans}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--35 panel--mt">\n' +
        '                                        <p>Other Payments</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="otherPayments" autocomplete="false" type="text" name="otherPayments" data-currency data-input value="{{values.Qualifying.monthlyPayments.otherPayments}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div >\n' +
        '                                <p class="panel__sub-title">loan information</p>\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item 5">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Qualifying.loanInfo.interestRate}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 15"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Loan Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '                                            <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.Qualifying.loanInfo.loanTerms}}"\n' +
        '                                                   data-msg="Please enter a Loan Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax" data-currency data-input value="{{values.Qualifying.loanInfo.propertyTax}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners" data-currency data-input value="{{values.Qualifying.loanInfo.homeownersInsurance}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="flex-row">\n' +
        '                                    <a href="# " class="panel__button " data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom">\n' +
        '                                <p>Your loan can range between</p>\n' +
        '                                <h2 class="head-small" calculator-highlightedTextColor><i></i> <span data-result="consLA">$0.00</span> <small>&nbsp;and&nbsp;</small> <span data-result="aggLA">$0.00</span></h2>\n' +
        '                            </div>\n' +
        '                            <div class="divider divider--double"></div>\n' +
        '                        </form>\n' +
        '\n' +
        '                    </div>\n' +
        '                    <div class="results no-indent">\n' +
        '                        <div class=" results__graphic">\n' +
        '\n' +
        '                            <div data-chart>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                        <div class="results__chart" data-graphic>\n' +
        '\n' +
        '                        </div>\n' +
        '\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '\n' +
        '    </div>'
}