export default function (){
    return ' <div class="calculator__tab" data-tab data-calc-mode="Purchasing">\n' +
        '        <div class="calculator__header">\n' +
    '           {{#if h1}}  <h1>Home Affordability Calculator</h1>{{else}}<h3>Home Affordability Calculator</h3>{{/if}}\n' +
        '            <p>Calculate your maximum purchase price.</p>\n' +
        '            <div class="calculator__navigate">\n' +
        '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link active" data-tab-link href="#basic" role="tab" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item ">\n' +
        '                        <a class="nav-link " data-tab-link href="#advanced" role="tab" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
        '                    </li>\n' +
        '\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#amortization" role="tab" aria-controls="AMORTIZATION" aria-selected="true">AMORTIZATION</a>\n' +
        '                    </li>\n' +
        '\n' +
        '                </ul>\n' +
        '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
        '                <div class="dropdown-menu">\n' +
        '                    <a class="dropdown-item active" href="#basic" mobile-link-item>BASIC</a>\n' +
        '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
        '                    <a class="dropdown-item" href="#amortization" mobile-link-item>AMORTIZATION</a>\n' +
        '                </div>\n' +
        '\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="calculator__content">\n' +
        '            <div class="tab-content">\n' +
        '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicPurchasing">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body panel--left" autocomplete="off">\n' +
        '                            <div class="panel--wrapper flex-end">\n' +
        '                                <div class="panel__item panel--large">\n' +
        '                                    <p>Total Monthly Payment</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="totalMonthlyPayment" type="text" name="totalMonthlyPayment" autocomplete="false" data-currency data-input value="{{values.Purchasing.basic.totalPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item">\n' +
        '                                    <p>Loan Term (Yrs)</p>\n' +
        '                                    <div class="panel__input ">\n' +
        '\n' +
        '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.Purchasing.basic.loanTerm}}"\n' +
        '                                               data-msg="Please enter a Loan Term"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="30" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item">\n' +
        '                                    <p>Interest Rate (%)</p>\n' +
        '                                    <div class="panel__input icon--right ">\n' +
        '                                        <i>%</i>\n' +
        '                                        <input title="interestRate" data-percent type="text" name="interestRate" data-input value="{{values.Purchasing.basic.interestRate}}"\n' +
        '                                               data-msg="Please enter a number between, 1 and 15"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="15" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="break"></div>\n'+
        '                                <div class="panel__item panel--large panel--mt">\n' +
        '                                    <p>Down Payment</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="downPayment" type="text" name="downPayment"  data-change="num"\n' +
        '                                               data-currency data-input value="{{values.Purchasing.basic.downPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <a href="# " class="panel__button panel--mt w-auto" data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                            </div>\n' +
        '\n' +
        '                        </form>\n' +
        '                        <div class="panel--right">\n' +
        '                            <p>Your new home can cost up to</p>\n' +
        '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="totalLoan">0.00</span>*</h2>\n' +
        '                            <div class="divider"></div>\n' +
        '                            <div class="">\n' +
        '                                <p>Down Payment <span data-result="DownPayment">$0.00</span></p>\n' +
        '                                <p>Loan Amount <span data-result="LoanAmount">$0.00</span></p>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="advanced" role="tabpanel"  data-calculator data-methods="advancedPurchasing">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body panel--size-small" autocomplete="off">\n' +
        '                            <div class="panel--wrapper ">\n' +
        '                                <div class="panel__item panel--large">\n' +
        '                                    <p>Total Monthly Payment</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="totalMonthlyPayment" autocomplete="false" type="text" name="totalMonthlyPayment" data-currency data-input value="{{values.Purchasing.basic.totalPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item ">\n' +
        '                                    <p>Down Payment</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="downPayment" autocomplete="false" type="text" name="downPayment" data-currency data-input value="{{values.Purchasing.basic.downPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item">\n' +
        '                                    <p>Loan Term (Yrs)</p>\n' +
        '                                    <div class="panel__input ">\n' +
        '\n' +
        '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.Purchasing.basic.loanTerm}}"\n' +
        '                                               data-msg="Please enter a Loan Term"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="30" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item">\n' +
        '                                    <p>Interest Rate (%)</p>\n' +
        '                                    <div class="panel__input icon--right ">\n' +
        '                                        <i>%</i>\n' +
        '                                        <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Purchasing.basic.interestRate}}"\n' +
        '                                               data-msg="Please enter a number between, 1 and 15"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="15" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item panel--large">\n' +
        '                                    <p>Property Tax (Yearly)</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="propertyTax" autocomplete="false" type="text" name="propertyTax" data-currency data-input value="{{values.Purchasing.advanced.propertyTax}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a roperty Tax" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item panel--large">\n' +
        '                                    <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="homeowners" autocomplete="false" type="text" name="homeowners" data-currency data-input value="{{values.Purchasing.advanced.homeownersIns}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a roperty Tax" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Mortgage Ins. (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="mortgageins" autocomplete="false" type="text" name="mortgageins" data-currency data-input value="{{values.Purchasing.advanced.mortgageIns}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a mortgage Tax" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                             <div class="panel__item panel--large">\n' +
    '                                    <p>HOA dues (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="hoa" autocomplete="false" type="text" name="hoa" data-currency data-input value="{{values.Purchasing.advanced.hoaDues}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter HOA dues" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="panel__item panel--large">\n' +
    '                                    <p>Other (Yearly)</p>\n' +
    '                                    <div class="panel__input icon--left ">\n' +
    '                                        <i>$</i>\n' +
    '                                        <input title="other" autocomplete="false" type="text" name="other" data-currency data-input value="{{values.Purchasing.advanced.other}}" data-rule-required data-rule-maxlength="7" data-msg="Please enter a number" />\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="flex-row">\n' +
        '\n' +
        '                                <a href="# " class="panel__button" data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                            </div>\n' +
        '                            <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                        </form>\n' +
        '\n' +
        '                    </div>\n' +
        '                    <div class="results">\n' +
        '                        <div class="results__chart order">\n' +
        '                            <p>Your new home can cost up to</p>\n' +
        '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="totalLoan">0.00</span>*</h2>\n' +
        '                            <div class="divider"></div>\n' +
        '                            <div data-chart>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                        <div class="results__graphic results__chart " data-graphic>\n' +
        '\n' +
        '                        </div>\n' +
        '\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="amortization" role="tabpanel"  data-calculator data-methods="amortizationPurchasing">\n' +
        '                    <div class="panel d-none d-sm-flex">\n' +
        '                        <form class="panel__body panel--left" autocomplete="off">\n' +
        '                            <div class="panel--wrapper flex-end">\n' +
        '                                <div class="panel__item panel--large">\n' +
        '                                    <p>Total Monthly Payment</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="totalMonthlyPayment" type="text" name="totalMonthlyPayment" data-autocomplete-change="downPayment" data-currency data-input value="{{values.Purchasing.basic.totalPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item">\n' +
        '                                    <p>Loan Term (Yrs)</p>\n' +
        '                                    <div class="panel__input ">\n' +
        '\n' +
        '                                        <input title="loanTerm" autocomplete="false" type="text" data-input name="loanTerm" value="{{values.Purchasing.basic.loanTerm}}"\n' +
        '                                               data-msg="Please enter a Loan Term"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="30" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item">\n' +
        '                                    <p>Interest Rate (%)</p>\n' +
        '                                    <div class="panel__input icon--right ">\n' +
        '                                        <i>%</i>\n' +
        '                                        <input title="interestRate" data-percent type="text" name="interestRate" data-input value="{{values.Purchasing.basic.interestRate}}"\n' +
        '                                               data-msg="Please enter a number between, 1 and 15"\n' +
        '                                               data-rule-required\n' +
        '                                               data-rule-max="15" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <div class="panel__item panel--large panel--mt">\n' +
        '                                    <p>Down Payment</p>\n' +
        '                                    <div class="panel__input icon--left ">\n' +
        '                                        <i>$</i>\n' +
        '                                        <input title="downPayment" type="text" name="downPayment"  data-change="num"\n' +
        '                                               data-currency data-input value="{{values.Purchasing.basic.downPayment}}" data-rule-maxlength="7" data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                    </div>\n' +
        '                                </div>\n' +
        '                                <a href="# " class="panel__button panel--mt w-auto" data-calculate calculator-button>calculate</a>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                            </div>\n' +
        '\n' +
        '                        </form>\n' +
        '                        <div class="panel--right">\n' +
        '                            <p>Your new home can cost up to</p>\n' +
        '                            <h2 calculator-highlightedTextColor><i>$</i> <span data-result="totalLoan">0.00</span>*</h2>\n' +
        '                            <div class="divider"></div>\n' +
        '                            <div class="">\n' +
        '                                <p>Down Payment <span data-result="DownPayment">$0.00</span></p>\n' +
        '                                <p>Loan Amount <span data-result="LoanAmount">$0.00</span></p>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                    <div class="results results--table">\n' +
        '                        <div class="results__table" data-table>\n' +
        '\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '\n' +
        '    </div>'
}