import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';
import Handlebars from "handlebars";

import Calculator from '../calculator-common'

export default class extends Calculator {
    constructor(el, param) {
        super(el, param)
    }
    basicBuydoown(data, panel) {
        return new Promise((resolve, reject) => {
            const pprice = data.loanAmount,
                term = data.term,
                months = term * 12,
                interestrate = data.interestRate / 100,
                bdinterest = data.interestRate,
                bdinterestrate = bdinterest / 100,
                thirsPartycontributionPercent = Number(data.contributionBuydownFee);
            let type = data.mode;
            if (data.mode == 111) {
                type = 4;
            }
            const
                loanamt = pprice,
                amortdata = this.logicMethods.AmortizationComputeBuydown(loanamt, bdinterest, months, type),

                bdThirdParty = (Number(loanamt) * (thirsPartycontributionPercent / 100)),
                bdYou = amortdata.totalbuydownamount.toFixed(2) - Number(bdThirdParty);
            const bdmonthlytable = panel.find("#bdmonthlyTable");
            // compute monthly payment for first x years of buydown (x: 0-4)
            const bdmonthlypay = { "payments": [] };
            if (type == 11) {
                for (let j = 0; j < 3; j++) {
                    bdmonthlypay.payments[j] = {
                        "year": "$" + (Currency((Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                    };
                }
            }
            else if (type == 4) {
                for (let k = 0; k <= 3; k++) {
                    bdmonthlypay.payments[k] = {
                        "year": "$" + (Currency((Number(amortdata.amortization[(k * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(k * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                    };
                }
            }
            else {
                for (let i = 0; i <= type; i++) {
                    bdmonthlypay.payments[i] = {
                        "year": "$" + (Currency((Number(amortdata.amortization[(i * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(i * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                    };
                }
            };
            let monthlytempcompile
            //set index to +1 for table display of monthly payment
            Handlebars.registerHelper({
                "offset": function (index) {
                    index += 1;
                    return index;
                }
            });

            monthlytempcompile = Handlebars.compile('<table class="table"><tr><th>Year </th><th>Monthly Payments</th></tr></thead>{{#each payments}}<tr><td>{{offset @index}}</td><td>{{this.year}}</td></tr>{{/each}}</table>');
            let html = monthlytempcompile({ 'payments': Array.from(bdmonthlypay.payments) });
            $('#bdmonthlyTable').html(html);

            panel.find(this.selectors.result).filter('[data-result="totalbuydownamount"]').text(Currency(amortdata.totalbuydownamount, {
                separator: ",",
                formatWithSymbol: true,
                precision: 0
            }).format())
            panel.find(this.selectors.result).filter('[data-result="bdThirdParty"]').text(Currency(bdThirdParty, {
                separator: ",",
                formatWithSymbol: true,
                precision: 0
            }).format())
            panel.find(this.selectors.result).filter('[data-result="bdYou"]').text(Currency(bdYou, {
                separator: ",",
                formatWithSymbol: true,
                precision: 0
            }).format())
            resolve(true)
        })
    }

    advancedBuydoown(data, panel) {
        return new Promise((resolve, reject) => {
            const pprice = data.loanAmount,
                term = data.term,
                months = term * 12,
                interestrate = data.interestRate / 100,

                proptax = data.propertyTax,
                homeownersins = data.homeowners,
                thirsPartycontributionPercent = Number(data.contributionBuydownFee),
                select = panel.find('[data-update-chart]');
            let type = parseInt(data.mode);
            if (data.mode == 111) {
                type = 4;
            } else {
                type = parseInt(data.mode);
            }

            const
                yearselected = select.val() ? select.val() : 1,
                loanamt = pprice;
            let bdinterest = data.interestRate - (type - (yearselected - 1));
            let amortdata = this.logicMethods.AmortizationComputeBuydown(loanamt, data.interestRate, months, type);
            if (type == 11) {
                if (select.val() == 1 || select.val() == 2) {
                    bdinterest = data.interestRate - (1);
                } else {
                    bdinterest = data.interestRate;
                }
            }
            else if (type == 4 && (select.val() == 1 || select.val() == 2 || select.val() == 3)) {
                bdinterest = data.interestRate - (1);
            }
            //else {
            //    bdinterest = data.interestRate;
            //}


            if (!select.val()) {
                let template;
                let html;
                if (type == 11) {
                    template = Handlebars.compile('{{#each type}}<option value="{{this}}" {{#if @first}}selected{{/if}}>{{this}}</option>{{/each }}');
                    html = template({ 'type': Array.from({ length: type - 8 }, (v, k) => k + 1) })
                }
                else if (type == 4) {
                    template = Handlebars.compile('{{#each type}}<option value="{{this}}" {{#if @first}}selected{{/if}}>{{this}}</option>{{/each }}');
                    html = template({ 'type': Array.from({ length: type }, (v, k) => k + 1) })
                }
                else {
                    template = Handlebars.compile('{{#each type}}<option value="{{this}}" {{#if @first}}selected{{/if}}>{{this}}</option>{{/each }}');
                    html = template({ 'type': Array.from({ length: type + 1 }, (v, k) => k + 1) })
                }
                select.html(html)
            }

            const
                principalpaidforyear = (100).toFixed(2),
                bdinterestrate = bdinterest / 100,
                taxamt = Number(proptax / 12),
                insamt = Number(homeownersins / 12),
                printerest = Number(this.logicMethods.pmt((interestrate / 12), (term * 12), -Math.abs(pprice - (pprice * 0)), 0, 0).toFixed(2)),
                bdprinterest = Number(this.logicMethods.pmt((bdinterestrate / 12), (term * 12), -Math.abs(pprice - (pprice * 0)), 0, 0).toFixed(2)),
                totalpayment = (taxamt + insamt + printerest).toFixed(2),
                totalbdpayment = (taxamt + insamt + bdprinterest).toFixed(2),
                totalMonthlyPayment = this.logicMethods.getMonthlyPayment(loanamt, interestrate, months),
                interestpaid = Number(loanamt) * (interestrate / 1200),
                interest = ((Number(loanamt) * interestrate) / 12).toFixed(2),
                principal = (Number(totalpayment) - Number(interest) - Number(taxamt) - Number(insamt)).toFixed(2),
                bdinterestamt = (Number(totalbdpayment) - Number(principal) - Number(taxamt) - Number(insamt)).toFixed(2),
                total = amortdata.monthlyPayment,
                bdThirdParty = (Number(loanamt) * (thirsPartycontributionPercent / 100)),
                bdYou = amortdata.totalbuydownamount.toFixed(2) - Number(bdThirdParty);

            const bdmonthlytable = panel.find("#bdmonthlyTable");
            // compute monthly payment for first x years of buydown (x: 0-4)
            const bdmonthlypay = { "payments": [] };
            if (type == 11) {
                for (let j = 0; j < 3; j++) {
                    bdmonthlypay.payments[j] = {
                        "pAndI": "$" + (Currency((Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                        "totalPay": "$" + (Currency((Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, '')) + taxamt + insamt), { separator: ",", precision: 2 })).format()
                    };
                }
            }
            else if (type == 4) {
                for (let k = 0; k < type; k++) {
                    bdmonthlypay.payments[k] = {
                        "pAndI": "$" + (Currency((Number(amortdata.amortization[(k * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(k * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                        "totalPay": "$" + (Currency((Number(amortdata.amortization[(k * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(k * 12)].interest.replace('$', '').replace(/,/g, '')) + taxamt + insamt), { separator: ",", precision: 2 })).format()
                    };
                }
            }
            else {
                for (let i = 0; i <= type; i++) {
                    bdmonthlypay.payments[i] = {
                        "pAndI": "$" + (Currency((Number(amortdata.amortization[(i * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(i * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                        "totalPay": "$" + (Currency((Number(amortdata.amortization[(i * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(i * 12)].interest.replace('$', '').replace(/,/g, '')) + taxamt + insamt), { separator: ",", precision: 2 })).format()
                    };
                }
            }

            let monthlytempcompile = Handlebars.compile('<table class="table"><tr><th>Year </th><th>Principal and Interest</th><th>Total Monthly Payments</th></tr></thead>{{#each payments}}<tr><td>{{offset @index}}</td><td>{{this.pAndI}}</td><td>{{this.totalPay}}</td></tr>{{/each}}</table>');
            let html = monthlytempcompile({ 'payments': Array.from(bdmonthlypay.payments) });
            bdmonthlytable.html(html);

            if (panel.find(this.selectors.chart).closest('[data-show-calc]'))
                panel.find(this.selectors.chart).closest('[data-show-calc]').removeClass('d-none')
            panel.find(this.selectors.result).filter('[data-result="totalbuydownamount"]').text(Currency(amortdata.totalbuydownamount, { separator: ",", formatWithSymbol: true, precision: 0 }).format())
            panel.find(this.selectors.result).filter('[data-result="bdThirdParty"]').text(Currency(bdThirdParty, { separator: ",", formatWithSymbol: true, precision: 0 }).format())
            panel.find(this.selectors.result).filter('[data-result="bdYou"]').text(Currency(bdYou, { separator: ",", formatWithSymbol: true, precision: 0 }).format())


            const pieColors = (() => {
                var colors = [this.params.tabs.highcharts.pie.Principal, this.params.tabs.highcharts.pie.Interest, this.params.tabs.highcharts.pie.Taxes, this.params.tabs.highcharts.pie.Insurance]
                return colors;
            })();
            let categories = [],
                PrincipalData = [],
                InterestData = []
            if (type == 11) {
                for (let j = 0; j < 3; j++) {
                    categories.push('Year ' + (j + 1))
                    PrincipalData.push(Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')))
                    InterestData.push(Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, '')))
                }
            }
            else if (type == 4) {
                for (let k = 0; k <= 3; k++) {
                    categories.push('Year ' + (k + 1))
                    PrincipalData.push(Number(amortdata.amortization[(k * 12)].principalPaid.replace('$', '').replace(/,/g, '')))
                    InterestData.push(Number(amortdata.amortization[(k * 12)].interest.replace('$', '').replace(/,/g, '')))
                }
            } else {
                for (let i = 0; i <= type; i++) {
                    categories.push('Year ' + (i + 1))
                    PrincipalData.push(Number(amortdata.amortization[(i * 12)].principalPaid.replace('$', '').replace(/,/g, '')))
                    InterestData.push(Number(amortdata.amortization[(i * 12)].interest.replace('$', '').replace(/,/g, '')))
                }
            }
            panel.find(this.selectors.chart).highcharts({
                chart: {
                    type: 'pie',
                    height: 340,
                    backgroundColor: 'transparent',
                    marginTop: 50,
                    marginBottom: 50
                },

                legend: {

                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical',
                    symbolHeight: 14,
                    symbolWidth: 14,
                    symbolRadius: 6,
                    lineHeight: 24,
                    itemMarginTop: 10,
                    itemMarginBottom: 10,
                    useHTML: true,
                    labelFormatter: function () {
                        return '<div class="chart__legend large"><span class="chart__title">' + this.name + '</span><span class="chart__value"> $' + Currency(this.id, { separator: ",", precision: 0 }).format() + '</span></div>';
                    }
                },
                title: {
                    useHTML: true,
                    text: '<div class="titlediv secondtitle">For year <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight + '">' + yearselected + '</span>, your monthly payment will be <span class="firsttitle" style="color:' + this.params.tabs.highcharts.column.Principal + '">' + Currency(totalbdpayment, { symbol: "$", separator: ",", formatWithSymbol: true, precision: 0 }).format() + '</span>, based on a reduced interest rate of <span class="firsttitle" style="color:' + this.params.tabs.highcharts.column.Principal + '">' + bdinterest + '%</span></div>',
                    align: 'center',
                    backgroundColor: '#EFEFEF',
                    marginTop: 50,
                },

                plotOptions: {
                    pie: {
                        innerSize: 120,
                        size: 180,
                        allowPointSelect: true,
                        colors: pieColors,
                        cursor: 'pointer',
                        dataLabels: {
                            overflow: 'allow',
                            crop: false,
                            enabled: false,
                            verticalAlign: 'top',
                            format: '{point.percentage:.1f} %',
                            style: {
                                color: '#888888',
                                fontSize: '12px'

                            }
                        },
                        showInLegend: true,
                        borderWidth: 0,
                        series: {
                            allowPointSelect: true
                        }
                    }
                },
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    borderColor: 'transparent',
                    borderRadius: 5,
                    backgroundColor: '#ffffff',
                    padding: 10,
                    shadow: false,
                    pointFormatter: function () {
                        return '<span class="legend-list"><span style="color:' + this.color + ';font-size: 16px;">\u25CF</span> <b> ' + this.name + '</b> - ' + Currency(this.id, { symbol: "$", separator: ",", formatWithSymbol: true, precision: 0 }).format() + ' </span>'
                    }

                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 450
                        },
                        chartOptions: {
                            plotOptions: {
                                pie: {
                                    visible: false
                                }
                            },
                            legend: {

                                align: 'left',
                                verticalAlign: 'bottom',
                                layout: 'vertical',
                            },
                            title: {
                                marginTop: 100,
                                marginBottom: 100,

                            },
                            chart: {
                                type: 'pie',
                                height: 300,
                                marginTop: 100,
                                events: {
                                    // redraw: function () {
                                    //
                                    //     var total = 0;
                                    //     for (var i = 0, len = this.series[0].yData.length; i < len; i++) {
                                    //         if (this.series[0].points[i].visible)
                                    //             total += this.series[0].yData[i];
                                    //     }
                                    //     this.setTitle({
                                    //         text: '<div class="titleresponsive secondtitleresponsive">Your total payment will be <span class="firsttitleresponsive">' + Currency(total,{separator: ",",  symbol: "$"}).format()  + '</span></div>'
                                    //     });
                                    // }
                                }
                            },

                        }
                    }]
                },
                series: [{
                    name: "Percent",
                    colorByPoint: true,
                    data: [{
                        name: "Principal", //+ numberWithCommas(principal),
                        y: amortdata.amortization[(yearselected - 1) * 12].principalPaid / 1,
                        id: amortdata.amortization[(yearselected - 1) * 12].principalPaid
                    }, {
                        name: "Interest", //+ numberWithCommas(interest),
                        y: bdinterestamt / 1,
                        id: bdinterestamt
                    }, {
                        name: "Taxes", //+ taxamt.toFixed(2),
                        y: taxamt / 1,
                        id: Currency(taxamt, { separator: ",", precision: 0 }).format()
                    }, {
                        name: "Insurance", //+ insamt.toFixed(2),
                        y: insamt / 1,
                        id: Currency(insamt, { separator: ",", precision: 0 }).format()

                    }]
                }]


            });
            panel.find(this.selectors.graphic).highcharts({
                chart: {
                    plotBackgroundColor: '#ffffff',
                    backgroundColor: 'transparent',
                    type: 'column',
                    height: 420,
                    marginTop: 80
                },
                title: {
                    style: {
                        display: 'none'
                    }
                },
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    borderColor: 'transparent',
                    borderRadius: 5,
                    backgroundColor: '#ffffff',
                    padding: 10,
                    shadow: false,
                    pointFormat: '<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}  <span class="small">({point.percentage:.0f}%)</span></span>',
                    shared: true,
                    pointFormatter: function () {
                        return '<span class="legend-list"><span style="color:' + this.color + ';font-size: 16px;">\u25CF</span> <b> ' + this.series.name + '</b> : ' + Currency(this.y, { symbol: "$", separator: ",", formatWithSymbol: true, precision: 0 }).format() + ' <span class="small">(' + Math.round(this.percentage) + '%)</span></span>'
                    }
                },
                legend: {

                    enabled: false,
                    y: 50,
                    align: 'center',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    symbolHeight: 12,
                    symbolWidth: 12,
                    symbolRadius: 6,
                    useHTML: true,
                    floating: true,
                    labelFormatter: function () {

                        return '<div style=""><span class="legenddtitle">' + this.name + '</span></div>';
                    }

                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        showInLegend: true,
                        allowPointSelect: true,
                        borderWidth: 0
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            minwidth: 100,
                            maxWidth: 400
                        },
                        chartOptions: {
                            chart: {
                                height: 250,
                                marginTop: 20,
                                marginBotom: 20
                            },
                            legend: {
                                y: 90,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'top',
                                useHTML: true,
                                floating: true,
                                labelFormatter: function () {
                                    return '<div style="width:50px;"><span class="legenddtitle">' + this.name + '</span></div>';
                                }
                            },
                            title: {
                                style: {
                                    display: 'none'
                                }
                            },
                        }
                    }]
                },
                series: [
                    {
                        name: 'Interest',
                        data: InterestData,
                        color: this.params.tabs.highcharts.column.Interest
                    }, {
                        name: 'Principal',
                        data: PrincipalData,
                        color: this.params.tabs.highcharts.column.Principal
                    }]
            });




            resolve(true)
        })
    }

    amortizationBuydoown(data, panel) {
        return new Promise((resolve, reject) => {
            const pprice = data.loanAmount,
                term = data.term,
                months = term * 12,
                interestrate = data.interestRate / 100,
                bdinterest = data.interestRate,
                bdinterestrate = bdinterest / 100,
                proptax = data.propertyTax,
                homeownersins = data.homeowners,
                thirsPartycontributionPercent = Number(data.contributionBuydownFee);
            let type;
            if (data.mode == 111) {
                type = 4;
            } else {
                type = data.mode;
            }


            const

                loanamt = pprice,
                amortdata = this.logicMethods.AmortizationComputeBuydown(loanamt, bdinterest, months, type),

                bdThirdParty = (Number(loanamt) * (thirsPartycontributionPercent / 100)),
                bdYou = amortdata.totalbuydownamount.toFixed(2) - Number(bdThirdParty);

            const bdmonthlytable = panel.find("#bdmonthlyTable");
            // compute monthly payment for first x years of buydown (x: 0-4)
            const bdmonthlypay = { "payments": [] };
            if (type == 11) {
                for (let j = 0; j < 3; j++) {
                    bdmonthlypay.payments[j] = {
                        "pAndI": "$" + (Currency((Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                        "totalPay": "$" + (Currency((Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, '')) + Number((proptax / 12) + (homeownersins / 12))), { separator: ",", precision: 2 })).format()
                    }
                }
            } else if (type == 4) {
                for (let j = 0; j <= 3; j++) {
                    bdmonthlypay.payments[j] = {
                        "pAndI": "$" + (Currency((Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                        "totalPay": "$" + (Currency((Number(amortdata.amortization[(j * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(j * 12)].interest.replace('$', '').replace(/,/g, '')) + Number((proptax / 12) + (homeownersins / 12))), { separator: ",", precision: 2 })).format()
                    }
                }
            } else {
                for (let i = 0; i <= type; i++) {
                    bdmonthlypay.payments[i] = {
                        "pAndI": "$" + (Currency((Number(amortdata.amortization[(i * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(i * 12)].interest.replace('$', '').replace(/,/g, ''))), { separator: ",", precision: 2 })).format(),
                        "totalPay": "$" + (Currency((Number(amortdata.amortization[(i * 12)].principalPaid.replace('$', '').replace(/,/g, '')) + Number(amortdata.amortization[(i * 12)].interest.replace('$', '').replace(/,/g, '')) + Number((proptax / 12) + (homeownersins / 12))), { separator: ",", precision: 2 })).format()
                    };
                };
            }

            let monthlytempcompile = Handlebars.compile('<table class="table"><tr><th>Year </th><th>Principal and Interest</th><th>Total Monthly Payments</th></tr></thead>{{#each payments}}<tr><td>{{offset @index}}</td><td>{{this.pAndI}}</td><td>{{this.totalPay}}</td></tr>{{/each}}</table>');
            let html = monthlytempcompile({ 'payments': Array.from(bdmonthlypay.payments) });
            bdmonthlytable.html(html);

            panel.find(this.selectors.result).filter('[data-result="totalbuydownamount"]').text(Currency(amortdata.totalbuydownamount, { separator: ",", formatWithSymbol: true, precision: 0 }).format())
            panel.find(this.selectors.result).filter('[data-result="bdThirdParty"]').text(Currency(bdThirdParty, { separator: ",", formatWithSymbol: true, precision: 0 }).format())
            panel.find(this.selectors.result).filter('[data-result="bdYou"]').text(Currency(bdYou, { separator: ",", formatWithSymbol: true, precision: 0 }).format())


            let filterData = {}
            filterData.Items = amortdata.amortization.reduce((function (hash) {
                return function (array, obj) {
                    if (!hash[obj.year])
                        hash[obj.year] = [obj]
                    else
                        hash[obj.year].push(obj);
                    return hash;
                };

            })({}), [])
            filterData.buydown = true
            filterData.principalPaid = true
            filterData.totalInterest = true

            this.renderTable(filterData, panel.find(this.selectors.table)).then(() => {
                //  panel.find('.collapse').collapse({'toogle':false})

            })
            resolve(true)
        })

    }
}