export default function (){
    return '  <div class="gloabl-navigate" data-color="{{tabs.navigateColor}}">\n' +

        '        <div class="gloabl-navigate__item ">\n' +
        '            <div render-button="" {{#if linkNavigate}}data-href="{{links.Mortgage}}" {{/if}}  data-id="mortgage" {{#ifEquals calcMode "Mortgage"}}class="active"{{/ifEquals}}>'+

        '                   {{#if navigate.Mortgage.image}}' +
        '                      <img alt="Mortgage" src="{{navigate.Mortgage.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon" width="41.473" height="44.739"><use xlink:href="#icon-mortage"></use></svg> \n' +
        '                    {{/if}}' +
        
        '                <p>{{#if navigate.Mortgage.text}} {{navigate.Mortgage.text}} {{else}}MORTGAGE PAYMENT{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="gloabl-navigate__item ">\n' +
        '            <div render-button="" {{#if linkNavigate}} data-href="{{links.Purchasing}}" {{/if}}  data-id="purchasing" {{#ifEquals calcMode "Purchasing"}}class="active"{{/ifEquals}}>'+

        '                   {{#if navigate.Purchasing.image}}' +
        '                      <img alt="Purchasing" src="{{navigate.Purchasing.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon"   width="45.337" height="39.475"><use xlink:href="#icon-purchasuing"></use></svg> \n' +
        '                    {{/if}}' +
        '                <p>{{#if navigate.Purchasing.text}} {{navigate.Purchasing.text}} {{else}}PURCHASING POWER{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="gloabl-navigate__item">\n' +
        '            <div render-button {{#if linkNavigate}} data-href="{{links.Qualifying}}" {{/if}} data-id="qualifying" {{#ifEquals calcMode "Qualifying"}}class="active"{{/ifEquals}}>'+

        '                   {{#if navigate.Qualifying.image}}' +
        '                      <img alt="Qualifying" src="{{navigate.Qualifying.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon"  width="38.079" height="49.344"><use xlink:href="#icon-qualifying"></use></svg> \n' +
        '                    {{/if}}' +
        '                <p>{{#if navigate.Qualifying.text}} {{navigate.Qualifying.text}} {{else}}QUALIFYING LOAN{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="gloabl-navigate__item">\n' +
        '            <div render-button {{#if linkNavigate}} data-href="{{links.Rent}}" {{/if}} data-id="rentbuy" {{#ifEquals calcMode "Rent"}}class="active"{{/ifEquals}}>'+

        '                   {{#if navigate.Rent.image}}' +
        '                      <img alt="Rent" src="{{navigate.Rent.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon" width="47.699" height="52.634"><use xlink:href="#icon-rent-buy"></use></svg> \n' +
        '                    {{/if}}' +
        '                <p>{{#if navigate.Rent.text}} {{navigate.Rent.text}} {{else}}RENT VS BUY{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="gloabl-navigate__item">\n' +
        '            <div render-button {{#if linkNavigate}} data-href="{{links.Savings}}" {{/if}} data-id="savings" {{#ifEquals calcMode "Savings"}}class="active"{{/ifEquals}}>'+

        '                   {{#if navigate.Savings.image}}' +
        '                      <img alt="Savings" src="{{navigate.Savings.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon"  width="40.61" height="52.633"><use xlink:href="#icon-saving-calculator"></use></svg> \n' +
        '                    {{/if}}' +
        '                <p>{{#if navigate.Savings.text}} {{navigate.Savings.text}} {{else}}REFINANCE{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="gloabl-navigate__item">\n' +
        '            <div render-button {{#if linkNavigate}} data-href="{{links.ShortLong}}" {{/if}} data-id="shortlong" {{#ifEquals calcMode "ShortLong"}}class="active"{{/ifEquals}}>'+

        '                   {{#if navigate.ShortLong.image}}' +
        '                      <img alt="ShortLong" src="{{navigate.ShortLong.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon" width="46.054" height="46.054"><use xlink:href="#icon-short-long"></use></svg> \n' +
        '                    {{/if}}' +
        '                <p>{{#if navigate.ShortLong.text}} {{navigate.ShortLong.text}} {{else}}15 vs 30 Year{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="gloabl-navigate__item">\n' +
        '            <div render-button {{#if linkNavigate}} data-href="{{links.Buydown}}" {{/if}} data-id="buydown" {{#ifEquals calcMode "Buydown"}}class="active"{{/ifEquals}}>'+

        '                   {{#if navigate.Buydown.image}}' +
        '                      <img alt="BuyDown" src="{{navigate.Buydown.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon" width="46.611" height="52.281"><use xlink:href="#icon-buydown"></use></svg> \n' +
        '                    {{/if}}' +
        '                <p>{{#if navigate.Buydown.text}} {{navigate.Buydown.text}} {{else}}3-2-1 BUYDOWN{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="gloabl-navigate__item">\n' +
        '            <div render-button {{#if linkNavigate}} data-href="{{links.biweeklyMortgage}}" {{/if}} data-id="biweeklyMortgage"  {{#ifEquals calcMode "biweeklyMortgage"}}class="active"{{/ifEquals}}>'+
        '                   {{#if navigate.biweeklyMortgage.image}}' +
        '                      <img alt="BiWeeklyMortgage" src="{{navigate.biweeklyMortgage.image}}">\n' +
        '                   {{else}}    ' +
        '                        <svg class="icon"  width="48.562" height="47.098"><use xlink:href="#icon-bi-weekly"></use></svg> \n' +
        '                    {{/if}}' +
    
        '                <p>{{#if navigate.biweeklyMortgage.text}} {{navigate.biweeklyMortgage.text}} {{else}}Bi-weekly{{/if}}</p>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>'
}