import $ from 'jquery';

import 'jquery-validation';

import Currency from 'currency.js';

import Handlebars from "handlebars";
import  "../../common/handlebars-helpers";

import Logic from './calculator-logic-class';

// table template
import tableTemplate from './template/global/table-template.hbs'

export default class {
    constructor(el, param) {
        this.params = param
        this.calculator = el
        this.settings = {}
        this.selectors = {
            calculateBtn: '[data-calculate]',

            input: '[data-input]',
            result: '[data-result]',
            currency: '[data-currency]',
            percent: '[data-percent]',
            error: '[data-error]',
            graphic: '[data-graphic]',
            chart: '[data-chart]',
            chart2: '[data-chart-2]',
            table: '[data-table]',
            templateTable: '#table-template',
            autoComplete: '[data-autocomplete]',
            toggleSection: '[data-toggle-section]',
            
        }

        this.data = {}
        this.logicMethods = new Logic();
        this.init()
        this.calculator.find(this.selectors.calculateBtn).not('[data-noload]').trigger('click')
    }
    init() {
        this.validateRules();
        this.bind()
        this.calculator.find('form').validate({
            errorPlacement: this.errorPlacement,
            success: this.success,
            
        });
    }
    bind() {
        this.calculator.on({
            'keypress' : (e) => {
                if (e.which == 13) {
                    const el = $(e.target);
                    let panel = el.closest('.tab-pane');
                    panel.find('[data-update-chart]').html('')
                    if (panel.find(this.selectors.calculateBtn).attr('data-calculate')) this.calculate(panel, true)
                    else this.calculate(panel, false)
                }
            }
        })
        this.calculator.on({
            'click': (e) => {
                e.preventDefault()
                const el = $(e.currentTarget);
                el.toggleClass('active')
                el.parent().find('[table-item-hide]').toggleClass('d-none')
            }
        }, '[data-show-table]')
        var typingTimer;
        this.calculator.on({
            'change keyup paste': (e) => {
                const el = $(e.target);
                let panel = el.closest('.tab-pane');
                clearTimeout(typingTimer);
                typingTimer = setTimeout(() => {
                   // panel.find(this.selectors.calculateBtn).attr('data-calculate').click();
                   // this.calculator.find(panel.find(this.selectors.calculateBtn).attr('data-calculate')).click();
                    if (panel.find(this.selectors.calculateBtn).attr('data-calculate')) this.calculate(panel, true)
                    else this.calculate(panel, false)
                },2000)
                if(!this.calculator.hasClass('calculate-pending'))
                    this.calculator.addClass('calculate-pending')
            }
        }, this.selectors.input)
        this.calculator.on({
            'click' : (e) => {
                e.preventDefault()
                const el = $(e.currentTarget);
                let panel = el.closest('.tab-pane');
                panel.find('[data-update-chart]').html('')
                if (el.attr('data-calculate')) this.calculate(panel, true)
                else this.calculate(panel,false)
            }
        }, this.selectors.calculateBtn)

        this.calculator.on({
            'change' : (e) => {
                const el = $(e.currentTarget);
                
                let panel = el.closest('.tab-pane');
                const btn = panel.find(this.selectors.calculateBtn)
                if (btn.attr('data-calculate')) this.calculate(panel, true)
                else this.calculate(panel,false)
                
                
                
                
            }
        }, '[data-update-chart]')
        this.calculator.on({
            'click' : (e,b,c) => {
                e.preventDefault()
                const el = $(e.currentTarget)
                const panel = $('[role = tabpanel]')
                const id = el.attr('data-toggle-section')
                const calculator = el.closest('[data-calculator]')
                const button = calculator.find('[data-toggle-button]')
                //calculator.find('[data-toggle-block]').addClass('d-none')
                //calculator.find('[data-toggle-block="' + id + '"]').removeClass('d-none')
/*                button.text(el.text())*/
                panel.find('[data-toggle-block]').addClass('d-none')
                panel.find('[data-toggle-block="' + id + '"]').removeClass('d-none')
                panel.find('[data-toggle-button]').text(el.text())
                calculator.find('[data-calculate]').click()
             }
        },
             this.selectors.toggleSection)
        this.calculator.on({
            'change keyup paste' : (e) => {
                const el = $(e.currentTarget),
                    val = Currency(el.val()).value,
                    sibling =  this.calculator.find('[name="'+el.attr('data-autocomplete')+'"]');
                parent =  Currency(this.calculator.find('[name="'+el.attr('data-autocomplete-parent')+'"]').val()).value;
                
                if(el.attr('data-change') == 'percent') {
                    sibling.val(parent*(val/100))
                } else {
                    sibling.val(val/parent*100)
                }
            }
        }, this.selectors.autoComplete)
        this.calculator.on({
            'change keyup paste' : (e) => {
                const el = $(e.currentTarget),
                    change = this.calculator.find('[name="'+el.attr('data-autocomplete-change')+'"]');
                
                if(change.val().length) {
                    change.trigger('change')
                }
            }
        }, '[data-autocomplete-change]')
    }
    
    calculate(panel, subPanel) {
        if(panel.find('form').valid()) {
            $('body').addClass('pending')
            this.currencyVal(panel)
            this.percentVal(panel)
            let data ;
            if(subPanel){
                const subpanel = panel.find('[data-toggle-block]:not(".d-none")')
                data = this.getData(subpanel)
                data.mode = subpanel.attr('data-mode')
                
            }
            else{
                data = this.getData(panel)
            }
            $.each(this.calculator.find('.tab-pane').not(panel), (i,e) => {
                let otherdata ;
                const otherpanel = $(e)
                const otherbtnCheck = otherpanel.find('[data-toggle-block]:not(".d-none")').find('[data-calculate]').attr('data-calculate')
               if(otherbtnCheck == 'true'){
                    const othersubpanel = otherpanel.find('[data-toggle-block]:not(".d-none")')
                     otherdata = this.getData(othersubpanel)
                    otherdata.mode = othersubpanel.attr('data-mode')
        
                }
                else{
                    otherdata = this.getData(otherpanel)
                }
                this[otherpanel.attr('data-methods')](otherdata,otherpanel)
            })
            this[panel.attr('data-methods')](data,panel).then(() => {
                this.calculator.removeClass('calculate-pending')
                $('body').removeClass('pending')
            })
        }
        
    }
    
    getData(panel) {
        let data= {}
        $.each(panel.find(this.selectors.input),(i,el) => {
            const name = $(el).attr('name'),
                val = $(el).val()
            if(this.calculator.find(this.selectors.input).filter('[name="'+name+'"]').not($(el)).length){
                this.calculator.find(this.selectors.input).filter('[name="'+name+'"]').not($(el)).val() != val ?
                    this.calculator.find(this.selectors.input).filter('[name="'+name+'"]').not($(el)).val(val): '';
            }
            data[name] = Currency(val).value
        })
        
        
        return data
    }
    //renderError(panel){
    //     $.each(panel.find(this.selectors.input),(i,el) => {
    //         if(!$(el).val().length){
    //             let error = $('<label class="error" data-error>')
    //             if($(el).attr('data-empty') && $(el).attr('data-empty').length) {
    //                 error.text($(el).attr('data-empty'))
    //             } else {
    //                 error.text('invalid field')
    //             }
    //
    //
    //             $(el).parent().after(error)
    //         }
    //     })
    // }
    // resetError(panel) {
    //     panel.find(this.selectors.error).remove()
    // }
    errorPlacement(error, element){
        const $el = $(element).parent();
        const $ell = $(element).parent().parent().find('p');
        //const $el = $(element).parent().find('.panel__item');
        $ell.before(error);
        $el.addClass('field--error');
        $el.removeClass('field--success');
    }
    success(label) {
        const $el = $(label).parent().find('.panel__input');
        $el.removeClass('field--error');
        $el.addClass('field--success');
        $(label).remove()
    }
    currencyVal(panel) {
        $.each(panel.find(this.selectors.currency), (i, el) => {
            $(el).val( Currency($(el).val(),{separator: "," ,precision: 0}).format())
        })
        
    }
    percentVal(panel) {
        $.each(panel.find(this.selectors.percent), (i, el) => {
            $(el).val( Currency($(el).val(),{separator: "" ,precision: 3}).format())
        })
        
    }
    validateRules() {
        $.validator.addMethod("maxlength", function (value, element, arg) {
            const Value = Currency(value).value
            let len = Math.ceil(Math.log10(Value + 1));
            return this.optional( element ) || len <= arg;
        }, $.validator.format("Please enter no more than {0} characters." ));
    }
    
    
    
    
    
    
    renderTable(context, elem) {
        return new Promise((resolve, reject) => {
            let source = tableTemplate()
            let template = Handlebars.compile(source);
            let html = template(context)
            elem.html(html)
            resolve(true)
        })
    }
    
    
    
}