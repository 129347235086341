import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';

import Calculator from '../calculator-common'

export default class extends Calculator{
    constructor(el, param) {
        super(el, param)


    }

    basicMountly(data, panel ){
        return new Promise((resolve, reject) => {
      
        const monthlyPayments = this.logicMethods.getMonthlyPayment(data.loanAmount,data.interestRate,data.loanTerm*12)
       
        panel.find(this.selectors.result).filter('[data-result="monthlyPayments"]').text(Currency(monthlyPayments,{separator: ",", precision: 0}).format())
        var months = data.loanTerm*12
        var pprice = Number(data.loanAmount);
        var interestrate = Number(data.interestRate) / 100;
        var interest = (data.loanAmount * interestrate) / 12;
        
        var printerest = Number(this.logicMethods.pmt((interestrate / 12), (months), -Math.abs(pprice- (pprice * 0)), 0, 0).toFixed(2));
      
        var principal = Number(printerest) - Number(interest);
        
        panel.find(this.selectors.result).filter('[data-result="printerest"]').text(Currency(interest,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="principal"]').text(Currency(principal,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
            resolve(true)
        })
    }
    advancedMountly(data, panel ){
        return new Promise((resolve, reject) => {
            var pprice = Number(data.loanAmount);
            var term = Number(data.loanTerm);
            var interestrate = Number(data.interestRate) / 100;
            var proptax = Number(data.propertyTax);
            var homeownersins = Number(data.homeowners);
            var taxamt = Number(proptax / 12);
            var insamt = Number(homeownersins / 12);
            var printerest = Number(this.logicMethods.pmt((interestrate / 12), (term * 12), -Math.abs(pprice - (pprice * 0)), 0, 0).toFixed(2));
            var totalpayment = (taxamt + insamt + printerest).toFixed(2);
            var loanamt = pprice;
            var interest = (Number(loanamt) * interestrate) / 12;
            var interest = interest.toFixed(2);
            var principal = Number(totalpayment) - Number(interest) - Number(taxamt) - Number(insamt);
            var principal = principal.toFixed(2);
            /* story 4994 */
            var mortgageins = Number(data.mortgageins) / 12;
            var hoadues = Number(data.hoa) / 12;
            var others = Number(data.other) / 12;

            var amortdata = this.logicMethods.AmortizationCompute(data.loanAmount, data.interestRate, term * 12);
            var monthlyPrincInt = this.logicMethods.getMonthlyPayment(data.loanAmount, data.interestRate, data.loanTerm * 12);
            var total = Number(amortdata.monthlyPayment) + taxamt + insamt + mortgageins + hoadues + others;
            var totalIns = Number(homeownersins / 12) + mortgageins;
            var totalFeesOther = hoadues + others;

            panel.find(this.selectors.result).filter('[data-result="monthlyPayments"]').text(Currency(total,{separator: ",", precision: 0}).format())
            
            var pieColors =  (() => {
                var colors = [this.params.tabs.highcharts.pie.Principal,this.params.tabs.highcharts.pie.Interest,this.params.tabs.highcharts.pie.Taxes,this.params.tabs.highcharts.pie.Insurance, this.params.tabs.highcharts.pie.Fees]
                return colors;
            })();
            
            let categories = [],
                PrincipalData = [],
                InterestData = []
            let offset =  term/5,
            step = 5
            if(term > 20) {
                offset = 3;
                step = 5;
            }
            for(let i = 0; i < offset; i++ ) {

                const variable = (step * (i + 1))
                if (variable > term) {
                    categories.push(term + ' years')
                    PrincipalData.push(Number(amortdata.amortization[(term * 12) - 1].principalPaid.replace('$', '').replace(/,/g, '')))
                    InterestData.push(Number(amortdata.amortization[(term * 12) - 1].interest.replace('$', '').replace(/,/g, '')))
                } else {
                    categories.push(5*(i+1) + ' years')
                    PrincipalData.push(Number(amortdata.amortization[(variable * 12) - 1].principalPaid.replace('$', '').replace(/,/g, '')))
                    InterestData.push(Number(amortdata.amortization[(variable * 12) - 1].interest.replace('$', '').replace(/,/g, '')))
                }
                
            }

            panel.find(this.selectors.chart).highcharts({
                chart: {
                    type: 'pie',
                    height: 260,
                    backgroundColor: 'transparent',
                    events: {
                    
                    }
                    
                },
                title: {
                    text:''
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical',
                    symbolHeight: 14,
                    symbolWidth: 14,
                    symbolRadius: 6,
                    lineHeight: 24,
                    itemMarginTop: 10,
                    itemMarginBottom: 10,
                    useHTML: true,
                    labelFormatter: function () {
                        return '<div class="chart__legend"><span class="chart__title">' + this.name + '</span><span class="chart__value"> $' + this.id + '</span></div>';
                    }
                },
                
                
                plotOptions: {
                    pie: {
                        innerSize: 120,
                        size: 180,
                        allowPointSelect: true,
                        colors: pieColors,
                        cursor: 'pointer',
                        dataLabels: {
                            overflow: 'allow',
                            crop: false,
                            enabled: false,
                            verticalAlign: 'top',
                            format: '{point.percentage:.1f} %',
                            style: {
                                color: '#888888',
                                fontSize: '12px'
                                
                            }
                        },
                        showInLegend: true,
                        borderWidth: 0,
                        series: {
                            allowPointSelect: true
                        }
                    }
                },
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    borderColor: 'transparent',
                    borderRadius: 5,
                    backgroundColor: '#ffffff',
                    padding: 10,
                    shadow:false,
                    pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {point.name}</b> - ${point.id} </span>',
        
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 450
                        },
                        chartOptions: {
                            plotOptions: {
                                pie:{
                                    visible: false
                                }
                            },
                            caption :{
                                margin: 0
                            },


                            legend:{
                                align: 'right',
                                verticalAlign: 'top',
                                itemDistance: 0,
                                padding: 0,
                                layout: 'vertical',
                                width: '100%',
                                floating: false,
                                itemWidth: "100%"

                            },
                            chart: {
                                type: 'pie',
                                height: 200,
                                marginTop: 0,
                                events: {
                                    // redraw: function () {
                                    //
                                    //     var total = 0;
                                    //     for (var i = 0, len = this.series[0].yData.length; i < len; i++) {
                                    //         if (this.series[0].points[i].visible)
                                    //             total += this.series[0].yData[i];
                                    //     }
                                    //     this.setTitle({
                                    //         text: '<div class="titleresponsive secondtitleresponsive">Your total payment will be <span class="firsttitleresponsive">' + Currency(total,{separator: ",",  symbol: "$"}).format()  + '</span></div>'
                                    //     });
                                    // }
                                }
                            },
                            
                        }
                    }]
                },
                series: [{
                    name: "Percent",
                    colorByPoint: true,
                    data: [{
                        name: "Principal",
                        y: principal / 1,
                        id: Currency(principal,{separator: ",", precision: 0}).format()
                    }, {
                        name: "Interest",
                        y: interest / 1,
                        id:  Currency(interest,{separator: ",", precision: 0}).format()
                    }, {
                        name: "Taxes",
                        y: taxamt / 1,
                        id: Currency(taxamt,{separator: ",", precision: 0}).format()
                    }, {
                        name: "Insurance",
                        y: insamt / 1,
                        id: Currency(totalIns,{separator: ",", precision: 0}).format()
                    }, {
                         name: "HOA and Other",
                         y: totalFeesOther / 1,
                         id: Currency(totalFeesOther, {separator: ",", precision: 0}).format()
                     }]
                }]
                
                
            });
            panel.find(this.selectors.graphic).highcharts({
                chart: {
                    plotBackgroundColor: '#ffffff',
                    type: 'column',
                    backgroundColor: 'transparent',
                    marginTop: 50
                },
                title: {
                    useHTML: true,
                    text: '<div class="titlediv"><div class="secondtitle">Your total Principal and Interest payment: <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight + '">' + Currency(monthlyPrincInt,{ symbol: "$", separator: ",",formatWithSymbol: true , precision: 0}).format()+ '</span></div></div>',
                    align: 'center',
                    backgroundColor: '#EFEFEF',
                    marginTop: 15
                    
                },
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                legend: {
                    enabled: false,
                    y: 50,
                    align: 'center',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    symbolHeight: 12,
                    symbolWidth: 12,
                    symbolRadius: 6,
                    useHTML: true,
                    floating: true,
                    labelFormatter: function () {
                        
                        return '<div style=""><span class="legenddtitle">' + this.name + '</span></div>';
                    }
                },
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    borderColor: 'transparent',
                    borderRadius: 5,
                    backgroundColor: '#ffffff',
                    padding: 10,
                    shadow:false,
                    pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}   {point.id} <span class="small">({point.percentage:.0f}%)</span></span>',
                    shared: true,
                    pointFormatter: function () {
                        return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> : '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true , precision: 0}).format()+' <span class="small">('+Math.round(this.percentage)+'%)</span></span>'
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        showInLegend: true,
                        allowPointSelect: true,
                        borderWidth: 0
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            minwidth: 100,
                            maxWidth: 400
                        },
                        chartOptions: {
                            chart: {
                                height: 320,
                                marginTop: 70,
                                marginBotom: 20,
                                padding:0,
                                margin: 0
                            },
                            legend: {
                                y: 90,
                                align: 'left',
                                layout: 'horizontal',
                                verticalAlign: 'top',
                                useHTML: true,
                                floating: true,
                                labelFormatter: function () {
                                    return '<div style="width:50px;"><span class="legenddtitle">' + this.name + '</span></div>';
                                }
                            },
                            title: {
                                x: 0,
                                y: 15,
                                verticalAlign: 'top',
                                align: 'center',
                                text: '<div class="titleresponsive"><div class="secondtitleresponsive text-center">Your total Principal <br> and Interest </div></div>'
                            },
                        }
                    }]
                },
                series: [
                    {
                        name: 'Interest',
                        data: InterestData,
                      
                        color: this.params.tabs.highcharts.column.Interest
                    },{
                        name: 'Principal',
                        data: PrincipalData,
                        color: this.params.tabs.highcharts.column.Principal
                    }]
            });
            resolve(true)
        })
    }
    amortization(data, panel) {
                return new Promise((resolve, reject) => {
            
        const monthlyPayments = this.logicMethods.getMonthlyPayment(data.loanAmount,data.interestRate,data.loanTerm*12)
    
        panel.find(this.selectors.result).filter('[data-result="monthlyPayments"]').text(Currency(monthlyPayments,{separator: ",", precision: 0}).format())
        var months = data.loanTerm*12
        var pprice = Number(data.loanAmount);
        var interestrate = Number(data.interestRate) / 100;
        var interest = (data.loanAmount * interestrate) / 12;
    
        var printerest = Number(this.logicMethods.pmt((interestrate / 12), (months), -Math.abs(pprice- (pprice * 0)), 0, 0).toFixed(2));
    
        var principal = Number(printerest) - Number(interest);
    
        panel.find(this.selectors.result).filter('[data-result="printerest"]').text(Currency(interest,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="principal"]').text(Currency(principal,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
    
        var amortdata = this.logicMethods.AmortizationCompute(data.loanAmount,data.interestRate,months);
        
        
        
        
        
        
        var filterData={}
        filterData.Items = amortdata.amortization.reduce((function(hash){
            return function(array,obj){
                if(!hash[obj.year])
                    hash[obj.year]=[obj]
                else
                    hash[obj.year].push(obj);
                return hash;
            };
            
        })({}),[])

        filterData.principalPaid = true
        filterData.totalInterest = true
        this.renderTable(filterData,    panel.find(this.selectors.table)).then(() => {
          
            // $(panel.find('.collapse')).collapse('dispose')
            // $(panel.find('.collapse')).collapse({'toogle':false})
            
        })
        resolve(true)
    })
    
    }
}