import $ from 'jquery';
import Handlebars from "handlebars";
import  "../../common/handlebars-helpers";

import CalculatorMode from './calculator-class'
import Tab from './tabs'


// template calculator
import biweeklyMortgage from './template/biweeklyMortgage.hbs'
import mortgage from './template/mortgage.hbs'
import purchasing from './template/pursage.hbs'
import qualifying from './template/qualifying.hbs'
import rentbuy from './template/rentbuy.hbs'
import shortlong from './template/shortlong.hbs'
import savings from './template/savings.hbs'
import buydown from './template/buydown.hbs'

// template common
import navigate from './template/global/navigate.hbs'
import mobileNav from './template/global/mobile-nav.hbs'
import tableTemplate from './template/global/table-template.hbs'
import wrapper from './template/global/wrapper.hbs'

export default class {
    constructor(el, param) {
      
        this.module = el
        this.params = param
        this.selectors = {
            render: '[data-render]',
            linkItem: '[render-button]',
            linkItemMobile: '[render-button-mobile]',
            linkRender: '[data-render-navigate]',
            linkRenderMobile: '[data-render-navigate-mobile]',
            id: 'data-id'
        }
        this.template = {
            biweeklyMortgage : biweeklyMortgage(),
            mortgage: mortgage(),
            purchasing: purchasing(),
            qualifying: qualifying(),
            rentbuy: rentbuy(),
            shortlong: shortlong(),
            savings: savings(),
            buydown: buydown(),
    
            navigate: navigate(),
            mobileNav: mobileNav(),
            tableTemplate: tableTemplate(),
            wrapper: wrapper(),
        }
        
    }
    
    init() {
        
        this.renderWrapper().then(() => {
            this.generateLink().then(() => {
                this.element = {
                    render: this.module.find(this.selectors.render),
                    linkItem: this.module.find(this.selectors.linkItem),
                    linkItemMobile: this.module.find(this.selectors.linkItemMobile),
                }
                this.bind()
                this.renderTab()
            })
        })
    }
    renderSingle (id ) {
        const lowerCaseId = id.charAt(0).toLowerCase() + id.slice(1);
        this.renderWrapper().then(() => {
            this.render(lowerCaseId).then(() => {
                $("html, body").stop().animate({scrollTop:0}, 500, 'swing');
                const tabs = $('[data-tab]')
                const calc = $('[data-calc-mode]');
                let mode
                if(calc.length)
                    $.each(calc, (i, e) => {
                        mode = $(e).attr('data-calc-mode')
                        new CalculatorMode($(e), this.params,mode)
                    })
                if(tabs.length)
                    $.each(tabs, (i, e) => {
                        new Tab($(e), this.params)
                    })
                }
    
            )
        })
    }
    generateLink() {
        return new Promise((resolve, reject) => {

            let source = this.template.navigate,
                source2 = this.template.mobileNav

            let template = Handlebars.compile(source),
                template2 = Handlebars.compile(source2)
                
            let html = template(this.params),
                 html2 = template2(this.params)
            
            $(this.selectors.linkRender).html(html)
            $(this.selectors.linkRenderMobile).html(html2)
            resolve(true)
        })
    }
    renderWrapper() {
        return new Promise((resolve) => {
            let source = this.template.wrapper
            let template = Handlebars.compile(source);
            let html = template(this.params )
             this.module.html(html)
            resolve(true)
        })
      
    }
    renderTab () {
        const color = this.element.linkItem.closest('[data-color]').attr('data-color')
        this.element.linkItem.filter('.active').css({
            'border-color': color,
            'color': color
        })
        this.element.linkItemMobile.filter('.active').css({
         
            'color': color
        })
        this.element.linkItem.hover((e) => {
            const el = $(e.currentTarget)
            el.css({
                'border-color': color,
                'color': color
            })
        }, (e) => {
            const el = $(e.currentTarget)
            if(!el.hasClass('active'))
                el.removeAttr('style')
        })
        const id =  this.element.linkItem.filter('.active').attr('data-id')
        this.render(id).then(() => {
                $("html, body").stop().animate({scrollTop:0}, 500, 'swing');
               
                const tabs = $('[data-tab]')
                const calc = $('[data-calc-mode]');
                let mode
       
                
                if(calc.length)
                    $.each(calc, (i, e) => {
                        mode = $(e).attr('data-calc-mode')
                        new CalculatorMode($(e), this.params,mode)
                    })
                if(tabs.length)
                    $.each(tabs, (i, e) => {
                        new Tab($(e), this.params)
                    })
            }
    
        )
    }
   
    bind() {
        this.module.on({
            'click': (e) => {
                e.preventDefault()

                const el = $(e.currentTarget)
                if(el.attr('data-href') && el.attr('data-href').length){
                    window.location.href = el.attr('data-href')
                    return false
                }
                const id =el.attr(this.selectors.id)
                const color = this.element.linkItem.closest('[data-color]').attr('data-color')
                this.element.linkItem.removeClass('active inactive').removeAttr('style')
                this.element.linkItemMobile.removeClass('active').removeAttr('style')
                this.element.linkItem.filter('[data-id="'+id+'"]').addClass('active').css('border-bottom-color',color)
                this.element.linkItemMobile.filter('[data-id="'+id+'"]').addClass('active').css('color',color)
            
            
                this.render(id).then(() => {
                    $("html, body").stop().animate({scrollTop:0}, 500, 'swing');
                    const tabs = $('[data-tab]')
                    const calc = $('[data-calc-mode]');
                    let mode
    
    
                    if(calc.length)
                        $.each(calc, (i, e) => {
                            mode = $(e).attr('data-calc-mode')
                            new CalculatorMode($(e), this.params,mode)
                        })
                    if(tabs.length)
                        $.each(tabs, (i, e) => {
                            new Tab($(e), this.params)
                        })
                    }
                
                )
            }
        },this.selectors.linkItem +', '+this.selectors.linkItemMobile)
        this.module.on({
      
            mouseenter: (e) => {
                if(!$(e.currentTarget).hasClass('active')) {
                    const el = $(this.selectors.linkItem).filter('.active')
                    el.addClass('inactive').removeClass('active')
    
                }
              
            },
            mouseleave: (e) => {
                const el = $(this.selectors.linkItem).filter('.inactive')
                    el.addClass('active').removeClass('inactive')
                
    
            }
        }, this.selectors.linkItem)
       
      
       
    }
    render( id) {
        
        return new Promise((resolve, reject) => {
            let source = this.template[id]
            let template = Handlebars.compile(source);
            let html = template(this.params)
            
            $(this.selectors.render).html(html)
            resolve(true)
        })
    }
}