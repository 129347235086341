import '../../../scss/CMG/shared-resources/widget/calculator/calculator.scss';
import '../../../scss/CMG/shared-resources/widget/calculator/icons/index';
import '../../../scss/CMG/corporate/embed-code/lo-section.scss';
import 'bootstrap/dist/css/bootstrap.css';
import svgxhr from "webpack-svgstore/dist/helpers/svgxhr";
import $ from 'jquery';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import DynamicTab from './render-template';
import { mergeDeep } from './deepmerge';
import defaultValues from '../../../../wwwroot/dv/defaultValues.json';
export default class {

    constructor(el, params, settings) {
        //console.log(params)
        this.param = {
            navigateMode: true,
            calcMode: 'Mortgage',
            footnote: true,
            amortTab: true,
            plugins: true,
            h1: true,
            linkNavigate: false,
            svgUrl: 'http://cmg-test.cmgfi.com/',
            displayLO: false
            , ...params
        };
        class Person {

            get name() {
                return this._name + '!!!'
            }

            set name(newValue) {
                this._name = newValue
            }

            constructor(name) {
                this._name = name
            }
        }

        this.setting = mergeDeep({
            navigate: {
                Mortgage: {
                    image: '',
                    text: ''
                },
                Purchasing: {
                    image: '',
                    text: ''
                },
                Qualifying: {
                    image: '',
                    text: ''
                },
                Rent: {
                    image: '',
                    text: ''
                },
                ShortLong: {
                    image: '',
                    text: ''
                },
                Savings: {
                    image: '',
                    text: ''
                },
                Buydown: {
                    image: '',
                    text: ''
                },
                biweeklyMortgage: {
                    image: '',
                    text: ''
                }
            },
            links: {},
            values: {
                Mortgage: {
                    basic: defaultValues.mortgagePayment[0].basic,
                    advanced: defaultValues.mortgagePayment[0].advanced
                },
                Purchasing: {
                    basic: defaultValues.purchasingPower[0].basic,
                    advanced: defaultValues.purchasingPower[0].advanced
                },
                Qualifying: {
                    monthlyIncome: defaultValues.qualifyingLoan[0].monthlyIncome,
                    monthlyPayments: defaultValues.qualifyingLoan[0].monthlyPayments,
                    loanInfo: defaultValues.qualifyingLoan[0].loanInfo
                },
                RentBuy: {
                    comparableRent: defaultValues.rentVsBuy[0].comparableRent,
                    homePurchase: defaultValues.rentVsBuy[0].homePurchase,
                    newLoanInfo: defaultValues.rentVsBuy[0].newLoanInfo,
                    assumptions: defaultValues.rentVsBuy[0].assumptions
                },
                Refinance: {
                    currentLoan: defaultValues.refinance[0].currentLoan,
                    newLoan: defaultValues.refinance[0].newLoan,
                    accountsToBePaid: defaultValues.refinance[0].accountsToBePaid
                },
                FifteenThirty: {
                    homeInfo: defaultValues.fifteenVsThirty[0].homeInfo,
                    shortTermInfo: defaultValues.fifteenVsThirty[0].shortTermInfo,
                    longTermInfo: defaultValues.fifteenVsThirty[0].longTermInfo
                },
                Buydown: defaultValues.buyDown,
                BiWeekly: {
                    basic: defaultValues.biWeekly[0].basic,
                    advanced: defaultValues.biWeekly[0].advanced
                }
            },
            tabs: {
                highcharts: {
                    column: {
                        Interest: '#138890',
                        Principal: '#3DABB2'
                    },
                    pie: {
                        Principal: "#138890",
                        Interest: "#9CC846",
                        Taxes: "#2A343D",
                        Insurance: "#9B9B9B",
                        "Homeowners Ins": "#9B9B9B",
                        "Mortgage Ins": "#32A4AC",
                        Fees: "#134A90"
                    }
                },
                buttonColor: '#9AC125',
                get navigateColor() {

                    return this.buttonColor
                    //return this.navigateColor.length ? this.navigateColor :this.buttonColor
                },
                highlightedTextColor: '#138890',
                highlightedTextColorLight: '#32A4AC',
                headerColor: '#138890',
                lo: 'Yes',
                loName: 'Loan Officer Name',
                loTitle: 'Loan Officer Title',
                loNmls: 'Loan Officer NMLS',
                loImageurl: 'https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1571538212/CMG_Web_Resources/LO_Images/photo-placeholder.png',
                loLocation: 'Loan Officer Location',
                loPhone: 'xxx-xxx-xxxx',
                loEmail: 'xxxx@cmgfi.com',
                loMysite: 'https://www.cmgfi.com/mysite/xxxxxx',
                loApplynow: 'https://my.cmgfi.com/borrower/signup/xxxx',
                loLogo: 'https://cloud.cmgfi.com/dvbdysuf5/image/upload/v1631888625/AIOWebsite/Shared_Images/cmg-aio-logo.svg'
            }
        }, settings);

        this.setting = mergeDeep(this.setting, this.param);

        this.module = $(el);
    }
    init() {
        if (this.param.plugins) {
            const __svg__ = {
                path: './sprite/**/*.svg',
                name: 'img/sprite.svg'
            };
            window.baseUrl = 'https://cors-anywhere.herokuapp.com/'+this.setting.svgUrl
            svgxhr("img/sprite.svg");
        }
        this.setting.values = {
            Mortgage: {
                basic: this.setting.values.Mortgage.basic,
                advanced: this.setting.values.Mortgage.advanced
            },
            Purchasing: {
                basic: this.setting.values.Purchasing.basic,
                advanced: this.setting.values.Purchasing.advanced
            },
            Qualifying: {
                monthlyIncome: this.setting.values.Qualifying.monthlyIncome,
                monthlyPayments: this.setting.values.Qualifying.monthlyPayments,
                loanInfo: this.setting.values.Qualifying.loanInfo
            },
            RentBuy: {
                comparableRent: this.setting.values.RentBuy.comparableRent,
                homePurchase: this.setting.values.RentBuy.homePurchase,
                newLoanInfo: this.setting.values.RentBuy.newLoanInfo,
                assumptions: this.setting.values.RentBuy.assumptions
            },
            Refinance: {
                currentLoan: this.setting.values.Refinance.currentLoan,
                newLoan: this.setting.values.Refinance.newLoan,
                accountsToBePaid: this.setting.values.Refinance.accountsToBePaid
            },
            FifteenThirty: {
                homeInfo: this.setting.values.FifteenThirty.homeInfo,
                shortTermInfo: this.setting.values.FifteenThirty.shortTermInfo,
                longTermInfo: this.setting.values.FifteenThirty.longTermInfo
            },
            Buydown: this.setting.values.Buydown,
            BiWeekly: {
                basic: this.setting.values.BiWeekly.basic,
                advanced: this.setting.values.BiWeekly.advanced
            }
        }
        const render = new DynamicTab(this.module, this.setting);

        if (this.parseBoolean(this.param.navigateMode))
            render.init();
        else {

            render.renderSingle(this.param.calcMode, this.param)
        }

    }
    parseBoolean(str) {
        return /^true$/i.test(str);
    }
}

export class TableCSVExporter {
    constructor(table, includeHeaders = true) {
        this.table = table;
        this.rows = Array.from(table.querySelectorAll("tr"));

        if (!includeHeaders && this.rows[0].querySelectorAll("th").length) {
            this.rows.shift();
        }
    }

    convertToCSV() {
        const lines = [];
        const numCols = this._findLongestRowLength();

        for (const row of this.rows) {
            let line = "";

            for (let i = 0; i < numCols; i++) {
                let currentCol = row.children[i].textContent;
                if (!lines[0]) {
                    line += TableCSVExporter.parseCell(row.children[i]);
                    line += (i !== (numCols - 1)) ? "," : "";
                } else if (row.children[i] !== undefined
                    && currentCol !== "Month"
                    && currentCol !== "Month No."
                    && currentCol !== "Year No."
                    && currentCol !== "Payment"
                    && currentCol !== "Principal"
                    && currentCol !== "Interest"
                    && currentCol !== "Total Interest"
                    && currentCol !== "Buydown"
                    && currentCol !== "Balance"
                    && currentCol !== "Accelerated Payment"
                    && currentCol !== "Accelerated Interest"
                    && currentCol !== "Accelerated Balance") {
                    line += TableCSVExporter.parseCell(row.children[i]);
                    line += (i !== (numCols - 1)) ? "," : "";
                } else {
                     line = null
                }
            }
            if (line !== null) {
                lines.push(line);
            }
        }

        return lines.join("\n");
    }

    _findLongestRowLength() {
        return this.rows.reduce((l, row) => row.childElementCount > l ? row.childElementCount : l, 0);
    }

    static parseCell(tableCell) {
        let parsedValue = tableCell.textContent;

        // Replace all double quotes with two double quotes
        parsedValue = parsedValue.replace(/"/g, `""`);

        // If value contains comma, new-line or double-quote, enclose in double quotes
        parsedValue = /[",\n]/.test(parsedValue) ? `"${parsedValue}"` : parsedValue;

        return parsedValue;
    }
}

window.downloadCSV = function (data) {
    const exporter = new TableCSVExporter(data);
    const csvOutput = exporter.convertToCSV();
    const csvBlob = new Blob([csvOutput], { type: "text/csv" });
    const blobUrl = URL.createObjectURL(csvBlob);
    const anchorElement = document.createElement("a");

    anchorElement.href = blobUrl;
    anchorElement.download = "amortization.csv";
    anchorElement.click();

    setTimeout(() => {
        URL.revokeObjectURL(blobUrl);
    }, 500);
};
