export default function (){
    return ' <div class="calculator__tab" data-tab data-calc-mode="Buydown">\n' +
        '        <div class="calculator__header">\n' +
        '           {{#if h1}}  <h1>3-2-1 Mortgage Buydown Calculator</h1>{{else}}<h3>3-2-1 Mortgage Buydown Calculator</h3>{{/if}}\n' +       
        '            <p>\n' +
        '                See how our temporary buy down loan options can lower your\n' +
        '                payments over the first few years of the loan\n' +
        '            </p>\n' +
        '            <div class="calculator__navigate">\n' +
        '                <ul class="nav nav-tabs" id="myTab" role="tablist">\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link active" data-tab-link href="#basic" role="tab" aria-controls="basic" aria-selected="true">BASIC</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#advanced" role="tab" aria-controls="advanced" aria-selected="true">ADVANCED</a>\n' +
        '                    </li>\n' +
        '                    <li class="nav-item">\n' +
        '                        <a class="nav-link " data-tab-link href="#amortization" role="tab" aria-controls="AMORTIZATION" aria-selected="true">AMORTIZATION</a>\n' +
        '                    </li>\n' +
        '                </ul>\n' +
        '                <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" mobile-link>BASIC</a>\n' +
        '                <div class="dropdown-menu">\n' +
        '                    <a class="dropdown-item active" href="#basic" mobile-link-item>BASIC</a>\n' +
        '                    <a class="dropdown-item" href="#advanced" mobile-link-item>ADVANCED</a>\n' +
        '                    <a class="dropdown-item" href="#amortization" mobile-link-item>AMORTIZATION</a>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '        <div class="calculator__content">\n' +
        '            <div class="tab-content">\n' +
        '                <div class="tab-pane active" id="basic" role="tabpanel"  data-calculator data-methods="basicBuydoown">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body panel--left" autocomplete="off">\n' +
        '                            <div class="dropdown">\n' +
        '                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuQualifying" data-toggle-button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n' +
        '                                    3/2/1 Buydown\n' +
        '                                </button>\n' +
        '                                <div class="dropdown-menu" aria-labelledby="dropdownMenuQualifying">\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown321">3/2/1 Buydown</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown111">1/1/1 Buydown</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown21">2/1 BUYDOWN</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown11">1/1 BUYDOWN</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown10">1/0 BUYDOWN</a>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="" data-toggle-block="buydown321" data-mode="3">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input title="term" autocomplete="false" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown111" data-mode="111">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input title="term" autocomplete="false" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown21" data-mode="2">\n' +
        '\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 2.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="2.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +  
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown11" data-mode="11">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input title="term" autocomplete="false" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown10" data-mode="1">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 1.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="1.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                    </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                        </form>\n' +
        '                         <div class="panel--right">\n' +
        '                           <div>\n' +
        '                            <div id="bdmonthlyTable" class="table__body"></div>\n'+            
        '                               </div>\n' +   
        '                         </div>\n' + 
        '                    </div>\n' +
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom" style="padding-left: 20px;">\n' +
        '                                <p>Total buy down fee for this loan is</p>\n' +
        '                                <h2 calculator-highlightedTextColor><i></i> <span data-result="totalbuydownamount">$0.00</span><span><small calculator-small-asterisk>*</small></span></h2>\n' +
        '                                <p><span data-result="bdThirdParty" class="c-blue" calculator-highlightedTextColor>$0.00</span>  is paid by a third-party, and <span data-result="bdYou" class="c-blue" calculator-highlightedTextColor> </span> is paid by you.</p>\n' +
        '\n' +
        '\n' +
        '                            </div>\n' +
        '                                 <br></br>\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="advanced" role="tabpanel"  data-calculator data-methods="advancedBuydoown">\n' +
        '                    <div class="panel">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
        '                            <div class="dropdown">\n' +
        '                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuQualifying" data-toggle-button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n' +
        '                                    3/2/1 Buydown\n' +
        '                                </button>\n' +
        '                                <div class="dropdown-menu" aria-labelledby="dropdownMenuQualifying">\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown321">3/2/1 Buydown</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown111">1/1/1 Buydown</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown21">2/1 BUYDOWN</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown11">1/1 BUYDOWN</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown10">1/0 BUYDOWN</a>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="" data-toggle-block="buydown321" data-mode="3">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown111" data-mode="111">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown21" data-mode="2">\n' +
        '\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 2.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="2.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown11" data-mode="11">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +

        '                            <div class="d-none" data-toggle-block="buydown10" data-mode="1">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 1.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                    data-rule-min="1.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
    
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                         <div class="panel--bottom">\n' +
        '                            <div id="bdmonthlyTable"></div>\n'+
        '                         </div>\n' +   
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                            <div class="panel--bottom" style="padding-left: 20px;">\n' +
        '                                <p>Total buy down fee for this loan is</p>\n' +
        '                                <h2 calculator-highlightedTextColor><i></i> <span data-result="totalbuydownamount">$0.00</span><span><small calculator-small-asterisk>*</small></span></h2>\n' +
        '                                <p><span data-result="bdThirdParty" class="c-blue" calculator-highlightedTextColor>$0.00</span>  is paid by a third-party, and <span data-result="bdYou" class="c-blue" calculator-highlightedTextColor> </span> is paid by you.</p>\n' +
        '\n' +
        '                            </div>\n' +
        '\n' +
        '\n' +
        '                        </form>\n' +
        '\n' +
        '                    </div>\n' +
        '                    <div data-show-calc class=" d-none">\n' +
        '                        <div class="divider divider--double"></div>\n' +
        '                        <div class="results results--indent no-indent ">\n' +
        '                            <div class=" results__graphic">\n' +
        '                                <h3>\n' +
        '                                    See your lower monthly payment for the first years\n' +
        '                                    of the loan. Select Year:\n' +
        '                                    <select title="Chart Types" data-update-chart class="default-select"></select>\n' +
        '                                </h3>\n' +
        '                                <div data-chart>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="results__chart" data-graphic>\n' +
        '\n' +
        '                            </div>\n' +
        '\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '\n' +
        '                </div>\n' +
        '                <div class="tab-pane " id="amortization" role="tabpanel"  data-calculator data-methods="amortizationBuydoown">\n' +
        '                    <div class="panel d-none d-sm-block">\n' +
        '                        <form class="panel__body " autocomplete="off">\n' +
        '                            <div class="dropdown">\n' +
        '                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuQualifying" data-toggle-button data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n' +
        '                                    3/2/1 Buydown\n' +
        '                                </button>\n' +
        '                                <div class="dropdown-menu" aria-labelledby="dropdownMenuQualifying">\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown321">3/2/1 Buydown</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown111">1/1/1 Buydown</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown21">2/1 BUYDOWN</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown11">1/1 BUYDOWN</a>\n' +
        '                                    <a class="dropdown-item" data-toggle-section="buydown10">1/0 BUYDOWN</a>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="" data-toggle-block="buydown321" data-mode="3">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown111" data-mode="111">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown21" data-mode="2">\n' +
        '\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 2.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-min="2.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown11" data-mode="11">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 3.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-min="3.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                            <div class="d-none" data-toggle-block="buydown10" data-mode="1">\n' +
        '                                <div class="panel--wrapper flex-end">\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Loan Amount</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="loanAmount" autocomplete="false" type="text"\n' +
        '                                                   name="loanAmount" data-currency\n' +
        '                                                   data-input value="{{values.Buydown.loanAmount}}" data-rule-maxlength="7"\n' +
        '                                                   data-rule-required data-msg="Please enter a Loan Amount" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item  ">\n' +
        '                                        <p>Term (Yrs)</p>\n' +
        '                                        <div class="panel__input ">\n' +
        '\n' +
        '                                            <input autocomplete="false" title="term" type="text" data-input name="term" value="{{values.Buydown.term}}"\n' +
        '                                                   data-msg="Please enter a Term"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="30" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item ">\n' +
        '                                        <p>Interest Rate (%)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="interestRate" data-percent autocomplete="false" type="text" name="interestRate" data-input value="{{values.Buydown.interestRate}}"\n' +
        '                                                   data-msg="Please enter a rate between 1.01% and 15%"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-min="1.01"\n' +
        '                                                   data-rule-max="15" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="panel__item panel--auto">\n' +
        '                                        <p>Property Tax (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="propertyTax" autocomplete="false" type="text" name="propertyTax"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.propertyTax}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <div class="break"></div>\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Homeowners Ins. (Yearly)</p>\n' +
        '                                        <div class="panel__input icon--left ">\n' +
        '                                            <i>$</i>\n' +
        '                                            <input title="homeowners" autocomplete="false" type="text" name="homeowners"\n' +
        '                                                   data-currency data-input value="{{values.Buydown.homeownersIns}}"\n' +
        '                                                   data-rule-maxlength="7" data-rule-required data-msg="Please enter a Homeowners Ins." />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '\n' +
        '                                    <div class="panel__item panel--auto panel--mt">\n' +
        '                                        <p>Third-party Contribution toward<br/>Buydown Fee (% of Loan Amount)</p>\n' +
        '                                        <div class="panel__input icon--right ">\n' +
        '                                            <i>%</i>\n' +
        '                                            <input title="contributionBuydownFee" data-percent autocomplete="false" type="text" name="contributionBuydownFee"\n' +
        '                                                   data-input value="{{values.Buydown.thirdPartyCont}}"\n' +
        '                                                   data-msg="Please enter a number between, 1 and 100"\n' +
        '                                                   data-rule-required\n' +
        '                                                   data-rule-max="100" />\n' +
        '                                        </div>\n' +
        '                                    </div>\n' +
        '                                    <a href="# " class="panel__button panel--mt w-auto" data-calculate="true" calculator-button>calculate</a>\n' +
        '\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                               <p class="update-message" calculator-highlightedTextColor>Changes detected</p>\n'+
        '                           <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                         <div class="panel--bottom">\n' +
        '                              <div id="bdmonthlyTable"></div>\n'+ 
        '                         </div>\n' +  
        '                            <div class="divider divider--double"></div>\n' +
        '                            <div class="panel--bottom" style="padding-left: 20px;">\n' +
        '                                <p>Total buy down fee for this loan is</p>\n' +
        '                                <h2 calculator-highlightedTextColor><i></i> <span data-result="totalbuydownamount">$0.00</span><span><small calculator-small-asterisk>*</small></span></h2>\n' +
        '                                <p><span data-result="bdThirdParty" class="c-blue" calculator-highlightedTextColor>$0.00</span>  is paid by a third-party, and <span data-result="bdYou" class="c-blue" calculator-highlightedTextColor> </span> is paid by you.</p>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </form>\n' +
        '\n' +
        '                    </div>\n' +
        '                    <div class="divider divider--double d-none d-sm-block"></div>\n' +
        '                    <div class="results results--table">\n' +
        '                        <div class="results__table" data-table>\n' +
        '\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '\n' +
        '    </div>'
}