
// calculators logic
import  biweeklyMortgage from './calculator-mode/biweekly-mortgage-payment';
import  Mortgage from './calculator-mode/mortgage-payment';
import  Purchasing from './calculator-mode/purchasing-power';
import  Qualifying from './calculator-mode/qualifying-loan';
import  Rent from './calculator-mode/rent-buy';
import  ShortLong from './calculator-mode/short-long';
import  Savings from './calculator-mode/savings';
import  Buydown from './calculator-mode/buydown';


export  default class CalculatorMode{
    constructor(el, param, mode){
        this.calcs= {
            biweeklyMortgage,
            Mortgage,
            Purchasing,
            Qualifying,
            Rent,
            ShortLong,
            Savings,
            Buydown,
        }
        const module = this.calcs[mode]
        new module(el, param)
    }
}