import './icon.scss';
import './sprite.css';

import svgxhr from "webpack-svgstore/dist/helpers/svgxhr";

const __svg__ = {
    path: './sprite/**/*.svg',
    name: 'img/sprite.svg'
};
svgxhr("img/sprite.svg");
