

export default class {

    constructor() {
    }
    getMonthlyPayment(loanAmount, interestRate, months) {
        
        let factor = 1;
        const rate = interestRate / 1200;
        const interestRatePlusOne = rate + 1;
        for (let i = 0; i < months; i++) { factor *= interestRatePlusOne }
        return (loanAmount * factor * rate) / (factor - 1)
    }

    amortRowClass(year, date, payment, principalPaid, totalPrincipalPaid, interest, total, balance, buydownamount,acceleratedPayment,interestAccelerated,totalInterestAccelerated,acceleratedBalance, newMonthNumber, newYearNumber) {
        const resultRow = {
            'date': date,
            'payment': payment? payment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): 0,
            'year': year,
            'principalPaid':principalPaid? principalPaid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):0,
            'totalPrincipalPaid': totalPrincipalPaid?totalPrincipalPaid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):0,
            'interest': interest.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            'totalInterest': total?total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):0,
            'balance': balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            'buydownamount':buydownamount? buydownamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): 0 ,
            'acceleratedPayment':acceleratedPayment? acceleratedPayment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): 0 ,
            'interestAccelerated':interestAccelerated? interestAccelerated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): 0 ,
            'totalInterestAccelerated':totalInterestAccelerated? totalInterestAccelerated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): 0 ,
            'acceleratedBalance': acceleratedBalance ? acceleratedBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0,
            'newMonthNumber': newMonthNumber,
            'newYearNumber': newYearNumber
        };
        return resultRow;
    }


    pmt(rate_per_period, number_of_payments, present_value, future_value, type) {
        if (rate_per_period != 0.0) { var q = Math.pow(1 + rate_per_period, number_of_payments); return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type))) } else if (number_of_payments != 0.0) { return -(future_value + present_value) / number_of_payments }
        return 0
    }
    pmt2(rate, periods, present, future, type) {
        future = future || 0;
        type = type || 0;
        var result;
        if (rate === 0) { result = (present + future) / periods }
        else { var term = Math.pow(1 + rate, periods);
        if (type === 1) { result = (future * rate / (term - 1) + present * rate / (1 - 1 / term)) / (1 + rate) }
        else { result = future * rate / (term - 1) + present * rate / (1 - 1 / term) } }
        return -result
    };
    pv(rate, per, nper, pmt, fv) {
        nper = parseFloat(nper);
        pmt = parseFloat(pmt);
        fv = parseFloat(fv);
        rate = eval((rate) / (per * 100));
        if ((pmt == 0) || (nper == 0)) {
            return (0)
        }
        let  pv_value
        if (rate == 0) {
             pv_value = -(fv + (pmt * nper))
        } else {
            let   x = Math.pow(1 + rate, -nper);
            let   y = Math.pow(1 + rate, nper);
               pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate
        }
        pv_value = this.conv_number(pv_value, 2);
        return (pv_value)
    }
    conv_number(expr, decplaces) {
        var str = "" + Math.round(eval(expr) * Math.pow(10, decplaces)); while (str.length <= decplaces) { str = "0" + str }
        var decpoint = str.length - decplaces; return (str.substring(0, decpoint) + "." + str.substring(decpoint, str.length))
    }
    FV(rate, nper, pmt, pv, type) {
        var pow = Math.pow(1 + rate, nper), fv;
        if (rate) { fv = (pmt * (1 + rate * type) * (1 - pow) / rate) - pv * pow }
        else { fv = -1 * (pv + pmt * nper) }
        return fv.toFixed(2)
    }
    FV2(rate, periods, payment, value, type) {
        value = value || 0; type = type || 0;
        var result;
        if (rate === 0) { result = value + payment * periods }
        else { var term = Math.pow(1 + rate, periods);
        if (type === 1) { result = value * term + payment * (1 + rate) * (term - 1) / rate }
        else { result = value * term + payment * (term - 1) / rate } }
        return -result
    };
    CUMIPMT(rate, periods, value, start, end, type) {
        var payment = this.pmt2(rate, periods, value, 0, type);
        var interest = 0; if (start === 1) { if (type === 0) { interest = -value; start++ } }
        for (var i = start; i <= end; i++) { if (type === 1) { interest += this.FV2(rate, i - 2, payment, value, 1) - payment } else { interest += this.FV2(rate, i - 1, payment, value, 0) } }
        interest *= rate; return interest
    }
     AmortizationCompute(loanAmount, interestRate, months) {
        
        const today = new Date();
         let monthNumber = today.getMonth() + 1;
         let newMonthNumber = 0;
         let monthTally = 0;
         let yearNumber = today.getFullYear();
         let newYearNumber = 1;
        /**/

         let LoanAmount = Math.abs(Number(loanAmount));
         let Months = Number(months);
         let InterestRate = Number(interestRate);
         let monthlyPayment = 0;
         let rate = InterestRate / 1200;
         let balance = LoanAmount;
         let errormsg = '';
         let totalMonthlyPayment;
    
         let loanStartMonth = monthNumber;
         let loanStartYear = yearNumber;
         let interestPaid = 0;
         let totalInterest = 0;
         let principalPaid = 0;
         let totalPrincipalPaid = 0;
         let amortData = new Array();
         let tableRows = new Array();
         
        monthlyPayment = this.getMonthlyPayment(LoanAmount, InterestRate, Months);
         for (var i = 0; i < Months; i++) {
            ++monthTally;
            if (++monthNumber > 12) {
                monthNumber = 1;
                yearNumber++;
            }
            if (++newMonthNumber > 12) {
                newMonthNumber = 1;
                newYearNumber += 1;
            }

            totalMonthlyPayment = monthlyPayment;
            
            interestPaid = balance * rate;
            totalInterest += interestPaid;
            principalPaid = totalMonthlyPayment - interestPaid;
            totalPrincipalPaid += principalPaid;
            balance -= principalPaid;
            
            if (balance <= 0) {
                totalMonthlyPayment += balance;
                principalPaid = totalMonthlyPayment - interestPaid;
                balance = 0;
            }
            
            amortData[i] = this.amortRowClass(
                yearNumber,
                this.DateFormat(monthNumber),
                totalMonthlyPayment.toFixed(2),
                principalPaid.toFixed(2),
                totalPrincipalPaid.toFixed(2),
                interestPaid.toFixed(2),
                totalInterest.toFixed(2),
                balance.toFixed(2),
                0,
                0,
                0,
                0,
                0,
                monthTally,
                newYearNumber
            );
            
            if (balance <= 0)
                break;
        }
        
        //var paidOffDate = AmortizationCalc.GetPaidoffDateFormat(loanStartDay, (monthNumber - 1), yearNumber, (loanStartMonth - 1), loanStartYear);
        //var loanOriginalDate = AmortizationCalc.GetPaidoffDateFormat(loanStartDay, (date[0] - 1), date[2], (date[0] - 1), date[2]);
        var loanFormat = (LoanAmount.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        
        var data = {
            'monthlyPayment': monthlyPayment.toFixed(2),
            //'paidOffDate': paidOffDate,
            'amortization': amortData,
            'error': errormsg,
            //'loanOriginalDate': loanOriginalDate,
            'loanFormat': loanFormat
        };
        
        // createTable(data);
        // $('#loadingSpinner').hide();
        // if ($("#calculator").hasClass("active")) {
        //     $('#loadingSpinner').show();
        //     window.setTimeout(LoadAmortData, 300);
        //
        // }
        //
        return data;
    }
    AmortizationComputeBuydown(loanAmount, interestRate, months, buydown) {
        const today = new Date();
        let monthNumber = today.getMonth() + 1;
        let newMonthNumber = 0;
        let monthTally = 0;
        let yearNumber = today.getFullYear();
        let newYearNumber = 1;
        /**/

        var loanAmount = Math.abs(Number(loanAmount));
        var months = Number(months);
        var interestRate = Number(interestRate);
        var buydownInterestRate = Number(interestRate);

        var monthlyPayment = 0;
        var buydownMonthlyPayment = 0;

        var balance = loanAmount;
        var errormsg = '';
        var totalMonthlyPayment;
        var totalBuydownMonthlyPayment;

        var loanStartMonth = monthNumber;
        var loanStartYear = yearNumber;
        var interestPaid = 0;
        var buydownInterestPaid = 0;
        var buydownamount = 0;
        var totalbuydownamount = 0;
        var totalInterest = 0;
        var principalPaid = 0;
        var totalPrincipalPaid = 0;
        var amortData = new Array();
        var tableRows = new Array();
        var rate = interestRate / 12;
        // if the buydown selected is a 1/1 we need to HOLD the interest rate at 1% for 
        // an additional year to account for 2 whole years of a 1% buydown
        const heldRate = Number(buydownInterestRate - 1);
        if (buydown == 11 || buydown == 4) {
            buydownInterestRate = Number(heldRate);
        } else {
        buydownInterestRate = buydownInterestRate - buydown;
        }
        var buydownRate = (buydownInterestRate / 12);
        monthlyPayment = this.getMonthlyPayment(loanAmount, interestRate, months);
        buydownMonthlyPayment = this.getMonthlyPayment(loanAmount, buydownInterestRate, months);
    
        var monthcount = 1;
        for (var i = 0; i < months; i++) {
            ++monthTally;
            if (++monthNumber > 12) {
                monthNumber = 1;
                yearNumber++;
            }
            if (++newMonthNumber > 12) {
                newMonthNumber = 1;
                newYearNumber += 1;
            }
            if (monthcount > 12) {
                if (buydownInterestRate >= interestRate) {
                        buydownInterestRate = interestRate;
                }
                else {
                        buydownInterestRate++;
                        monthcount = 1;
                    }
            }
            if (i <= 12 && buydown == 11) {
                buydownInterestRate = heldRate;
            }
            if (i <= 24 && buydown == 4) {
                buydownInterestRate = heldRate;
            }
        
            rate = interestRate / 1200;
            buydownRate = buydownInterestRate / 1200;
            totalMonthlyPayment = this.getMonthlyPayment(loanAmount, interestRate, months);
            totalBuydownMonthlyPayment = this.getMonthlyPayment(loanAmount, buydownInterestRate, months);
            interestPaid = balance * rate;
            principalPaid = totalMonthlyPayment - interestPaid;
            buydownInterestPaid = totalBuydownMonthlyPayment - principalPaid;
            totalInterest += buydownInterestPaid;
            totalPrincipalPaid += principalPaid;
            balance -= principalPaid;
            buydownamount = interestPaid - buydownInterestPaid;
            if (buydownamount > 0) {
                totalbuydownamount += buydownamount;
            }
            if (balance <= 0) {
                totalMonthlyPayment += balance;
                principalPaid = totalMonthlyPayment - interestPaid;
                balance = 0;
            }
            amortData[i] = this.amortRowClass(
                yearNumber,
                this.DateFormat(monthNumber),
                totalBuydownMonthlyPayment.toFixed(2),
                principalPaid.toFixed(2),
                totalPrincipalPaid.toFixed(2),
                buydownInterestPaid.toFixed(2),
                totalInterest.toFixed(2),
                balance.toFixed(2),
                buydownamount.toFixed(2),
                0,
                0,
                0,
                0,
                monthTally,
                newYearNumber
            );
        
            if (balance <= 0)
                break;
        
            monthcount++;
        }
    
        var loanFormat = (loanAmount.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var data = {
            'monthlyPayment': monthlyPayment.toFixed(2),
            //'paidOffDate': paidOffDate,
            'amortization': amortData,
            'error': errormsg,
            //'loanOriginalDate': loanOriginalDate,
            'loanFormat': loanFormat,
            'totalbuydownamount': totalbuydownamount
        };
        return data;
    }
    AmortizationComputeBiWeekly(loanAmount, interestRate, months) {
    
        const today = new Date();
        let monthNumber = today.getMonth() + 1;
        let newMonthNumber = 0;
        let monthTally = 0;
        let yearNumber = today.getFullYear();
        let newYearNumber = 1;
        /**/
    
        let LoanAmount = Math.abs(Number(loanAmount));
        let Months = Number(months);
        let InterestRate = Number(interestRate);
        let monthlyPayment = 0;
        let rate = InterestRate / 1200;
        let balance = LoanAmount;
        let acceleratedBalance = LoanAmount;
        let acceleratedPayment = 0;
        let errormsg = '';
        let totalMonthlyPayment;
    
        let interestPaid = 0;
        let interestAccelerated = 0;
        let totalInterest = 0;
        let totalInterestAccelerated = 0;
        let principalPaid = 0;
        let principalPaidAccelerated = 0;
        let totalPrincipalPaidAccelerated = 0;
        let totalPrincipalPaid = 0;
        let amortData = new Array();
        let tableRows = new Array();
        let AcceleratedRepaymentTime = 0
    
        monthlyPayment = this.getMonthlyPayment(LoanAmount, InterestRate, Months);
        acceleratedPayment = 0;
    
        for (var i = 0; i < Months; i++) {
            ++monthTally;
            if (++monthNumber > 12) {
                monthNumber = 1;
                yearNumber++;
            }
            if (++newMonthNumber > 12) {
                newMonthNumber = 1;
                newYearNumber += 1;
            }
            totalMonthlyPayment = monthlyPayment;
            if(acceleratedBalance >0 )
                acceleratedPayment = (13/12) * monthlyPayment;
            interestPaid = balance * rate;
            interestAccelerated = acceleratedBalance * rate;
            totalInterest += interestPaid;
            totalInterestAccelerated += interestAccelerated;
            principalPaid = totalMonthlyPayment - interestPaid;
            principalPaidAccelerated = acceleratedPayment - interestAccelerated
            totalPrincipalPaid += principalPaid;
            balance -= principalPaid;
            acceleratedBalance -=  principalPaidAccelerated
            
            if (balance <= 0) {
                totalMonthlyPayment += balance;
                principalPaid = totalMonthlyPayment - interestPaid;
                balance = 0;
            }
            if (acceleratedPayment> 0 && acceleratedBalance <= 0) {
                acceleratedPayment = 0;
                acceleratedBalance = 0;
                principalPaidAccelerated = 0;
                AcceleratedRepaymentTime = i +1
            }
            
   
            amortData[i] = this.amortRowClass(
                yearNumber,
                this.DateFormat(monthNumber),
                totalMonthlyPayment.toFixed(2),
                principalPaid.toFixed(2),
                totalPrincipalPaid.toFixed(2),
                interestPaid.toFixed(2),
                totalInterest.toFixed(2),
                balance.toFixed(2),0,
                acceleratedPayment.toFixed(2),
                interestAccelerated.toFixed(2),
                totalInterestAccelerated.toFixed(2),
                acceleratedBalance.toFixed(2),
                monthTally,
                newYearNumber
            );
     
           
        
            if (balance <= 0)
                break;
           
        }
    
        //var paidOffDate = AmortizationCalc.GetPaidoffDateFormat(loanStartDay, (monthNumber - 1), yearNumber, (loanStartMonth - 1), loanStartYear);
        //var loanOriginalDate = AmortizationCalc.GetPaidoffDateFormat(loanStartDay, (date[0] - 1), date[2], (date[0] - 1), date[2]);
        var loanFormat = (LoanAmount.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
        var data = {
            'monthlyPayment': monthlyPayment.toFixed(2),
            //'paidOffDate': paidOffDate,
            'amortization': amortData,
            'error': errormsg,
            //'loanOriginalDate': loanOriginalDate,
            'loanFormat': loanFormat,
            'totalInterest': totalInterest.toFixed(0),
            'totalInterestAccelerated': totalInterestAccelerated.toFixed(0),
            'acceleratedRepaymentTime': AcceleratedRepaymentTime,
            
        };
    
        // createTable(data);
        // $('#loadingSpinner').hide();
        // if ($("#calculator").hasClass("active")) {
        //     $('#loadingSpinner').show();
        //     window.setTimeout(LoadAmortData, 300);
        //
        // }
        //
        return data;
    }
    DateFormat(month, year) {
        var tblDate;
        var month_name = ["Jan.", "Feb.", "Mar.", "April", "May", "June", "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];
    var monthInteger = parseInt(month);
    if(year)
    tblDate = month_name[(monthInteger - 1)] + "&nbsp;" + year;
    else
        tblDate = month_name[(monthInteger - 1)] ;
    return tblDate }
   }