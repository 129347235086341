export default function () {
    return ' <nav data-render-navigate-mobile></nav>\n' +
        '        <div class="mx-auto">\n' +
        '            <div data-render-navigate>\n' +
        '            </div>\n' +
        '            <div data-render>\n' +
        '\n' +
        '            </div>\n' +
        '            <div class="loader"> loading ...</div>\n' +
        '             {{#if displayLO}}<div class="lo-section"><div class="lo-image" style="background:url({{tabs.loImageurl}});background-position:center;background-size:contain;background-repeat:no-repeat;"></div>\n'  +
         '<div class="lo-info">\n'+
        '<p class="name">{{ tabs.loName }}<span>{{ tabs.loTitle }}</span></p>\n'+
       '<p class="nmls">NMLS# {{ tabs.loNmls }}</p>\n'+
        '<div class="contact-info">\n'+
            '<div><img src="https://cloud.cmgfi.com/dvbdysuf5/image/upload/AIOWebsite/Shared_Images/location-aio.svg" /><p>{{ tabs.loLocation }}</p></div>\n'+
            '<div><img src="https://cloud.cmgfi.com/dvbdysuf5/image/upload/AIOWebsite/Shared_Images/phone-aio.svg" /><p>{{ tabs.loPhone }}</p></div>\n'+
            '<div><img src="https://cloud.cmgfi.com/dvbdysuf5/image/upload/AIOWebsite/Shared_Images/email-aio.svg" /><p>{{ tabs.loEmail }}</p></div>\n'+
       ' </div>\n'+
    '</div>\n'+
     '<div class="lo-action">\n'+
        '<a href="{{ tabs.loMysite }}">VIEW MYSITE</a>\n'+
        '<a href="{{ tabs.loApplynow }}">GET STARTED</a>\n'+
    '</div>\n'+
    '<div class="lo-logo">\n'+
        '<img src="{{ tabs.loLogo }}" alt="logo" />\n'+
    '</div>\n'+
        '             </div>{{/if}}\n' +
        '            {{#if footnote}}<p class="after-text">\n' +
        '               *Results are hypothetical and may not be accurate. This is not a commitment to lend nor a preapproval. Consult a financial professional for full details. <br/><br/>Payment example: If you bought a $450,000 home with a 20% down payment, for a loan amount of $360,000, with a 30 year term at a fixed rate of 6.125% (Annual Percentage Rate 6.220%), you would make 360 payments of $2,189.00. Payment stated does not include taxes and insurance, which will result in a higher payment.\n' +
        '            </p>{{/if}}\n' +

        '\n' +
        '        </div>    \n'
}