import $ from 'jquery';
import Currency from 'currency.js';
import 'highcharts';

import Calculator from '../calculator-common'

export default class extends Calculator{
    constructor(el, param) {
        super(el, param)
       
    }
    basicMountly(data, panel ){
        return new Promise((resolve, reject) => {
      
     
        var months = data.loanTerm*12
        var pprice = Number(data.loanAmount);
        var interestrate = Number(data.interestRate) / 100;
        var interest = (data.loanAmount * interestrate) / 12;
        var payment = Number(this.logicMethods.pmt((interestrate / 12), (months), -Math.abs(pprice - (pprice * 0)), 0, 0).toFixed(2));
        var biWeeklyPayments = payment/2,
          biweeklyAmt = biWeeklyPayments*26,
          monthlyAmt = payment * 12 ,
          differenceIAmts = biweeklyAmt - monthlyAmt;
        
          var amortdata = this.logicMethods.AmortizationComputeBiWeekly(data.loanAmount,data.interestRate,data.loanTerm*12);
     var interestSaved = amortdata.totalInterest - amortdata.totalInterestAccelerated
     var acceleratedPayoff = (amortdata.acceleratedRepaymentTime/12).toFixed(1)
        
        panel.find(this.selectors.result).filter('[data-result="biWeeklyPayments"]').text(Currency(biWeeklyPayments,{separator: ",", precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="biweeklyAmt"]').text(Currency(biweeklyAmt,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format())
        panel.find(this.selectors.result).filter('[data-result="monthlyAmt"]').text(Currency(monthlyAmt,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="differenceIAmts"]').text(Currency(differenceIAmts,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="interestSaved"]').text(Currency(interestSaved,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
        panel.find(this.selectors.result).filter('[data-result="acceleratedPayoff"]').text(acceleratedPayoff)
            resolve(true)
        })
    }
    advancedMountly(data, panel ){
        return new Promise((resolve, reject) => {
    
            var months = data.loanTerm*12
            var term = Number(data.loanTerm);
            var pprice = Number(data.loanAmount);
            var interestrate = Number(data.interestRate) / 100;
            var interest = (data.loanAmount * interestrate) / 12;
            var payment = Number(this.logicMethods.pmt((interestrate / 12), (months), -Math.abs(pprice - (pprice * 0)), 0, 0).toFixed(2));
            var biWeeklyPayments = payment/2 + data.propertyTax/26 + data.homeowners/26,
                biweeklyAmt = payment*13,
                monthlyAmt = payment * 12 ,
                differenceIAmts = biweeklyAmt - monthlyAmt;
    
            var amortdata = this.logicMethods.AmortizationComputeBiWeekly(data.loanAmount,data.interestRate,data.loanTerm*12);
            var interestSaved = amortdata.totalInterest - amortdata.totalInterestAccelerated
            var acceleratedPayoff = (amortdata.acceleratedRepaymentTime/12).toFixed(1)
    
    
            panel.find(this.selectors.result).filter('[data-result="biWeeklyPayments"]').text(Currency(biWeeklyPayments,{separator: ",", precision: 0}).format())
    
            let categories = [],
                Balance = [],
                AcceleratedBalance = [],
                InterestData = []
            let offset =  term/5,
            step = 5
        
            for(let i = 0; i < offset; i++ ) {
                
                const variable = (step*(i+1))
                let int;
                if(variable>term) {
                    int = Number((amortdata.amortization[(term * 12) - 1].totalInterest).replace('$', '').replace(/,/g, '')) - Number((amortdata.amortization[(term * 12) - 1].totalInterestAccelerated).replace('$', '').replace(/,/g, ''))
                    categories.push(term + ' years')
                     InterestData.push(int)
                
                } else {
                    int = Number((amortdata.amortization[(variable * 12) - 1].totalInterest).replace('$', '').replace(/,/g, '')) - Number((amortdata.amortization[(variable * 12) - 1].totalInterestAccelerated).replace('$', '').replace(/,/g, ''))
    
                    categories.push(5*(i+1) + ' years')
                   InterestData.push(int)
               
                }
                
            }
            for(let i = 0; i < acceleratedPayoff/5 - 1; i++ ) {
                const variable = (step*(i+1))
                if(variable>term) {
                   categories.push(term + ' years')
                      Balance.push(Number((amortdata.amortization[(term * 12) - 1].balance).replace('$', '').replace(/,/g, '')))
                    AcceleratedBalance.push(Number((amortdata.amortization[(term * 12) - 1].acceleratedBalance).replace('$', '').replace(/,/g, '')))
            
                } else {
                 
                    categories.push(5*(i+1) + ' years')
                 Balance.push(Number((amortdata.amortization[(variable * 12) - 1].balance).replace('$', '').replace(/,/g, '')))
                    AcceleratedBalance.push(Number((amortdata.amortization[(variable * 12) - 1].acceleratedBalance).replace('$', '').replace(/,/g, '')))
             
                }
                
            }
            
            
            panel.find(this.selectors.chart).highcharts({
                chart: {
                    plotBackgroundColor: '#ffffff',
                    type: 'column',
                    backgroundColor: '#F7F7F7',
                    marginTop: 80,
                    height: 400,
                },
                title: {
                    useHTML: true,
                    text: '<div class="titlediv"><div class="secondtitle">With accelerated biweekly payments, you will save <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight +'">' + Currency(interestSaved,{ symbol: "$", separator: ",",formatWithSymbol: true , precision: 0}).format()+ '</span> over the life of the loan</div></div>',
                    align: 'center',
                    backgroundColor: '#EFEFEF',
                    marginTop: 15
        
                },
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                legend: {
                    enabled: false,
                    y: 50,
                    align: 'center',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    symbolHeight: 12,
                    symbolWidth: 12,
                    symbolRadius: 6,
                    useHTML: true,
                    floating: true,
                    labelFormatter: function () {
            
                        return '<div style=""><span class="legenddtitle">' + this.name + '</span></div>';
                    }
                },
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    borderColor: 'transparent',
                    borderRadius: 5,
                    backgroundColor: '#ffffff',
                    padding: 10,
                    shadow:false,
                    pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}   {point.id} <span class="small">({point.percentage:.0f}%)</span></span>',
                    shared: true,
                    pointFormatter: function () {
                        return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> : '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true , precision: 0}).format()+'</span>'
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        showInLegend: true,
                        allowPointSelect: true,
                        borderWidth: 0
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            minwidth: 100,
                            maxWidth: 400
                        },
                        chartOptions: {
                            chart: {
                                height: 360,
                                marginTop: 100,
                                marginBotom: 20
                            },
                            legend: {
                                y: 90,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'top',
                                useHTML: true,
                                floating: true,
                                labelFormatter: function () {
                                    return '<div style="width:50px;"><span class="legenddtitle">' + this.name + '</span></div>';
                                }
                            },
                           
                           
                        }
                    }]
                },
                series: [
                    {
                        name: 'Interest',
                        data: InterestData,
            
                        color: this.params.tabs.highcharts.column.Interest
                    }]
            });
            panel.find(this.selectors.graphic).highcharts({
                chart: {
                    plotBackgroundColor: '#ffffff',
                    type: 'column',
                    backgroundColor: '#F7F7F7',
                    marginTop: 80,
                    height: 400,
                },
                title: {
                    useHTML: true,
                    text: '<div class="titlediv"><div class="secondtitle">With accelerated biweekly payments you will pay off your mortgage in  <span class="firsttitle" style="color:' + this.params.tabs.highlightedTextColorLight +'">' +acceleratedPayoff+ ' years</span></div></div>',
                    align: 'center',
                    backgroundColor: '#EFEFEF',
                    marginTop: 15
                    
                },
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                legend: {
                    enabled: false,
                    y: 50,
                    align: 'center',
                    verticalAlign: 'top',
                    layout: 'vertical',
                    symbolHeight: 12,
                    symbolWidth: 12,
                    symbolRadius: 6,
                    useHTML: true,
                    floating: true,
                    labelFormatter: function () {
                        
                        return '<div style=""><span class="legenddtitle">' + this.name + '</span></div>';
                    }
                },
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    borderColor: 'transparent',
                    borderRadius: 5,
                    backgroundColor: '#ffffff',
                    padding: 10,
                    shadow:false,
                    pointFormat:'<span class="legend-list"><span style="color:{point.color};font-size: 16px;">\u25CF</span> <b> {series.name}</b> : {point.y}   {point.id} <span class="small">({point.percentage:.0f}%)</span></span>',
                    shared: true,
                    pointFormatter: function () {
                        return '<span class="legend-list"><span style="color:'+this.color+';font-size: 16px;">\u25CF</span> <b> '+this.series.name+'</b> : '+Currency(this.y,{ symbol: "$", separator: ",",formatWithSymbol: true , precision: 0}).format()+' <span class="small">('+Math.round(this.percentage)+'%)</span></span>'
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        showInLegend: true,
                        allowPointSelect: true,
                        borderWidth: 0
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            minwidth: 100,
                            maxWidth: 400
                        },
                        chartOptions: {
                            chart: {
                                height: 360,
                                marginTop: 100,
                                marginBotom: 20
                            },
                            legend: {
                                y: 90,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'top',
                                useHTML: true,
                                floating: true,
                                labelFormatter: function () {
                                    return '<div style="width:50px;"><span class="legenddtitle">' + this.name + '</span></div>';
                                }
                            },
                          
                        }
                    }]
                },
                series: [
                    {
                        name: 'Balance',
                        data: Balance,
                        stack: 'Balance',
                      
                        color: this.params.tabs.highcharts.column.Interest
                    },  {
                        name: 'Accelerated Balance',
                        data: AcceleratedBalance,
                        stack: 'Accelerated Balance',
                        color: this.params.tabs.highcharts.column.Principal
                    }]
            });
            resolve(true)
        })
    }
    amortization(data, panel) {
                return new Promise((resolve, reject) => {
    
    
                    var months = data.loanTerm*12
                    var pprice = Number(data.loanAmount);
                    var interestrate = Number(data.interestRate) / 100;
                    var interest = (data.loanAmount * interestrate) / 12;
                    var payment = Number(this.logicMethods.pmt((interestrate / 12), (months), -Math.abs(pprice - (pprice * 0)), 0, 0).toFixed(2));
                    var biWeeklyPayments = payment/2,
                        biweeklyAmt = biWeeklyPayments*26,
                        monthlyAmt = payment * 12 ,
                        differenceIAmts = biweeklyAmt - monthlyAmt;
    
                    var amortdata = this.logicMethods.AmortizationComputeBiWeekly(data.loanAmount,data.interestRate,data.loanTerm*12);
                    var interestSaved = amortdata.totalInterest - amortdata.totalInterestAccelerated
                    var acceleratedPayoff = (amortdata.acceleratedRepaymentTime/12).toFixed(1)
    
                    panel.find(this.selectors.result).filter('[data-result="biWeeklyPayments"]').text(Currency(biWeeklyPayments,{separator: ",", precision: 0}).format())
                    panel.find(this.selectors.result).filter('[data-result="biweeklyAmt"]').text(Currency(biweeklyAmt,{ symbol: "$", separator: ",",formatWithSymbol: true, precision: 0 }).format())
                    panel.find(this.selectors.result).filter('[data-result="monthlyAmt"]').text(Currency(monthlyAmt,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
                    panel.find(this.selectors.result).filter('[data-result="differenceIAmts"]').text(Currency(differenceIAmts,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
                    panel.find(this.selectors.result).filter('[data-result="interestSaved"]').text(Currency(interestSaved,{ symbol: "$", separator: "," ,formatWithSymbol: true, precision: 0}).format())
                    panel.find(this.selectors.result).filter('[data-result="acceleratedPayoff"]').text(acceleratedPayoff)
        
        
        
        
        
        var filterData={}
        filterData.Items = amortdata.amortization.reduce((function(hash){
            return function(array,obj){
                if(!hash[obj.year])
                    hash[obj.year]=[obj]
                else
                    hash[obj.year].push(obj);
                return hash;
            };
            
        })({}),[])
    
        filterData.acceleratedPayment = true
        filterData.interestAccelerated = true
        filterData.acceleratedBalance = true
        this.renderTable(filterData,    panel.find(this.selectors.table)).then(() => {
          
            // $(panel.find('.collapse')).collapse('dispose')
            // $(panel.find('.collapse')).collapse({'toogle':false})
            
        })
        resolve(true)
    })
    
    }
}